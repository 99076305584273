import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";
import { cloneDeep } from "lodash";
import OptionsListBlack from "../component/options-list-black";
import { connect } from "react-redux";
import { getEntityItems, readEntities } from "../../redux/api";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import { cancelMultipleRequests, partialEntity } from "../../redux/api/actions";
import Loader from "../icons/tat/loader";

Modal.setAppElement("#root");

class AddProductRequisitionDeliveryModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      delivery: cloneDeep(props.deliveryData),
      products: [],
      currentProducts: cloneDeep(props.deliveryData.products || []),
      networkId: props.network_id,
      requisitionId: props.requisition_id,
      isAddingNewProduct: false,
      newProductName: '',
      newProductQty: '',
      providerProducts: [],
      productSelected: null,
      hasPendingChanges: false,
      messageSuccess: false,
      errorMessage: null
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    if (`${event.target.className}` !== 'option-list-component') {
      // this.setState({newProductName: ''});
    }
  }

  buildTitle = () => {
    if (this.state.hasPendingChanges) {
      return(
        <>
          <div className="title-action" style={{textAlign: 'center'}}>{'¿Estas seguro? Por favor confirma.'}</div>
        </>
      );  
    }

    return(
      <>
        <div className="title-action">{'AÑADIR PRODUCTO NUEVO'}</div>
        <div className="subtitle">{`Pedido: ${this.props.deliveryData.requisition_code || ''}-${this.props.deliveryData.suffix || ''}`}</div>
      </>
    );
  }

  buildProductsHeaders = () => {
    if (this.state.hasPendingChanges || this.state.messageSuccess) { return ''; }

    return(
      <div className="header-container">
        <div className="header-row">
          <div className="header-item">NOMBRE DEL PRODUCTO</div>
          <div className="header-item">SKU</div>
        </div>
        <div className="header-row">
          <div className="header-item">QTY POR SKU</div>
          <div className="header-item">PESO NETO</div>
          <div className="header-item">PESO BRUTO</div>
          <div className="header-item">VOLUMEN</div>
          <div className="header-item"></div>
        </div>
        
      </div>
    );
  }

  buildProductsList = () => {
    if (this.state.hasPendingChanges || this.state.messageSuccess) { return ''; }

    return(
      <div className="products-content">
        {
          (this.state.products || []).map( (product, index) => this.buildProductItem(index, product))
        }
        {
          this.state.isAddingNewProduct ? this.buildNewProductItem() : this.buildAddNewProductButton()
        }
      </div>
    );

  }

  buildAddNewProductButton = () => {
    return(
      <div 
        className="new-product-button"
        onClick={() => {
          this.setState({
            errorMessage: null,
            isAddingNewProduct: true,
            newProductQty: '',
            newProductName: '',
            productSelected: null
          })
        }}
      >
        <div className="add-icon">+</div>
        <div>{this.state.products.length > 0 ? 'Añadir otro producto' : 'Añadir producto'}</div>
      </div>
    );
  }

  updateProduct = (product_id, qty) => {
    let index_to_modify = null;

    (this.state.products || []).map( (product, index) => {
      if (`${product_id}` === `${product.id}`) {
        index_to_modify = index;
      }
    });

    if (index_to_modify === null) return;

    this.state.products[index_to_modify].qty_per_sku = qty;
    this.setState({});
  }

  buildProductItem = (index, product) => {

    return(
      <div className={`product-container background-${(index % 2) === 0 ? '1' : '2'}`} key={`product-container-${index}`}>
        <div className="product-row-2">
          <div className="product-item">{product.product_name}</div>
          <div className="product-item">{product.sku}</div>
        </div>
        <div className="product-row-2">
          <div className="product-item">
            {/* <input
              style={{textAlign: 'center'}}
              className="text-input-custom"
              name="editable"
              type="number"
              value={product.qty_per_sku}
              autocomplete={'off'}
              onChange={(e) => {
                let new_qty = e.target.value ? parseInt(e.target.value) : '';
                this.updateProduct(product.id, new_qty);
              }}
              onBlur={(e) => {
                let value = parseInt(e.target.value);

                if (value < 1) {
                  this.updateProduct(product.id, 1);
                }
              }}
            /> */}
            {product.qty_per_sku}
          </div>
          <div className="product-item">{product.net_weight_description}</div>
          <div className="product-item">{product.gross_weight_description}</div>
          <div className="product-item">{product.volume_description}</div>
          <div className="product-item">
            <img
              alt=""
              src="/images/bin.png"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  products: this.state.products.filter( item => product.id !== item.id)
                });
              }}
            />
          </div>
        </div>
        
      </div>
    );

  }

  buildNewProductItem = () => {

    if (this.state.productSelected) {
      let product = this.state.productSelected;

      let gross_weight_description = `${this.state.productSelected.gross_weight ? parseFloat(this.state.productSelected.gross_weight) * (this.state.newProductQty || 0) : ''} ${this.state.productSelected?.data?.sat_gross_weight_unit?.clave || ''}`.toLowerCase();
      let net_weight_description =`${this.state.productSelected.net_weight ? parseFloat(this.state.productSelected.net_weight) * (this.state.newProductQty || 0) : ''} ${this.state.productSelected?.data?.sat_net_weight_unit?.clave || ''}`.toLowerCase();
      let volume_description =`${this.state.productSelected.volume ? parseFloat(this.state.productSelected.volume) * (this.state.newProductQty || 0) : ''} ${this.state.productSelected?.data?.sat_volume_unit?.clave || ''}`.toLowerCase();

      return(
        <div className={`product-container background-1`} key={`product-container-99999`}>
        <div className="product-row-2">
          <div className="product-item">{product.description}</div>
          <div className="product-item">{product.sku}</div>
        </div>
        <div className="product-row-2">
          <div className="product-item">
            <input
              ref={input => input && input.focus()}
              style={{textAlign: 'center'}}
              className="text-input-custom"
              name="editable"
              type="number"
              value={this.state.newProductQty}
              placeholder={'Cantidad'}
              autocomplete={'off'}
              onChange={(e) => {
                let new_qty = e.target.value ? parseInt(e.target.value) : '';
                this.setState({newProductQty: new_qty})
              }}
              onBlur={(e) => {
                let value = parseInt(e.target.value);
                if (value < 1) {
                  this.setState({newProductQty: 1})
                }
              }}
            />
          </div>
          <div className="product-item">{net_weight_description}</div>
          <div className="product-item">{gross_weight_description}</div>
          <div className="product-item">{volume_description}</div>
          <div className="product-item">
            <img
              alt=""
              src="/images/bin.png"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  productSelected: null,
                  newProductName: ''
                });
              }}
            />
          </div>
        </div>
        
      </div>
      );
    }

    return(
      <>
        <div className="new-product-container" key={`product-container-99999`}>
          <div className="product-item">
            <input
              ref={input => input && input.focus()}
              style={{width: '100%', textAlign: 'center'}}
              type="text"
              name={'newProductName'}
              className="text-input-custom"
              onChange={(e) => {
                this.handleChange(e);

                if (e.target.value.length > 3) {
                  this.props.cancelMultipleRequests();
                  this.loadProviderProducts(e.target.value);
                }
              }}
              placeholder={'Buscar producto'}
              value={this.state.newProductName}
              autocomplete={'off'}
            />
          </div>
          
          <div className="product-item"></div>
          
        </div>
        <OptionsListBlack
          width={'45%'}
          visible={this.state.newProductName !== ''}
          loading={this.props.isProductsLoading}
          items={this.state.providerProducts}
          onClick={(value) => {

            this.setState({
              productSelected: value?.value || null,
              newProductName: value?.description || '',
              providerProducts: []
            })
            
          }}
        />
      </>
    );
  }

  loadProviderProducts = (search_value) => {

    if (this.state.networkId) {
      this.props.loadProviderProducts({
        company_id: this.props.companyId,
        network_id: this.state.networkId,
        search: search_value || '',
        offset: '',
        isMultipleCancels: true
      }, {
        onSuccess: (response) => {
          let current_products = this.state.currentProducts.map( product => product.id );
          let new_products = this.state.products.map( product => product.id );
          let all_products = new_products.concat(current_products);

          this.setState({
            providerProducts: response.filter( product => !all_products.includes(product.id) ).map( item => {
              return {id: item.id, description: `${item.description}`, value: item}
            })
          });
        }
      });

      return;
    }
    
    // propios productos
    this.props.loadProducts({
      company_id: this.props.companyId,
      view: 'short_detail',
      tags: '',
      skus: '',
      products: '',
      search: search_value || '',
      limit: '',
      offset: '',
      isMultipleCancels: true
    }, {
      onSuccess: (response) => {
        let current_products = this.state.products.map( product => product.id );

        this.setState({
          providerProducts: response.filter( product => !current_products.includes(product.id) ).map( item => {
            return {id: item.id, description: `${item.description}`, value: item}
          })
        });
      }
    });
    
  }

  hasPendingChanges = () => {
    return this.state.products.length > 0;
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  
  onClose = () => {
    if (this.props.isUpdatingLoading) return;
    if (this.props.closeAction) this.props.closeAction();
  };

  buildActions = () => {
    if (this.state.hasPendingChanges || this.state.messageSuccess) { return ''; }

    return(
      <div className="actions">
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center'
          }}
        >

          {/* {
            !this.state.isAddingNewProduct &&
            <Button
              disabled={this.state.isAddingNewProduct || this.props.isUpdatingLoading}
              text={'Agregar producto'}
              type={'btn outline primary dark'}
              onClick={() => {
                this.setState({
                  errorMessage: null,
                  isAddingNewProduct: true,
                  newProductQty: '',
                  newProductName: '',
                  productSelected: null
                })
              }}
            />
          } */}

          <div style={{width: 50}}/>

          <Button
            disabled={this.props.isUpdatingLoading}
            text={'Cancelar'}
            type={'btn secondary primary'}
            onClick={() => {
              if (this.state.isAddingNewProduct) {
                this.setState({
                  isAddingNewProduct: false
                });
              } else {
                if (this.hasPendingChanges()) {
                  this.setState({
                    hasPendingChanges: true
                  })
                } else {
                  this.onClose();
                }
              }
            }}
          />

          <div style={{width: 20}}/>

          <Button
            disabled={this.props.isUpdatingLoading || this.state.isAddingNewProduct ? (this.state.productSelected === null || `${this.state.newProductQty}`.length === 0) : !this.hasPendingChanges() || this.state.products.length === 0}
            text={this.props.isUpdatingLoading ? <Loader circleColor={'black'}/> : this.state.isAddingNewProduct ? 'Añadir' : 'Guardar'}
            type={'btn outline primary dark'}
            onClick={() => {
              if (this.state.isAddingNewProduct) {
                let gross_weight_description = `${this.state.productSelected.gross_weight ? parseFloat(this.state.productSelected.gross_weight) * (this.state.newProductQty || 0) : ''} ${this.state.productSelected?.data?.sat_gross_weight_unit?.clave || ''}`.toLowerCase();
                let net_weight_description =`${this.state.productSelected.net_weight ? parseFloat(this.state.productSelected.net_weight) * (this.state.newProductQty || 0) : ''} ${this.state.productSelected?.data?.sat_net_weight_unit?.clave || ''}`.toLowerCase();
                let volume_description =`${this.state.productSelected.volume ? parseFloat(this.state.productSelected.volume) * (this.state.newProductQty || 0) : ''} ${this.state.productSelected?.data?.sat_volume_unit?.clave || ''}`.toLowerCase();

                let new_product = {
                  id: this.state.productSelected.id,
                  product_name: this.state.productSelected.description,
                  sku: this.state.productSelected.sku,
                  qty_per_sku: parseInt(this.state.newProductQty),
                  gross_weight_description,
                  net_weight_description,
                  volume_description,
                  new_product: true
                };

                this.state.products.push(new_product);
                this.setState({
                  isAddingNewProduct: false,
                  newProductQty: '',
                  newProductName: '',
                  productSelected: null
                });

              } else {

                let new_products = this.state.products.filter( product => product.new_product ).map( product => ({id: product.id, qty: product.qty_per_sku}));
                let update_products = this.state.products.filter( product => !product.new_product ).map( product => ({id: product.id, qty: product.qty_per_sku}));

                this.props.addProducts({
                  company_id: this.props.companyId,
                  requisition_id: this.state.requisitionId,
                  delivery_id: this.state.delivery.id,
                  action: 'new_product',
                  new_products,
                  update_products
                }, {
                  onSuccess: () => {
                    this.props.handleChange({
                      target: {
                        name: "new_products_added",
                        value: null
                      }
                    });
    
                    this.setState({messageSuccess: true});
                  },
                  onError: (e) => {
                    this.setState({
                      errorMessage: e.response?.data?.error || 'Ocurrío un error, intente de nuevo'
                    })
                  }
                });

              }

            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.deliveryData !== null}
        portalClassName={`dialog add-product-requisition-delivery-dialog ${
          this.props.addClass ? this.props.addClass : ""
        }`}
        overlayClassName="overlay"
        className="content"
      >
        <div className="close" onClick={() => {
          if (this.hasPendingChanges()) {
            this.setState({
              hasPendingChanges: true
            });
          } else {
            this.onClose();
          }
        }}></div>

        { this.buildTitle() }

        { this.buildProductsHeaders() }
        { this.buildProductsList() }

        {
          this.state.hasPendingChanges && 
          <div style={{marginTop: 20, marginBottom: 30}}>
            <div>{'Si seleccionas "Continuar" se perderán los cambios realizados ¿Deseas continuar?'}</div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
              <Button
                text={'Cancelar'}
                type={'btn secondary'}
                onClick={() => this.setState({hasPendingChanges: false})}
              />

              <div style={{width: 20}}/>

              <Button
                text={'Continuar'}
                type={'btn primary'}
                onClick={() => this.onClose()}
              />
            </div>
          </div>
        }

        {
          this.state.messageSuccess &&
          <div style={{marginTop: 20, marginBottom: 30}}>
            <div>{'Se guardaron con éxito tu cambios'}</div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
              <Button
                text={'Continuar'}
                type={'btn primary'}
                onClick={() => {this.onClose()}}
              />
            </div>
          </div>
        }

        {
          this.state.errorMessage && <div style={{color: 'red', fontSize: 13}}>{this.state.errorMessage}</div>
        }

        { this.buildActions() }

      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const providerProducts = getEntityItems(state, "COMPANIES.ORDERS.CONTROL.PROVIDERS.PRODUCTSPARAMS");
  const isProductsLoading = state?.api['COMPANIES.ORDERS.CONTROL.PROVIDERS.PRODUCTSPARAMS']?.status?.isFetching || false;
  const isOwnProductsLoading = state?.api['COMPANIES.PRODUCTS']?.status?.isFetching || false;
  const isUpdatingLoading = state?.api['COMPANIES.ORDERS.CONTROL.PROVIDERS.NEWPRODUCTS']?.status?.isFetching || false;

  return {
    companyId,
    providerProducts,
    isProductsLoading: isOwnProductsLoading || isProductsLoading,
    isUpdatingLoading
  };
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());

  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    loadProducts: (params, opt) => dispatch(readEntities("COMPANIES.PRODUCTS", params, opt)),
    loadProviderProducts: (params, opts) => dispatch(readEntities("COMPANIES.ORDERS.CONTROL.PROVIDERS.PRODUCTSPARAMS", params, opts)),
    addProducts: (params, opts) => dispatch(partialEntity("COMPANIES.ORDERS.CONTROL.PROVIDERS.NEWPRODUCTS", params, opts)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProductRequisitionDeliveryModal);
