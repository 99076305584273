import React, { useState, useMemo, useCallback, createRef } from "react";
import moment from 'moment';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from "../../../shared/controls/button";
import { AG_GRID_LOCALE_ES } from "../../../shared/language";
import { RequisitionText } from './style';
import ImageWithCheckMark from "../../../shared/component/image-with-check-mark";
import { getUrlsEnv } from "../../../shared/backend-api";

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }
};

const getCellView = (key, params) => {
  let view = null;

  switch(key) {
    case 'status':
      view = <RequisitionText>{params.status?.description || '-'}</RequisitionText>
      break;
    case 'facility':
      view = <RequisitionText>{params.name || '-'}</RequisitionText>
      break;
    case 'company':
      view = <RequisitionText>
        <div style={{marginTop: 6}}>
          <ImageWithCheckMark
            src={getUrlsEnv().files.concat(params.logo?.substring(1) || '')}
            width={30}
            isCheckMark={params.verification_status === 3}
            customStyle={{
              width: 30, height: 30, borderRadius: '50%', position: 'relative', marginRight: 12
            }}
            isCo={true}
          />
        </div>
        <div>{params.orbi_id}</div>
      </RequisitionText>
      break;
    default:
      view = <div></div>;
  }

  return view
};


export const RequisitionView = (props) => {

  const gridRef = createRef();
  const [rowData, setRowData] = useState(props.rowData);
  
  const [colDefs, setColDefs] = useState([
    { headerName: 'Fecha de creación de pedido', field: 'created', minWidth: 250, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, checkboxSelection: true, sortable: true, headerCheckboxSelection: true },
    { headerName: 'Fecha de última actualización', field: 'modified', minWidth: 250, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, sortable: true },
    { headerName: 'ID de pedido', field: 'requisition_id', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 200, sortable: true },
    { headerName: 'Número de pedido', field: 'code', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 200, sortable: true },
    { headerName: 'Peso bruto total de pedido', field: 'total_weight', minWidth: 250, filter: 'agNumberColumnFilter', suppressMenu: true, sortable: true },
    { headerName: 'Volumen total de pedido', field: 'total_volume', minWidth: 230, filter: 'agNumberColumnFilter', suppressMenu: true, sortable: true },
    { headerName: 'Variantes SKU', field: 'sku_variants', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true },
    { headerName: 'Cantidad piezas', field: 'sku_quantities', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true },
    { headerName: 'Entregas', field: 'deliveries_count', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true },
    { headerName: 'Contador de ordenes', field: 'orders_count', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true },
    { headerName:  props.transactionType === 1 ? 'Proveedor' : props.transactionType === 2 ? 'Cliente' : 'Proveedor/Cliente', field: 'company', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true,
      cellRenderer: params => {
        return getCellView('company', params.data?.from_facility?.company || {})
      }
    },
    { headerName: 'Origen', field: 'origin_name', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true,
      cellRenderer: params => {
        return getCellView('facility', params.data?.from_facility || {})
      }
    },
    { headerName: 'Destino', field: 'destiny_name', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true,
      cellRenderer: params => {
        return getCellView('facility', params.data?.to_facility || {})
      }
    },
    { headerName: 'Estatus pedido', field: 'requisition_status_description', minWidth: 200, filter: true, suppressMenu: true, sortable: true,
      cellRenderer: params => {
        return getCellView('status', params.data || {})
      }
    },
    { headerName: 'CTA', field: 'cta', minWidth: 230, suppressMenu: true, 
      cellRenderer: params => {
        const isRow = params.node.data;
        if (!isRow) { return <div></div> }

        let status_id = params.data?.status?.id || 0;
        let requisition_id = params.data?.id || 0;
        let code = params.data?.code || '';

        if (status_id === 1) {
          return(
            <div style={{width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Button 
                text={'Confirmar'}
                type='primary small'
                onClick={() => {
                  if (props.isModeSelection) return;

                  props.handleChange({
                    target: {
                      name: "confirm_requisition",
                      value: {id: requisition_id, code }
                    }
                  });

                }}
              />
            </div>
          );
        } else if (status_id === 2) {
          return(
            <div style={{width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Button 
                text={'Convertir pedido en Orden'}
                type='primary small'
                onClick={() => {
                  if (props.isModeSelection) return;
                  
                  props.handleChange({
                    target: {
                      name: "convert_delivery_to_order",
                      value: {requisition: params.data, deliveries: params.data.deliveries}
                    }
                  });
                }}
              />
            </div>
          );
        } else {
          return <div/>;
        }
      }
    }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      floatingFilter: true,
      tooltipComponent: params => {
        return(
          <div
            style={{
              backgroundColor: 'black',
              color: 'white',
              padding: 10
            }}
          >
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "test",
                    value: 'test'
                  }
                })
              }}
            >{'Cancelar pedido'}</div>
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "test",
                    value: 'test'
                  }
                })
              }}
            >{'Añadir producto nuevo'}</div>
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "test",
                    value: 'test'
                  }
                })
              }}
            >{'Dividir pedido'}</div>
          </div>
        );
      },
    };
  }, []);

  const onGridReady = useCallback((params) => {

    if (props.allSelected) {
      params.api.selectAll()
    }

    return;

    if (props.configuration['filter'] !== undefined && typeof props.configuration['filter'] === "object") {
      gridRef.current.api.setFilterModel(
        props.configuration['filter']
      );
    }

    if (props.configuration['columns'] !== undefined && typeof props.configuration['columns'] === "object") {

      let temp_columns = props.configuration['columns'];

      if (temp_columns.length === colDefs.length) {
        gridRef.current.api.setColumnDefs(
          temp_columns
        );
        return;
      }

      try {

        let currentColumns = colDefs.map( item => item.field);
        let currentColumnsSaved = props.configuration['columns'].map( item => item.field);
        let difference = currentColumns.filter(x => !currentColumnsSaved.includes(x));

        let newColumnsToAdd = [];

        colDefs.map( (item, index) => {
          if (difference.includes(item.field)) {
            newColumnsToAdd.push({
              index,
              column: item
            })
          }
        });

        newColumnsToAdd.map( item => {
          temp_columns.splice(item.index, 0, item.column);
        });

        gridRef.current.api.setColumnDefs(
          temp_columns
        );

      } catch (error) {
        console.error('Error setting columns definitions', error);

        gridRef.current.api.setColumnDefs(
          temp_columns
        );
      }
    }

  }, []);

  return (
    <div>
      <div
        className="ag-theme-alpine"
        style={{ height: 650, marginTop: 20, marginBottom: 50 }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          enableCellTextSelection={true}
          suppressRowClickSelection={true}
          rowHeight={50}
          rowSelection="multiple"
          localeText={AG_GRID_LOCALE_ES}
          defaultColDef={defaultColDef}
          
          onCellClicked={ params => {
            if (props.isModeSelection) return;

            props.handleChange({
              target: {
                name: "requisition_detail",
                value: params.data
              }
            });
          }}
          onSelectionChanged ={ params => {
            props.handleChange({
              target: {
                name: "requisitions_selected",
                value: (gridRef?.current?.api?.getSelectedRows() || []).map( item => item.requisition_id)
              }
            });
          }}
          onGridReady={onGridReady}
        />
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'end',
        marginBottom: 20
      }}>
      </div>
    </div>
  );
}