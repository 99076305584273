import SocketIO from "./socket-io";
import { validateEmail } from "./validate";
import React from "react";
import { toast } from "react-toastify";

const months_abbr = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const months_full = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const USER_FORMAT = "USER_FORMAT";
export const LOG_FORMAT = "LOG_FORMAT";
export const US_FORMAT = "US_FORMAT";
export const US_HOURLY_FORMAT = "US_HOURLY_FORMAT";
export const DISPLAY_FORMAT = "DISPLAY_FORMAT";
export const DOCS_FORMAT = "DOCS_FORMAT";
export const TRAFFIC_FORMAT = "TRAFFIC_FORMAT";
export const LOCK_FORMAT = "LOCK_FORMAT";

export const getLastSession = (lastSession, type = "") => {
  let date = "";
  if (Date.parse(lastSession)) {
    date = new Date(lastSession);

    let dateFormatOptions = "";
    let d;
    switch (type) {
      case USER_FORMAT:
        dateFormatOptions = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        date = date.toLocaleDateString("es-ES", dateFormatOptions);
        break;
      case LOG_FORMAT:
        dateFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        date = date.toLocaleDateString("es-ES", dateFormatOptions);
        break;
      case US_FORMAT:
        dateFormatOptions = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        };
        date = date.toLocaleDateString("en-US", dateFormatOptions);
        break;
      case US_HOURLY_FORMAT:
        dateFormatOptions = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        date = date.toLocaleDateString("en-US", dateFormatOptions);
        break;
      case DOCS_FORMAT:
        d = date.toISOString();
        date = `${d.substring(0, 4)}-${d.substring(5, 7)}-${d.substring(
          8,
          10
        )}`;
        break;
      case DISPLAY_FORMAT:
        dateFormatOptions = { year: "numeric", month: "long", day: "2-digit" };
        date = date.toLocaleDateString("es-ES", dateFormatOptions);
        break;
      case TRAFFIC_FORMAT:
        date = "".concat(
          date.getDate(),
          "/",
          months_abbr[date.getMonth()],
          " ",
          String(date.getHours()).padStart(2, '0'),
          ":",
          String(date.getMinutes()).padStart(2, '0')
        );
        break;
      case LOCK_FORMAT:
        date = "".concat(
          date.getDate(),
          "/",
          months_abbr[date.getMonth()]
        );
        break;
      default:
        d = date.toISOString();
        date = "".concat(
          d.substring(11, 16),
          "hr / ",
          d.substring(8, 10),
          " ",
          months_abbr[Number(d.substring(5, 7)) - 1],
          " ",
          d.substring(2, 4)
        );
        break;
    }
  }

  return date;
};

export const getContrastYIQ = (hexcolor) => {
  if (hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 150 ? "black" : "white";
  }
  return "black";
};

export const normalizeStr = (str) => {
  // console.log("STR", str);
  if (str)
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  return "";
};

export const handleWSError = (wsError) => {
  if (wsError) {
    const errors = Object.values(wsError);
    let messageError = "";

    if (errors && errors.length) {
      for (var i = 0; i < errors.length; i++) {
        const error = errors[i];
        if (Array.isArray(error)) {
          const tempMessage = error.join(", ");

          if (i === error.length - 1) {
            messageError += tempMessage.concat(" ");
          } else {
            messageError += tempMessage;
          }
        } else {
          messageError = "Ocurrió un error, verifique sus datos";
        }
      }
    }

    return messageError;
  }

  return "Ocurrió un error, verifique sus datos";
};

export const handleWSErrorWithKeys = (wsError) => {
  if (wsError) {
    const errors = Object.values(wsError);
    const keys = Object.keys(wsError);
    let messageError = "";

    if (errors && errors.length) {
      for (var i = 0; i < errors.length; i++) {
        const error = errors[i];
        if (Array.isArray(error)) {
          const tempMessage = `*${keys[i]} - ${error.join(", ")} `;

          if (i === error.length - 1) {
            messageError += tempMessage.concat(" ");
          } else {
            messageError += tempMessage;
          }
        } else if (Array.isArray(errors) && errors.length > 0) {
          messageError = errors.join(", ");
        } else {
          messageError = "Ocurrió un error, verifique sus datos";
        }
      }
    }

    return messageError;
  }

  return "Ocurrió un error, verifique sus datos";
};

export const restrictInteger = (value) => {
  return RegExp(/^[0-9]*$/).test(value);
};

export const arrayDiff = (arr1 = [], arr2 = []) => {
  let result = [];
  arr1.forEach((a1) => {
    if (arr2.find((a2) => a2 === a1) === undefined) {
      result.push(a1);
    }
  });
  return result;
};

export const dateAddDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const dateAddHours = (date, hours) => {
  date.setHours(date.getHours() + hours);
  return date;
};

export const groupFacilities = (rawFacilities) => {
  if(!Array.isArray(rawFacilities) || !rawFacilities.length ) return [];

  let facilitiesByTag = [
    { id: 9999999999, description: "Sin etiqueta", items: [] },
  ];
  
  (rawFacilities || []).forEach((f) => {
    if ((f.tags || []).length) {
      f.tags.forEach((t) => {
        let tagIndex = facilitiesByTag.map((fT) => fT.id).indexOf(t.id);
        if (tagIndex !== -1) {
          facilitiesByTag[tagIndex].items.push({
            id: f.id,
            description: f.name.concat(" - ", f.code, " - ", f.alias),
          });
        } else {
          let newTag = {
            id: t.id,
            description: t.title,
            items: [
              {
                id: f.id,
                description: f.name.concat(" - ", f.code, " - ", f.alias),
              },
            ],
          };
          facilitiesByTag.push(newTag);
        }
      });
    } else {
      facilitiesByTag[0].items.push({
        id: f.id,
        description: f.name.concat(" - ", f.code, " - ", f.alias),
      });
    }
  });
  
  facilitiesByTag = facilitiesByTag.map((tag) => {
    let sortedItems = tag.items.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    return { ...tag, items: sortedItems };
  });
  
  let tagless = facilitiesByTag.shift();
  
  facilitiesByTag = facilitiesByTag.sort((a, b) => {
    if (a.description.toLowerCase() > b.description.toLowerCase()) {
      return 1;
    }
    if (a.description.toLowerCase() < b.description.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  
  facilitiesByTag.push(tagless);

  facilitiesByTag = facilitiesByTag.filter(f => f.items.length);

  return facilitiesByTag;
}

const buildDescription = (values, nameIncluded, codeIncluded, aliasIncluded) => {

  var newValueToCreate = [];

  values.map((item, index) => {
    if (nameIncluded === true && index === 0) {
      newValueToCreate = newValueToCreate.concat(item);
    } else if (codeIncluded === true && index === 1) {
      newValueToCreate = newValueToCreate.concat(item);
    } else if (aliasIncluded === true && index === 2) {
      newValueToCreate = newValueToCreate.concat(item);
    }
  });

  if (newValueToCreate.length === 0) {
    return 'Sin descripción';
  } else {
    return newValueToCreate.join(' - ');
  }

}

export const groupFacilitiesCustom = (rawFacilities, nameIncluded, codeIncluded, aliasIncluded) => {
  if(!Array.isArray(rawFacilities) || !rawFacilities.length ) return [];


  let facilitiesByTag = [
    { id: 9999999999, description: "Sin etiqueta", items: [] },
  ];
  
  (rawFacilities || []).forEach((f) => {
    if ((f.tags || []).length) {
      f.tags.forEach((t) => {
        let tagIndex = facilitiesByTag.map((fT) => fT.id).indexOf(t.id);
        if (tagIndex !== -1) {
          facilitiesByTag[tagIndex].items.push({
            id: f.id,
            description: buildDescription([f.name, f.code, f.alias], nameIncluded, codeIncluded, aliasIncluded),
          });
        } else {
          let newTag = {
            id: t.id,
            description: t.title,
            items: [
              {
                id: f.id,
                description: buildDescription([f.name, f.code, f.alias], nameIncluded, codeIncluded, aliasIncluded),
              },
            ],
          };
          facilitiesByTag.push(newTag);
        }
      });
    } else {
      facilitiesByTag[0].items.push({
        id: f.id,
        description: buildDescription([f.name, f.code, f.alias], nameIncluded, codeIncluded, aliasIncluded),
      });
    }
  });
  
  facilitiesByTag = facilitiesByTag.map((tag) => {
    let sortedItems = tag.items.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    return { ...tag, items: sortedItems };
  });
  
  let tagless = facilitiesByTag.shift();
  
  facilitiesByTag = facilitiesByTag.sort((a, b) => {
    if (a.description.toLowerCase() > b.description.toLowerCase()) {
      return 1;
    }
    if (a.description.toLowerCase() < b.description.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  
  facilitiesByTag.push(tagless);

  facilitiesByTag = facilitiesByTag.filter(f => f.items.length);

  return facilitiesByTag;
}

export const getFilterParamsInitialDateTime: Date = (
  actualDate: Date,
  valueToAdd: number,
  segment: string
) => {
  switch (segment) {
    case "HOUR":
      return new Date(
        actualDate.getFullYear(),
        actualDate.getMonth(),
        actualDate.getDate() + valueToAdd,
        actualDate.getHours() + valueToAdd
      );
      // break;
    case "DAY":
      return new Date(
        actualDate.getFullYear(),
        actualDate.getMonth(),
        actualDate.getDate() + valueToAdd
      );
      // break;
    case "WEEK":
      return new Date(
        actualDate.getFullYear(),
        actualDate.getMonth(),
        actualDate.getDate() - actualDate.getDay() + 1 + valueToAdd * 7
      );
      // break;
    case "MONTH":
      return new Date(
        actualDate.getFullYear(),
        actualDate.getMonth() + valueToAdd
      );
      // break;
    default:
  }
};

export const getTimeZone = () => {
  // Esta funcion es para envia por url
  let timezone = `${Intl.DateTimeFormat().resolvedOptions().timeZone || ''}`;
  return timezone.replace('+', '%2B').replace('-', '%2D')
}

export const getTimeZoneInfo = () => {
  // Esta funcion es para obtener el timezone sin modificacion
  let timezone = `${Intl.DateTimeFormat().resolvedOptions().timeZone || ''}`;
  return timezone
}

export const sendMessageToMultipleUsersInSocket = (current_user_id, users, data) => {
  users.filter( item => item.user_id !== current_user_id).map( item => {
    SocketIO.sendMessage(
      'user-update',
      `user-${item.user_id}-${item.company_id}`,
      data
    );
  });
}

export const isPremiumSubscriptionCo = (session) => {

  let isCo = session.company?.type?.id || 2 === 2;

  if (!isCo) return null;
  return (session.company && 'is_premium_subscription' in session.company && session.company.is_premium_subscription);
}

export const tranformSpecialCharacterUrl = (value) => {
  return value.replace('+', '%2B').replace('-', '%2D') 
}

export const getPriceFormatNumber = (price) => {
  try {
    return new Intl.NumberFormat("en", {
      maximumFractionDigits: 2, 
      minimumFractionDigits: 2, 
      style: 'currency', 
      currency: 'USD'
    }).format(price);
  } catch(error) {
    return 0;
  }
}

export const validateEmails = (string) => {
  try {
    let value_string = string.replaceAll(' ', '').split(',');
    let emails = value_string.filter( item => validateEmail(item));

    return emails.join(',');
  } catch(error) {
    return '';
  }
}

export const isValidationCompanyNotified = (company_id, validation_status) => {

  try {
    let notification_data = JSON.parse(window.sessionStorage.getItem("ValidationCompanyData"));
    return (notification_data.company_id === company_id && notification_data.validation_status === validation_status);
  } catch(error) {
    return false
  }
  
}

export const setValidationCompanyNotified = (company_id, validation_status) => {
  window.sessionStorage.setItem(
    "ValidationCompanyData",
    (company_id === null || validation_status === null) ? null : JSON.stringify({ company_id: company_id, validation_status: validation_status })
  );
}

// shopping car functions

export const getShoppingCartData = () => {
  try {
    let shopping = JSON.parse(window.sessionStorage.getItem("ShoppingCartData"));
    return shopping['items'];
  } catch(error) {
    return [];
  }
}

export const removeShoppingCart = () => {
  try {
    window.sessionStorage.setItem(
      "ShoppingCartData",
      null
    );
  } catch(error) {
    return false;
  }
}

export const addItemShoppingCart = (item, callback) => {
  try {
    let shopping = JSON.parse(window.sessionStorage.getItem("ShoppingCartData"));

    let items = (shopping && 'items' in shopping) ? shopping['items'] : [];
    items.push(item);

    window.sessionStorage.setItem(
      "ShoppingCartData",
      JSON.stringify({ items })
    );

    if (callback) {
      callback();
    }

  } catch(error) {
    console.log(error);
    return false;
  }
}

export const removeItemShoppingCart = (item_id, callback) => {
  try {
    let shopping = JSON.parse(window.sessionStorage.getItem("ShoppingCartData"));

    let items = (shopping && 'items' in shopping) ? shopping['items'] : [];

    items = items.filter( item => item.id !== item_id);

    window.sessionStorage.setItem(
      "ShoppingCartData",
      JSON.stringify({ items })
    );

    if (callback) {
      callback();
    }

  } catch(error) {
    console.log(error);
    return false;
  }
}

export const showSimpleFlashNotification = (text, action) => {
  toast.success(
    <div>
      <img
        alt=""
        src="/images/white-check.svg"
        style={{ marginRight: "8px", marginLeft: "4px" }}
      />
      {text}
    </div>,
    {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "my-toast-class",
      onClick: () => {
        if (action) {
          action();
        }
      }
    }
  );
};

export const validateJson = (data) => {

  if (Array.isArray(data)){
    return false;
  }

  return (typeof data === 'object');

}
// tab functions

export const getCurrentTabSelected = () => {
  try {
    let tab = JSON.parse(window.sessionStorage.getItem("CurrentTabSelected"));
    return tab;
  } catch(error) {
    return null;
  }
}

export const setCurrentTabSelected = (tab) => {
  try {
    window.sessionStorage.setItem(
      "CurrentTabSelected",
      tab
    );
  } catch(error) {
    return false;
  }
}

export const calculateGrossWeightProducts = (products) => {
  let total = 0;

  products.map( product => {
    if (product.gross_weight_description === 'tne') {
      if (product.gross_weight)
        total = total + ((parseFloat(product.gross_weight) * parseFloat(product.qty_per_sku)) * 1000);
    } else {
      if (product.gross_weight)
        total = total + (parseFloat(product.gross_weight) * parseFloat(product.qty_per_sku));
    }
  })

  return `${total} kg`
}

export const calculateNetWeightProducts = (products) => {
  let total = 0;

  products.map( product => {
    if (product.net_weight_description === 'tne') {
      if (product.net_weight)
        total = total + ((parseFloat(product.net_weight) * parseFloat(product.qty_per_sku)) * 1000);
    } else {
      if (product.net_weight)
        total = total + (parseFloat(product.net_weight) * parseFloat(product.qty_per_sku));
    }
  })

  return `${total} kg`
}

export const calculateVolumeProducts = (products) => {
  let total = 0;

  products.map( product => {
    if (product.volume)
      total = total + (parseFloat(product.volume) * parseFloat(product.qty_per_sku));
  })

  return `${total} mtq`
}
