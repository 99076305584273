import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";
import { cloneDeep } from "lodash";
import TextInput from "../controls/text-input";
import { partialEntity } from "../../redux/api";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import Loader from "../icons/tat/loader";
import { connect } from "react-redux";

Modal.setAppElement("#root");

class DivideRequisitionDeliveryModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      deliveries: [cloneDeep(props.deliveryData)],
      requisitionId: props.requisition_id,
      deliveryIdEditingTemp: null,
      deliveryIdOpenedTemp: null,
      deliveryIdOpened: null,
      deliveryIdEditing: null,
      productNameSku: '',
      hasPendingChanges: false,
      hasDeliveriesToSave: false,
      messageSuccess: false,
      errorMessage: null
    }
  }

  buildTitle = () => {
    return(
      <>
        <div className="title-action">{'DIVIDIR ENTREGA'}</div>
        <div className="subtitle">{``}</div>
      </>
    );
  }

  buildDeliveriesList = () => {
    if (this.state.hasPendingChanges || this.state.hasDeliveriesToSave || this.state.messageSuccess) { return ''; }

    return(
      <div className="deliveries-list-content">
        {
          (this.state.deliveries || []).map( (item, index) => this.buildDeliveryItem(item, index))
        }
      </div>
    );
  }

  getDeliveryName = (item, index) => {
    let total_count = (this.state.deliveries.length - 1) + parseInt(item.total_deliveries);
    if (index === 0) {
      return `${item.requisition_code} - ${parseInt(item.index) + 1}/${total_count}`  
    }
    
    return `${item.requisition_code} - ${parseInt(item.total_deliveries) + index}/${total_count}`  
  }

  buildDeliveryItem = (item, index) => {
    return(
      <div className="delivery-item-wrap" key={`delivery-item-${index}`}>
        <div className="delivery-item">
          <div style={{display: 'flex', flex: '2'}}>
            <div
              className={"collapser".concat(
                this.state.deliveryIdOpened === item.id ? " collapsed" : ""
              )}
              onClick={() => {
                this.setState({
                  productNameSku: '',
                  deliveryIdOpened: this.state.deliveryIdOpened === item.id ? null : item.id
                })
              }}
            >
              <img alt="" src="/images/select-arrow.svg" />
            </div>
            {/* <div style={{marginLeft: 10}}>{`Parte ${index + 1}/${this.state.deliveries.length}`}</div> */}
            <div style={{marginLeft: 10}}>{this.getDeliveryName(item, index)}</div>
          </div>
          
          <div style={{display: 'flex', flex: '1', justifyContent: 'center'}}>
            {
              this.state.deliveryIdEditing !== item.id &&
              <Button
                text={'Dividir entrega'}
                type={'btn small outline primary dark'}
                onClick={() => {

                  if (this.hasDeliveryChanged()) {
                    this.setState({
                      hasPendingChanges: true,
                      deliveryIdEditingTemp: item.id,
                      deliveryIdOpenedTemp: item.id,
                      errorMessage: null
                    })
                  } else {
                    this.setState({
                      deliveryIdEditing: item.id,
                      deliveryIdOpened: item.id,
                      errorMessage: null
                    });
                  }
                }}
              />
            }
          </div>
        </div>

        { this.buildProducts(item.id, item.products, this.state.deliveryIdOpened === item.id) }

        { this.buildSave(item.id, this.state.deliveryIdOpened === item.id) }

      </div>
    );
  }

  hasDeliveryChanged = () => {

    let deliveries = (this.state.deliveries || []).filter( delivery => delivery.id === this.state.deliveryIdEditing);
    let has_changed = false;

    if (deliveries.length > 0) {
      (deliveries[0].products || []).map( product => {
        if (`${deliveries[0].id}-${product.id}` in this.state && this.state[`${deliveries[0].id}-${product.id}`] !== '') {
          has_changed = true;
        }
      });
    }

    return has_changed;
  }

  generateNewDelivery = () => {

    let deliveries = (this.state.deliveries || []).filter( delivery => delivery.id === this.state.deliveryIdEditing);

    if (deliveries.length === 0) return;

    let new_delivery = cloneDeep(deliveries[0]);
    let last_delivery = this.state.deliveries[this.state.deliveries.length - 1];

    new_delivery.id = parseInt(last_delivery.id) + 1;
    new_delivery.products = [];

    (deliveries[0].products || []).map( (product, index) => {
      if (`${deliveries[0].id}-${product.id}` in this.state && this.state[`${deliveries[0].id}-${product.id}`] !== '') {
        let new_product = cloneDeep(product);
        new_product.qty_per_sku = parseInt(this.state[`${deliveries[0].id}-${product.id}`]);
        deliveries[0].products[index].qty_per_sku = parseInt(deliveries[0].products[index].qty_per_sku) - parseInt(this.state[`${deliveries[0].id}-${product.id}`]);
        this.state[`${deliveries[0].id}-${product.id}`] = '';

        new_delivery.products.push(new_product);
      }
    });

    this.state.deliveries.push(new_delivery);
    this.state.deliveryIdOpened = null;
    this.state.deliveryIdEditing = null;
    this.state.errorMessage = null;
    this.setState({});

  }

  buildSave = (requisition_id, isOpened) => {
    if (!isOpened) { return ''; }

    return(
      <div style={{display: 'flex', height: 50}}>

        <div style={{display: 'flex', flex: 2}}/>
        <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          {
            this.state.deliveryIdEditing === requisition_id &&
            <Button
              disabled={!this.hasDeliveryChanged()}
              text={'Guardar'}
              type={'btn small outline primary dark'}
              onClick={() => {
                this.generateNewDelivery()
              }}
            />
          }
        </div>

      </div>
    );
  }

  buildProducts = (requisition_id, products, isOpened) => {

    if (!isOpened) { return ''; }

    return(
      <>
        {
          this.buildHeaderProducts()
        }
        {
          (products || []).filter( product => {
            if (this.state.productNameSku === '') return true;

            return product.product_name.toLowerCase().includes(this.state.productNameSku.toLowerCase()) || product.sku.toLowerCase().includes(this.state.productNameSku.toLowerCase());

          }).map( (product, index) => this.buildProductItem(index, requisition_id, product))
        }
      </>
    );
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  buildHeaderProducts = () => {
    return(
      <>
        <div style={{display: 'flex', marginBottom: 12}}>
          <input
            type="text"
            name={'productNameSku'}
            className="text-input-custom"
            onChange={this.handleChange}
            placeholder={'Buscar producto o sku'}
            value={this.state.productNameSku}
            autocomplete={'off'}
          />
        </div>
        <div className="product-wrapper">
          <div className="product-header">PRODUCTO</div>
          <div className="product-header">QTY</div>
          <div className="product-header">QTY</div>
        </div>
      </>
    );
  }

  getQty = (requisition_id, product_id) => {
    if (`${requisition_id}-${product_id}` in this.state) {
      return this.state[`${requisition_id}-${product_id}`]
    }

    return '';
  }

  buildProductItem = (index, requisition_id, product) => {
    return(
      <div className="product-wrapper" key={`product-wrapper-${index}`}>
        <div className="product-column-1">
          <div>{product.product_name}</div>
          <div>{product.sku}</div>
        </div>
        <div className="product-column-2">{product.qty_per_sku}</div>
        <div className="product-column-3">
          {
            this.state.deliveryIdEditing === requisition_id &&
            <input
              style={{textAlign: 'center'}}
              className="text-input-custom"
              name="editable"
              type="number"
              value={this.getQty(requisition_id, product.id)}
              autocomplete={'off'}
              onChange={(e) => {
                this.setState({
                  [`${requisition_id}-${product.id}`]: e.target.value
                })
              }}
              onBlur={(e) => {
                let value = parseInt(e.target.value);
                let limit = parseInt(product.qty_per_sku);

                if (value < 1 || value >= limit) {
                  this.setState({
                    [`${requisition_id}-${product.id}`]: ''
                  });
                }
              }}
            />
          }
        </div>
      </div>
    );
  }

  onClose = () => {
    if (this.props.isUpdatingLoading) return;
    if (this.props.closeAction) this.props.closeAction();
  };

  buildActions = () => {
    if (this.state.hasPendingChanges || this.state.hasDeliveriesToSave || this.state.messageSuccess) { return ''; }

    return(
      <div className="actions">
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center'
          }}
        >
          <Button
            disabled={this.props.isUpdatingLoading}
            text={'Cancelar'}
            type={'btn secondary primary'}
            onClick={() => {
              if (this.state.deliveries.length > 1) {
                this.setState({
                  hasDeliveriesToSave: true
                });
              } else {
                this.onClose();
              }
            }}
          />

          <div style={{width: 20}}/>

          <Button
            disabled={this.state.deliveries.length === 1 || this.props.isUpdatingLoading}
            text={this.props.isUpdatingLoading ? <Loader circleColor={'black'}/> : 'Guardar'}
            type={'btn outline primary dark'}
            onClick={() => {

              let update_delivery = null;
              let new_deliveries = [];

              this.state.deliveries.map( (delivery, index) => {

                if (index === 0) {
                  update_delivery = {
                    id: delivery.id,
                    products: delivery.products.map( product => ({id: product.id, qty: product.qty_per_sku}))
                  };
                } else {
                  new_deliveries.push({
                    id: delivery.id,
                    products: delivery.products.map( product => ({id: product.id, qty: product.qty_per_sku}))
                  })
                }
              });

              this.props.divideDelivery({
                company_id: this.props.companyId,
                requisition_id: this.state.requisitionId,
                delivery_id: update_delivery.id,
                action: 'divide_delivery',
                update_delivery,
                new_deliveries
              }, {
                onSuccess: () => {
                  this.props.handleChange({
                    target: {
                      name: "divide_delivery",
                      value: null
                    }
                  });

                  this.setState({messageSuccess: true});
                }, 
                onError: (e) => {
                  this.setState({
                    errorMessage: e.response?.data?.error || 'Ocurrío un error, intente de nuevo'
                  })
                }
              })

            }}
          />
        </div>
      </div>
    );
  }

  clearChanges = () => {
    let deliveries = (this.state.deliveries || []).filter( delivery => delivery.id === this.state.deliveryIdEditing);

    if (deliveries.length === 0) return;

    (deliveries[0].products || []).map( (product, index) => {
      if (`${deliveries[0].id}-${product.id}` in this.state && this.state[`${deliveries[0].id}-${product.id}`] !== '') {
        this.state[`${deliveries[0].id}-${product.id}`] = '';
      }
    });

    this.setState({});
  } 

  render() {
    return (
      <Modal
        isOpen={this.props.deliveryData !== null}
        portalClassName={`dialog divide-requisition-delivery-dialog ${
          this.props.addClass ? this.props.addClass : ""
        }`}
        overlayClassName="overlay"
        className="content"
      >
        <div className="close" onClick={() => {
          if (this.state.deliveries.length > 1) {
            this.setState({
              hasDeliveriesToSave: true
            });
          } else {
            this.onClose();
          }
        }}></div>

        { this.buildTitle() }
        { this.buildDeliveriesList() }

        {
          this.state.hasPendingChanges && 
          <div style={{marginTop: 20, marginBottom: 30}}>
            <div>{'Al realizar esta acción, tus cambios pendientes se borrarán, ¿quieres continuar?'}</div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
              <Button
                text={'Cancelar'}
                type={'btn secondary'}
                onClick={() => this.setState({hasPendingChanges: false})}
              />

              <div style={{width: 20}}/>

              <Button
                text={'Continuar'}
                type={'btn primary'}
                onClick={() => {
                  this.clearChanges();

                  this.setState({
                    hasPendingChanges: false,
                    deliveryIdEditing: this.state.deliveryIdEditingTemp,
                    deliveryIdOpened: this.state.deliveryIdOpenedTemp
                  });
                }}
              />
            </div>
          </div>
        }

        {
          this.state.hasDeliveriesToSave &&
          <div style={{marginTop: 20, marginBottom: 30}}>
            <div>{'Tienes sub pedidos sin guardar, ¿quieres continuar?'}</div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
              <Button
                text={'Cancelar'}
                type={'btn secondary'}
                onClick={() => this.setState({hasDeliveriesToSave: false})}
              />

              <div style={{width: 20}}/>

              <Button
                text={'Continuar'}
                type={'btn primary'}
                onClick={() => {this.onClose()}}
              />
            </div>
          </div>
        }

        {
          this.state.messageSuccess &&
          <div style={{marginTop: 20, marginBottom: 30}}>
            <div>{'Se guardaron con éxito tu cambios'}</div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
              <Button
                text={'Continuar'}
                type={'btn primary'}
                onClick={() => {this.onClose()}}
              />
            </div>
          </div>
        }

        {
          this.state.errorMessage && <div style={{color: 'red', fontSize: 13}}>{this.state.errorMessage}</div>
        }

        { this.buildActions() }

      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const isUpdatingLoading = state?.api['COMPANIES.ORDERS.CONTROL.PROVIDERS.DIVIDEDELIVERY']?.status?.isFetching || false;

  return {
    companyId,
    isUpdatingLoading
  };
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());

  return {
    divideDelivery: (params, opts) => dispatch(partialEntity("COMPANIES.ORDERS.CONTROL.PROVIDERS.DIVIDEDELIVERY", params, opts)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DivideRequisitionDeliveryModal);