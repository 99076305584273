import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";
import { cloneDeep } from "lodash";
import TextInput from "../controls/text-input";
import { partialEntity, readEntities } from "../../redux/api";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import Loader from "../icons/tat/loader";
import { connect } from "react-redux";
import ImageWithCheckMark from "../component/image-with-check-mark";
import { getUrlsEnv } from "../backend-api";
import { RecommendationError } from "../../control/orders/consolidate-trip-modal";
import { calculateGrossWeightProducts, calculateVolumeProducts, US_HOURLY_FORMAT } from "../utils";
import SVGIcon, { icons } from "../svg-icon";
import Tag from "../controls/tag";
import { cancelMultipleRequests } from "../../redux/api/actions";
import CustomDatePicker from "../component/custom-date-picker";

Modal.setAppElement("#root");

class ConvertDeliveryToOrderModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      requisition: cloneDeep(props.requitition),
      deliveries: cloneDeep(props.deliveries),
      deliveriesInCommon: [],
      selectedDate: null,
      selectedUnloadDate: null,
      selectedCode: '',
      type: '',
      class: '',
      level: '',
      trailers: '',
      isConsolidateDeliveries: false,
      deliveriesToAdd: [],
      selectedTags: [],
      deliveryInCommonEdition: null,
      dateTarget: null,
      
      hasPendingChanges: false,
      hasDeliveriesToSave: false,
      messageSuccess: false,
      errorMessage: null
    }
  }

  buildTitle = () => {
    return(
      <>
        <div className="title-action">{ this.state.isConsolidateDeliveries ? 'CONSOLIDACIÓN DE ENTREGAS' : 'CONVERTIR ENTREGA EN ORDEN'}</div>
      </>
    );
  }

  buildDateInput = () => {

    let date_value = null;

    if (this.state.isConsolidateDeliveries) {
      date_value = this.state.deliveryInCommonEdition.type === 1 ? this.state.deliveriesInCommon[this.state.deliveryInCommonEdition.index].collection_date : this.state.deliveriesInCommon[this.state.deliveryInCommonEdition.index].delivery_date;
    } else {
      date_value = this.state.deliveryInCommonEdition.type === 1 ? this.state.deliveries[this.state.deliveryInCommonEdition.index].collection_date : this.state.deliveries[this.state.deliveryInCommonEdition.index].delivery_date;
    }

    if (date_value !== null) {

      let date_value_components = date_value.split('/');
      if (date_value_components.length > 2) {
        date_value = new Date(
          date_value_components[2],
          date_value_components[1] - 1,
          date_value_components[0]
        )
      }
    }

    return(
      <TextInput
        simple={true}
        type="date"
        format={"dd/MMM/yyyy"}
        minDate={
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 1
          )
        }
        value={date_value}
        name="selectedDate"
        onChange={(e) => {
          let date_components = (e.target.value || '').split('/');

          if (date_components.length > 2) {
            if (this.state.deliveryInCommonEdition.type === 1) {

              if (this.state.isConsolidateDeliveries) {
                this.state.deliveries[this.state.deliveryInCommonEdition.index].collection_date = `${date_components[1]}/${date_components[0]}/${date_components[2]}`;
              } else {
                this.state.deliveries[this.state.deliveryInCommonEdition.index].collection_date = `${date_components[1]}/${date_components[0]}/${date_components[2]}`;
              }

            } else if (this.state.deliveryInCommonEdition.type === 2) {

              if (this.state.isConsolidateDeliveries) {
                this.state.deliveriesInCommon[this.state.deliveryInCommonEdition.index].delivery_date = `${date_components[1]}/${date_components[0]}/${date_components[2]}`;
              } else {
                this.state.deliveries[this.state.deliveryInCommonEdition.index].delivery_date = `${date_components[1]}/${date_components[0]}/${date_components[2]}`;
              }

            }

            this.setState({});            
          }
        }}
        showTimeSelect={false}
      />
    );
  }

  buildConsolidateDeliveriesContentHeaders = () => {
    return(
      <div className="consolidate-deliveries-content">

        <div className="title-deliveries-content">{'ENTREGAS DISPONIBLES'}</div>

        <div className="delivery-row">
          <div style={{width: 20}}/>
          <div className="delivery-item">{'Código de pedido de entrega'}</div>
          <div className="delivery-item" style={{flex: 1.5}}>{'Fecha estimada de recolección'}</div>
          <div className="delivery-item" style={{flex: 1.5}}>{'Fecha estimada de entrega'}</div>
          <div className="delivery-item">{'Peso bruto total'}</div>
          <div className="delivery-item">{'Volumen total'}</div>
          <div className="delivery-item">{'QTY SKU Únicos'}</div>
          <div className="delivery-item">{'Cantidad de items'}</div>
        </div>
      </div>
    );
  }

  buildConsolidateDeliveriesContent = () => {
    return(
      <div className="consolidate-deliveries-content">

        <div className="delivery-list">
          {
            this.props.isDeliveriesLoading ? <Loader circleColor={'black'} /> : (this.state.deliveriesInCommon || []).length === 0 ? 
            <div
              style={{
                height: '45%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
                fontSize: 18
              }}
            >{'No hay entregas con el mismo origen y destino al pedido'}</div> : 
            (this.state.deliveriesInCommon || []).map( (delivery, index) => {
              return(
                <div className="delivery-row with-hover" key={`delivery-row-${index}`}>
                  
                  <div 
                    className={`checkbox-container ${this.state.deliveriesToAdd.includes(delivery.id) ? 'selected' : ''}`}
                    onClick={() => {
                      if (this.state.deliveriesToAdd.includes(delivery.id)) {
                        this.setState({
                          deliveriesToAdd: this.state.deliveriesToAdd.filter( item => item !== delivery.id)
                        })
                      } else {
                        this.state.deliveriesToAdd.push(delivery.id);
                        this.setState({});
                      }
                    }}
                  >
                    <div className="checkbox"></div>
                  </div>
 
                  <div className="delivery-item">{`${delivery.requisition_code} ${delivery.suffix}`}</div>
                  <div className="delivery-item" style={{flex: 1.5}}>
                    { delivery.collection_date ? `${delivery.collection_date}` : '-'}
                    <img
                      alt=""
                      src="/images/edit.png"
                      style={{ cursor: "pointer", width: 18, height: 18, marginLeft: 10 }}
                      onClick={() => {
                        if (this.state.deliveryInCommonEdition === null) {
                          this.setState({deliveryInCommonEdition: {index, type: 1, date_data: {date: delivery.collection_date, time: null}}});
                        } else {
                          if (this.state.deliveryInCommonEdition.index === index && this.state.deliveryInCommonEdition.type === 1) {
                            this.setState({deliveryInCommonEdition: null});
                          } else {
                            this.setState({deliveryInCommonEdition: {index, type: 1, date_data: {date: delivery.collection_date, time: null}}});
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="delivery-item" style={{flex: 1.5}}>
                    {delivery.delivery_date ? `${delivery.delivery_date}` : '-'}
                    <img
                      alt=""
                      src="/images/edit.png"
                      style={{ cursor: "pointer", width: 18, height: 18, marginLeft: 10 }}
                      onClick={() => {
                        if (this.state.deliveryInCommonEdition === null) {
                          this.setState({deliveryInCommonEdition: {index, type: 2, date_data: {date: delivery.delivery_date, time: null}}});
                        } else {
                          if (this.state.deliveryInCommonEdition.index === index && this.state.deliveryInCommonEdition.type === 2) {
                            this.setState({deliveryInCommonEdition: null});
                          } else {
                            this.setState({deliveryInCommonEdition: {index, type: 2, date_data: {date: delivery.delivery_date, time: null}}});
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="delivery-item">{calculateGrossWeightProducts(delivery.products)}</div>
                  <div className="delivery-item">{calculateVolumeProducts(delivery.products)}</div>
                  <div className="delivery-item">{`-`}</div>
                  <div className="delivery-item">{`${delivery.products.length}`}</div>
                </div>
              );
            })
          }
        </div>

      </div>
    );
  }

  getGrossWeightInCommon = () => {
    let deliveries = this.state.deliveriesInCommon.filter( item => this.state.deliveriesToAdd.includes(item.id) )
    let products = [];

    deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return calculateGrossWeightProducts(products);
  }

  getVolumeInCommon = () => {
    let deliveries = this.state.deliveriesInCommon.filter( item => this.state.deliveriesToAdd.includes(item.id) )
    let products = [];

    deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return calculateVolumeProducts(products);
  }

  getQtyCommon = () => {
    let deliveries = this.state.deliveriesInCommon.filter( item => this.state.deliveriesToAdd.includes(item.id) )
    let products = [];

    deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return products.length;
  }

  buildDestinations = () => {
    return(
      <div className="destinations-container">
        <div className="destination-item">
          <div style={{marginBottom: 10}}>{`Origen: ${this.state.requisition?.from_facility?.name || ''}`}</div>
          <ImageWithCheckMark
            src={getUrlsEnv().files.concat(this.state.requisition?.from_facility?.company?.logo?.substring(1) || '')}
            width={80}
            isCheckMark={(this.state.requisition?.from_facility?.company?.verification_status || -1) === 3}
            customStyle={{
              width: 80, height: 80, borderRadius: '50%', position: 'relative', marginRight: 12
            }}
            isCo={true}
          />
        </div>

        <div className="destination-item">
          <div style={{marginBottom: 10}}>{`Destino: ${this.state.requisition?.to_facility?.name || ''}`}</div>
          <ImageWithCheckMark
            src={getUrlsEnv().files.concat(this.state.requisition?.to_facility?.company?.logo?.substring(1) || '')}
            width={80}
            isCheckMark={(this.state.requisition?.to_facility?.company?.verification_status || -1) === 3}
            customStyle={{
              width: 80, height: 80, borderRadius: '50%', position: 'relative', marginRight: 12
            }}
            isCo={true}
          />
        </div>

      </div>
    );
  }

  buildOrderInputs = () => {
    return(
      <div className="order-inputs-container">
        <div className="input-item">
          <TextInput
            label="Número de orden"
            name="selectedCode"
            value={this.state.selectedCode}
            className="item"
            onChange={this.handleChange}
            isUserTyping={() => {
              if (this.state.codeDuplicated) {
                this.setState({codeDuplicated: false});
              }
            }}
          />
          {
            this.state.codeDuplicated &&
            <RecommendationError>
              {'El código ya está en uso'}
            </RecommendationError>
          }
        </div>
        <div className="input-item" style={{marginLeft: 10}}>
          
          <div style={{height: 34}}/>

          <div 
            className="text-container"
            onClick={() => {
              this.setState({
                deliveryInCommonEdition: { index: -1, type: 3, date_data: this.state.selectedDate}
              })
            }}
          >

            <div className="text-value">
              { this.state.selectedDate ? `${this.state.selectedDate.date} ${this.state.selectedDate.time}` : 'Cita de carga' }
            </div>
            <div className="suffix-container"> 
              <SVGIcon name={icons.calendar} fill="#3D77F7" />
            </div>

          </div>

          <div style={{height: 20}}/>

          <div 
            className="text-container"
            onClick={() => {
              this.setState({
                deliveryInCommonEdition: { index: -1, type: 4, date_data: this.state.selectedUnloadDate}
              })
            }}
          >
            <div className="text-value">
              { this.state.selectedUnloadDate ? `${this.state.selectedUnloadDate.date} ${this.state.selectedUnloadDate.time}` : 'Cita de descarga' }
            </div>
            <div className="suffix-container"> 
              <SVGIcon name={icons.calendar} fill="#3D77F7" />
            </div>

          </div>

          <div style={{height: 10}}/>

        </div>

      </div>
    );
  }

  buildOrderAttributesInputs = () => {
    return(
      <div className="order-inputs-container">
        <div className="input-item">
          <TextInput
            label="Tipo"
            name="type"
            value={this.state.type}
            className="item"
            onChange={this.handleChange}
          />
        </div>
        <div className="input-item" style={{marginLeft: 10}}>
          <TextInput
            label="Remolques"
            name="trailers"
            value={this.state.trailers}
            className="item"
            onChange={this.handleChange}
          />
        </div>
        <div className="input-item" style={{marginLeft: 10}}>
          <TextInput
            label="Clase de expedición"
            name="class"
            value={this.state.class}
            className="item"
            onChange={this.handleChange}
          />
        </div>
        <div className="input-item" style={{marginLeft: 10}}>
          <TextInput
            label="Nivel de criticidad"
            name="level"
            value={this.state.level}
            className="item"
            onChange={this.handleChange}
          />
        </div>
        <div className="input-tags">
          <div className="tag-title">{'Selecciona las etiquetas de tu orden (opcional)'}</div>
          {(this.props.tagsCatalog || []).map((tag) => {
            return (
              <Tag
                key={tag.id}
                id={tag.id}
                color={tag.color}
                title={tag.title}
                type={tag.type}
                isFull={this.state.selectedTags.includes(tag.id)}
                onClick={() => {
                  if (this.state.selectedTags.includes(tag.id)) {
                    this.state.selectedTags = this.state.selectedTags.filter( item => item !== tag.id)
                  } else {
                    this.state.selectedTags.push(tag.id);
                  }
                  this.setState({});
                }}
                parentBackground="light"
              />
            );
          })}
        </div>
      </div>
    );
  }

  buildDeliveries = () => {

    return(
      <div className="deliveries-content">
        <div className="title-deliveries-content">{'PEDIDOS CONSOLIDADOS'}</div>

        <div className="delivery-row">
          <div className="delivery-item">{'Código de pedido de entrega'}</div>
          <div className="delivery-item" style={{flex: 1.5}}>{'Fecha estimada de recolección'}</div>
          <div className="delivery-item" style={{flex: 1.5}}>{'Fecha estimada de entrega'}</div>
          <div className="delivery-item">{'Peso bruto total'}</div>
          <div className="delivery-item">{'Volumen total'}</div>
          <div className="delivery-item">{'QTY SKU Únicos'}</div>
          <div className="delivery-item">{'Cantidad de items'}</div>
          <div style={{width: 20}}/>
        </div>

        <div className="delivery-list">
          {
            (this.state.deliveries || []).map( (delivery, index) => {
              return(
                <div className="delivery-row with-hover" key={`delivery-row-${index}`}>
                  <div className="delivery-item">{`${delivery.requisition_code} ${delivery.suffix}`}</div>
                  <div className="delivery-item" style={{flex: 1.5}}>
                    {delivery.collection_date ? `${delivery.collection_date}` : '-'}

                    <img
                      alt=""
                      src="/images/edit.png"
                      style={{ cursor: "pointer", width: 18, height: 18, marginLeft: 10 }}
                      onClick={() => {
                        if (this.state.deliveryInCommonEdition === null) {
                          this.setState({deliveryInCommonEdition: {index, type: 1, date_data: {date: delivery.collection_date, time: null} }});
                        } else {
                          if (this.state.deliveryInCommonEdition.index === index && this.state.deliveryInCommonEdition.type === 1) {
                            this.setState({deliveryInCommonEdition: null});
                          } else {
                            this.setState({deliveryInCommonEdition: {index, type: 1, date_data: {date: delivery.collection_date, time: null}}});
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="delivery-item" style={{flex: 1.5}}>
                    {delivery.delivery_date ? `${delivery.delivery_date}` : ''}

                    <img
                      alt=""
                      src="/images/edit.png"
                      style={{ cursor: "pointer", width: 18, height: 18, marginLeft: 10 }}
                      onClick={() => {
                        if (this.state.deliveryInCommonEdition === null) {
                          this.setState({deliveryInCommonEdition: {index, type: 2, date_data: {date: delivery.delivery_date, time: null} }});
                        } else {
                          if (this.state.deliveryInCommonEdition.index === index && this.state.deliveryInCommonEdition.type === 2) {
                            this.setState({deliveryInCommonEdition: null});
                          } else {
                            this.setState({deliveryInCommonEdition: {index, type: 2, date_data: {date: delivery.delivery_date, time: null}}});
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="delivery-item">{calculateGrossWeightProducts(delivery.products)}</div>
                  <div className="delivery-item">{calculateVolumeProducts(delivery.products)}</div>
                  <div className="delivery-item">{`-`}</div>
                  <div className="delivery-item">{`${delivery.products.length}`}</div>
                  <div style={{width: 20}}>
                    <img
                      alt=""
                      src="/images/bin.png"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          deliveries: this.state.deliveries.filter( item => item.id !== delivery.id)
                        })
                      }}
                    />
                  </div>
                </div>
              );
            })
          }
        </div>

        <div className="delivery-row" style={{borderBottom: '0px solid white', paddingTop: 25, paddingBottom: 25}}>
          <div className="delivery-item">{`Total: ${this.state.deliveries.length}`}</div>
          <div className="delivery-item" style={{flex: 1.5}}></div>
          <div className="delivery-item" style={{flex: 1.5}}></div>
          <div className="delivery-item">{this.getTotalGrossWeight()}</div>
          <div className="delivery-item">{this.getTotalVolume()}</div>
          <div className="delivery-item">{'-'}</div>
          <div className="delivery-item">{this.getTotalQty()}</div>
          <div style={{width: 20}}/>
        </div>

      </div>
    )
  }

  getTotalGrossWeight = () => {
    let products = [];

    this.state.deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return calculateGrossWeightProducts(products);
  }

  getTotalVolume = () => {
    let products = [];

    this.state.deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return calculateVolumeProducts(products);
  }

  getTotalQty = () => {
    let products = [];

    this.state.deliveries.map( delivery => {
      products = products.concat(delivery.products);
    })

    return products.length;
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  onClose = () => {
    if (this.props.isUpdatingLoading) return;
    if (this.props.closeAction) this.props.closeAction();
  };

  buildActions = () => {
    if (this.state.hasPendingChanges || this.state.hasDeliveriesToSave || this.state.messageSuccess) { return ''; }

    return(
      <div className="actions">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {
            this.state.isConsolidateDeliveries ? <div/> :
            <div 
              style={{color: '#3d77f7', cursor: 'pointer', display: 'flex', alignItems: 'center'}}
              onClick={() => {
                this.setState({isConsolidateDeliveries: true, deliveriesInCommon: [], deliveryInCommonEdition: null});
                this.props.loadDeliveries({
                  company_id: this.props.companyId,
                  from_facility: this.state.requisition?.from_facility?.id || 0,
                  to_facility: this.state.requisition?.to_facility?.id || 0,
                  isMultipleCancels: true
                },{
                  onSuccess: (response) => {

                    let deliveries_id = this.state.deliveries.map(item => item.id);
                    let deliveries_in_common = ('deliveries' in response ? response['deliveries'] : []).filter( item => !deliveries_id.includes(item.id) );

                    this.setState({
                      deliveriesInCommon: deliveries_in_common
                    });

                  },
                  onError: (e) => {
                    this.setState({
                      errorMessage: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                    });
                  }
                })
              }}
            >
              <div className="add-icon">+</div>
              <div>
                {'Añadir entregas'}
              </div>
            </div>
          }

          <div style={{display: 'flex'}}>
            <Button
              disabled={this.props.isUpdatingLoading}
              text={'Cancelar'}
              type={'btn secondary primary'}
              onClick={() => {
                this.props.cancelMultipleRequests();
                if (this.state.isConsolidateDeliveries) {
                  this.setState({
                    errorMessage: null,
                    isConsolidateDeliveries: false,
                    deliveryInCommonEdition: null
                  });
                  return;
                }
                this.onClose();
              }}
            />

            <div style={{width: 20}}/>

            <Button
              disabled={this.props.isUpdatingLoading || ( this.state.isConsolidateDeliveries ? this.state.deliveriesToAdd.length === 0 : true) }
              text={this.props.isUpdatingLoading ? <Loader circleColor={'black'}/> : this.state.isConsolidateDeliveries ? 'Consolidar entregas' : 'Convertir en orden' }
              type={'btn outline primary dark'}
              onClick={() => {

                if (this.state.isConsolidateDeliveries) {

                  let new_deliveries = this.state.deliveriesInCommon.filter( item => this.state.deliveriesToAdd.includes(item.id) );
                  this.state.deliveries = this.state.deliveries.concat(new_deliveries);

                  this.setState({
                    isConsolidateDeliveries: false,
                    deliveryInCommonEdition: null,
                    deliveriesToAdd: []
                  });

                } else {


                }

              }}
            />
          </div>

        </div>
      </div>
    );
  }

  handleDataChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'change_date') {

      if (this.state.deliveryInCommonEdition.type === 1) {

        if (this.state.isConsolidateDeliveries) {
          this.state.deliveriesInCommon[this.state.deliveryInCommonEdition.index].collection_date = value.date;
        } else {
          this.state.deliveries[this.state.deliveryInCommonEdition.index].collection_date = value.date;
        }

      } else if (this.state.deliveryInCommonEdition.type === 2) {

        if (this.state.isConsolidateDeliveries) {
          this.state.deliveriesInCommon[this.state.deliveryInCommonEdition.index].delivery_date = value.date;
        } else {
          this.state.deliveries[this.state.deliveryInCommonEdition.index].delivery_date = value.date;
        }

      } else if (this.state.deliveryInCommonEdition.type === 3) {

        this.state.selectedDate = value;

      } else if (this.state.deliveryInCommonEdition.type === 4) {

        this.state.selectedUnloadDate = value;

      }

      this.setState({deliveryInCommonEdition: null});            
      
    }

  }

  transformToDate = (data) => {
    let date_string = data.date || null;
    if (date_string) {
      let components = date_string.split('/');
      if (date_string.length > 2) {
        return(
          new Date(
            components[2],
            components[1] - 1,
            components[0]
          )
        );
      }
    }

    return null;
  }

  transformToTime = (data) => {
    let time_string = data.time || null;
    if (time_string) {
      let components = time_string.split(':');
      return(parseInt(`${components[0]}${components[1]}`));
    }

    return null;
  }

  render() {
    return (
      <Modal
        isOpen={this.props.requisition !== null}
        portalClassName={`dialog convert-delivery-to-order-dialog ${
          this.props.addClass ? this.props.addClass : ""
        }`}
        overlayClassName="overlay"
        className="content"
      >
        <div className="close" onClick={() => {
          this.onClose();
        }}></div>

        { this.buildTitle() }

        { this.state.isConsolidateDeliveries && this.buildConsolidateDeliveriesContentHeaders()}

        {
          this.state.isConsolidateDeliveries ? 
          <div style={{overflow: 'hidden', overflowY: 'auto', height: 'calc(100% - 273px)'}}>
            { this.buildConsolidateDeliveriesContent() }
          </div> :
          <div
            style={{overflow: 'hidden', overflowY: 'auto', height: 'calc(100% - 133px)', paddingRight: 20}}
          >
            { this.buildDestinations() }
            { this.buildOrderInputs() }
            { this.buildOrderAttributesInputs() }
            { this.buildDeliveries() }

            {
              this.state.messageSuccess &&
              <div style={{marginTop: 20, marginBottom: 30}}>
                <div>{'Se guardaron con éxito tu cambios'}</div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                  <Button
                    text={'Continuar'}
                    type={'btn primary'}
                    onClick={() => {this.onClose()}}
                  />
                </div>
              </div>
            }

            {
              this.state.errorMessage && <div style={{color: 'red', fontSize: 13}}>{this.state.errorMessage}</div>
            }

          </div>
        }

        { 
          this.state.isConsolidateDeliveries &&
          <div className="consolidate-deliveries-content">
            <div className="delivery-row" style={{borderBottom: '0px solid white'}}>
            <div className="delivery-item">{`Total ${this.state.deliveriesToAdd.length}`}</div>
            <div style={{width: 20}}/>
            <div className="delivery-item" style={{flex: 1.5}}>{''}</div>
            <div className="delivery-item" style={{flex: 1.5}}>{''}</div>
            <div className="delivery-item">{this.getGrossWeightInCommon()}</div>
            <div className="delivery-item">{this.getVolumeInCommon()}</div>
            <div className="delivery-item">{'-'}</div>
            <div className="delivery-item">{this.getQtyCommon()}</div>
            </div>
          </div>
        }

        { this.buildActions() }

        {
          this.state.deliveryInCommonEdition &&
          <>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              onClick={() => this.setState({deliveryInCommonEdition: null})}
            />
            <CustomDatePicker
              withTime={this.state.deliveryInCommonEdition.index === -1}
              minDate={new Date()}
              dateSelected={this.transformToDate(this.state.deliveryInCommonEdition.date_data || {})}
              timeSelected={this.state.deliveryInCommonEdition.index === -1 ? this.transformToTime(this.state.deliveryInCommonEdition.date_data || {}) : null}
              handleChange={this.handleDataChange}
              onClose={() => {
                this.setState({deliveryInCommonEdition: null})
              }}
            />
          </>
        }

      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const isDeliveriesLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.DELIVERIESLIST']?.status?.isFetching || false;

  return {
    companyId,
    isDeliveriesLoading
  };
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());

  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    loadDeliveries: (params, opt) => dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.DELIVERIESLIST", params, opt))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConvertDeliveryToOrderModal);