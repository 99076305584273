import React from "react";
import LoginDialog from "../shared/dialog/login-modal";
import RegisterDialog from "../shared/dialog/register-modal";
import ResetNewPasswordDialog from "../shared/dialog/reset-new-password-modal";
import ResetPasswordMessageDialog from "../shared/dialog/reset-password-message-modal";
import RecoverPasswordDialog from "../shared/dialog/recover-password-modal";
import GenericDialog from "../shared/dialog/generic-modal";
import ErrorDialog from "../shared/dialog/error-dialog";
import LoginErrorDialog from "../shared/dialog/login-error-modal";
// import { IcoIsoOrbiColor } from "./components/IcoIsoOrbiColor";
// import { IconHistorial } from "./components/IconHistorial";
// import { IconFcl1 } from "./components/IconFcl1";
import { BACKEND, getApiUrlWithoutAuthorization } from "../shared/backend-api";
import { tranformSpecialCharacterUrl } from "../shared/utils";
import "../styles/landingStyle.css"
import LoginDetailModal from "../shared/dialog/login-detail-modal";
import { Link } from "react-router-dom";
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.pricingRef = React.createRef();
    this.mainPage = React.createRef();

    this.state = {
      showLoginModal: false,
      showRegisterModal: false,
      showRecoverPasswordModal: false,
      showRecoverMessageModal: false,
      showRegisterMessageModal: false,
      showResetNewPasswordModal: false,
      showResetNewPasswordMessage: false,
      showSuccessValidation: false,
      showSuccessInvitation: false,
      showRegisterInvitationMessageModal: false,
      showInvitationModal: false,
      showSessionError: false,
      emailInvitation: this.getEmailInvitation(props),
      hasInvitation: false,
      errorMessage: "",
      emailRegister: "",

      currentSlide: 0,

      mode: 1,
      pricingMode: 1,
      showViewDetail: false,
      detailTitle: '',
      detailMessage: '',
      detailImg: '',
      showMobileMenu: false,
      benefitsSlides: 3,
      brandsSlides: 3,
      screenWidth: 0,
      isMobileDevice: false,
      scrollBrandsCarousel: false
    };
  }



  handleResize = () => {
    const screenWidth = window.innerWidth;
    this.setState({ screenWidth });

    if (screenWidth <= 425) {
      this.setState({ benefitsSlides: 1, brandsSlides: 1   });
    } else if (screenWidth <= 600) {
      this.setState({ benefitsSlides: 1, brandsSlides: 2  });
    } else if (screenWidth <= 704) {
      this.setState({ benefitsSlides: 1, brandsSlides: 2  });
    } else if (screenWidth <= 768) {
      this.setState({ benefitsSlides: 2, brandsSlides: 3 });
    } else if (screenWidth <= 1024) {
      this.setState({ benefitsSlides: 2, brandsSlides: 4 });
    } else if (screenWidth <= 1056) {
      this.setState({ benefitsSlides: 2, brandsSlides: 4 });
    } else if (screenWidth <= 1408) {
      this.setState({ benefitsSlides: 3, brandsSlides: 4 });
    } else if (screenWidth <= 1440) {
      this.setState({ benefitsSlides: 3, brandsSlides: 5 });
    } else if (screenWidth <= 1761) {
      this.setState({ benefitsSlides: 4, brandsSlides: 5 });
    } else if (screenWidth <= 2113) {
      this.setState({ benefitsSlides: 5, brandsSlides: 5 });
    } else {
      this.setState({ benefitsSlides: 6, brandsSlides: 5  });
    }
  }
  
  detectMobile = () => {
    let navegador = navigator.userAgent;
    if (navegador.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
        this.setState({ isMobileDevice: true });
    } else {
        this.setState({ isMobileDevice: false });
    }
  }

  componentDidMount() {
    let self = this;

    const {
      location: { search = "" },
    } = this.props;

    this.handleResize();

    this.detectMobile();

    window.addEventListener("resize", this.handleResize);

    var observer = new IntersectionObserver(function(entries) {
      if(entries[0].isIntersecting === true){
        self.setState({scrollBrandsCarousel: true});
      } else {
        self.setState({scrollBrandsCarousel: false});
      }
    }, { threshold: [1] });
    
    observer.observe(document.querySelector("#brandsCarousel"));

    if (search !== "" && search.length > 1) {
      if (search.includes("valid_email")) {
        const result = search.split("=");

        if (result.length > 1 && String(result[1]) === "success") {
          this.setState({
            showSuccessValidation: true,
          });
        } else if (result.length > 1 && String(result[1]) === "info") {
          this.setState({
            errorMessage: "Tu cuenta ya fue validada previamente",
          });
        } else {
          this.setState({
            errorMessage:
              "Ocurrió un error al validar su email, intentalo más tarde",
          });
        }
      } else if (search.includes("invitation")) {
        const result = search.split("=");

        if (result.length > 1 && String(result[1]) === "success") {
          this.setState({
            showSuccessInvitation: true,
          });
        } else if (result.length > 1 && String(result[1]) === "error") {
          this.setState({
            errorMessage:
              "Ocurrió un error al aceptar la invitación, intentalo más tarde",
          });
        } else {
          this.setState({
            errorMessage: decodeURI(String(result[1])),
          });
        }
      } else if (search.includes("email")) {
        const result = search.split("=");

        if (result.length > 1) {
          let email = result[1];
          getApiUrlWithoutAuthorization(BACKEND.USERS.SIGN_UP.CHECK_EMAIL, {
            email: tranformSpecialCharacterUrl(email)
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.response === "ok") {
                this.setState(
                  {
                    hasInvitation: true,
                    showRegisterModal: true,
                  },
                  () => this.setState({ emailInvitation: String(result[1]) })
                );
              }
            })
            .catch((error) =>
              error.then((e) => {
                if (e.response === "duplicated") {
                  this.setState({
                    showInvitationModal: true,
                  });
                } else {
                  this.setState({
                    errorMessage:
                      "Ocurrió un error al validar su email, intentalo más tarde",
                  });
                }
              })
            );
        } else {
          this.setState({
            errorMessage:
              "Ocurrió un error al validar su email, intentalo más tarde",
          });
        }
      } else if (search.includes("register")){

        const result = search.split("user=");

        if (result.length > 1) {

          let email = result[1];
          getApiUrlWithoutAuthorization(BACKEND.USERS.SIGN_UP.CHECK_EMAIL, {
            email: tranformSpecialCharacterUrl(email)
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.response === "ok") {
                this.setState(
                  {
                    hasInvitation: true,
                    showRegisterModal: true
                  },
                  () => this.setState({ emailRegister: String(result[1]) })
                );
              }
            })
            .catch((error) =>
              error.then((e) => {
                if (e.response === "duplicated") {
                  this.setState({
                    showInvitationModal: true,
                  });
                } else {
                  this.setState({
                    errorMessage:
                      "Ocurrió un error al validar su email, intentalo más tarde",
                  });
                }
              })
            );
        } else {
          this.setState({
            hasInvitation: true,
            showRegisterModal: true
          });
        }
      } else if (search.includes("expiration")) {
        this.setState({
          showSessionError: true,
        });
      } else if (search.includes("view")){
        if(search.includes("co")){
          this.setState({mode: 2});
        } else if(search.includes("lt")){
          this.setState({mode: 3});
        }
      } else {
        const token = search.slice(3);

        this.setState({
          showResetNewPasswordModal: true,
          token,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getEmailInvitation = (props) => {
    const {
      location: { search = "" },
    } = props;

    if (search !== "" && search.length > 1 && search.includes("email")) {
      const result = search.split("=");

      if (result.length > 1) {
        return String(result[1]);
      } else {
        return "";
      }
    }

    return "";
  };

  handleEnd = () => {
    const { currentSlide } = this.slider.context.state;
    this.setState({ currentSlide });
  };

  handleModeChange = (e) => {
    this.setState({ mode: e });
  };

  closeModals = () =>
    this.setState({
      showLoginModal: false,
      showRegisterModal: false,
      showRecoverPasswordModal: false,
      showRecoverMessageModal: false,
      showRegisterMessageModal: false,
      errorMessage: "",
      showResetNewPasswordModal: false,
      showResetNewPasswordMessage: false,
      showSuccessValidation: false,
      showSuccessInvitation: false,
      showRegisterInvitationMessageModal: false,
      showSessionError: false,
    });

  forgotModal = (value) => {
    this.setState({
      showLoginModal: false,
      showRecoverPasswordModal: value,
    });
  };

  changeToLogin = () => {
    this.setState({
      showRegisterModal: false,
      showLoginModal: true,
    });
  };

  toggleMobileMenu = () => {
    this.setState({
      showMobileMenu: !this.state.showMobileMenu
    });
  };

  getBrands = () => {
    // const logos = [
    //   "https://www.logo.wine/a/logo/Puma_(brand)/Puma_(brand)-Logo.wine.svg",
    //   "https://www.hubspot.com/hs-fs/hubfs/Shell_logo.svg.png?width=450&height=417&name=Shell_logo.svg.png",
    //   "https://reallygooddesigns.com/wp-content/uploads/2022/12/Famous-Brand-Logos-LEGO.jpg",
    //   "https://res.cloudinary.com/vistaprint/images/c_scale,w_448,h_448,dpr_2/f_auto,q_auto/v1706191816/ideas-and-advice-prod/blogadmin/Screenshot-2024-01-25-at-15.09.28/Screenshot-2024-01-25-at-15.09.28.png?_i=AA",
    //   "https://www.designrush.com/uploads/users/customer-2/image_1505931862_e2896044118462ae451d87076f16ce6d.jpeg",
    //   "https://reallygooddesigns.com/wp-content/uploads/2022/12/Famous-Brand-Logos-Playboy.jpg",
    //   "https://www.hubspot.com/hs-fs/hubfs/Logo_NIKE.svg.png?width=550&height=196&name=Logo_NIKE.svg.png",
    //   "https://graphicdesignblog.org/images/gdb-6/famous-single-letter-logo-1.jpg",
    //   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvtWfBLaS_ARrJ5cHWKI87kp94IoGunvXkXA&s"
    // ]

    const logos = [
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/GrupoModelo_MX.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ALDAFAMX.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/AtPilot.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Zemog.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Transportes_Baez.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TMC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MURILLO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CODA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ABC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/KUGAR.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Trans_EduardoA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/COSTA_VERDE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Lym_Logistics.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ACUNA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSHER.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/jlpm0566.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Mexamerik.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Maverick.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Kargofer.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ARLEQUIN.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SINTRA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Garcias_Trucking.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ESMACADA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Torta.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ARCACS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPOVER_SA_DE_CV.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TML.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTES_DEGAM.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/VEAR.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TLDC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CASTRO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Perla.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Veta2020.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ALCA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CST.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Trebol.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/atb.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TICARSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/KLS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/AGUILERA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRAILMEXA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/traficovrv.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PEX_LOGISTICA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/LOGICSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/segovias.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/VILCHIS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SEVA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CarMavi.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTES_ACOSTA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/RAEL.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/LitransporteSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TITSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Mi_oste.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/BAVARIA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/servicioaclientetle.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTES_D_LOPEZ.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/T77.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/NAO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/UCP_Backus.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CN_Ecuador.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/HERLOPTRUCKS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SERVITRAILER.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Albantruks.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/HGMASOCIADOS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TCI.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/LTM_TRANSPORTES.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Transer.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRVARGAS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TEV.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRUKCEPEDA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/INCURRETRANS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/alliance.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CONSULTING_DINADEC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSCORALV.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/GRUPO_TICAL_.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Mi_Beatriz.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Vigsan.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Cumbaza.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Abirafael.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/23_julio.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/AYACHO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/AYKA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CARPIO_MIRAMENDE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTES_CHINO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/DATROX.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/FERNANDEZ_LLERENA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/EMILIO_LLERENA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/FRANSVIC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/INTEROCEANICO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/JOSUE_LIZ.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/KUSI.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/VALENCIA_CORDOVA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MARIA_QUISPE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PANCORBO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/QUISPE_BACA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SEGOVIA_TORRES.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SMIT.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MADERA_STA_ANA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANS_INTI.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TUPAYACHI.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/VALIENTE_VALENTIN.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/VARGAS_ACOSTA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTES_WILLIAM.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/YUCRA_RAMOS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ANDINA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/DIREBA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/J_M.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/LM_y_G.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SALDANA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSNOR.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTA2.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/GRUPO_ALCANTARA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ALFA-OMEGA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/AQTRAN.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CARPIO_VARGAS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ESTRATEGICO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/FANY_PALOMINO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/HECARO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MACONSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PALACIOS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/RUTTA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PIELAGO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SIFUENTES.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSBLANCO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSLACOSTA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/84Trainsa.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/FLETES2HERMANOS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/AUTO_TRANSPORTESLA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/LOGISTIC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TELFRA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/GRUPO_D_E_H_.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/RYM.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/JUTERZA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ATLANTIC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MARISA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRASMISA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Level_One.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/IMEX_CP.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/RO-K.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/JP_LOGISTICA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/HPL.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CARVI.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/LEON_ORO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/DUANEE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TyS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/DAXI.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/NARCEA_ENLACES.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ASJ_MERIDA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PORTE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PEXLOG.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Robinson.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/FRETHUNTERS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MENDIETA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PROPANE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANCESA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MYM.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CMR.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ATG.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/RIO_BIAVO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CENTRAL_PERU.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPESA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/HELU.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MYA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CRIBZAM.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/DEMALE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/DMV.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/RUDE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/BACA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/NORMAT.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PACIFICO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PUCHOC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MIGUELITO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/STARK.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TACSA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TORUS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSNET.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/GAMANIEL.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/JMA_CARGO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/VALDIVIESO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANS_SE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CAHUANA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CRISMA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CANDELARIA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/INMACOP.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MC_CARGO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MCTRANSPOR.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MOTTA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ANDRESUR.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MYC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/HODA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SUR_MERCANTILES_3RO_264466.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/HKS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANEX.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ESCOBEDO_ANDRES.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/GARBE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/REDISBA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Transportes_Garcia.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSCOBA_.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Transportes_AFA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/D_Y_C_LOGISTIC_IMPORT.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TransMartz.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TTELOGISTICA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/CARISA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/BRACHO.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TOTOS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Kemblin.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/10113236.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANS_GARCIAS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/ETARFIL.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SANTISA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MAPH.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MVP_TRANSPORT_SA_DE_CV.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/PEREDA.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/DYR.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/VE.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/SANTISA_GROUP.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/LOPEZ.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/T_RAMOS.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/MHZ.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TECOVE_LOGISTIC.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/TRANSPORTES_TLR.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/Elipse.jpg",
      "https://orbiback.orbinetwork.com/api/v2/bridge/resource/private?path=images/company/pictures/chino_CO.jpg"
    ]

    const logos2 = [];
    let result = [];
    let logoI = 0;
    for(let i = 1; i <= logos.length; i++) {
      result.push({
        id: i, 
        url: logos[logoI++]
      });
      if(logoI === logos.length) {
        logoI = 0;
      }
    }
    return result
  }

  render() {
    return (
    <div className="landingpage" ref={this.mainPage}>
      <div className={"group"+(this.state.mode === 1 ? '' : ' white')+(this.state.mode !== 3 ? '' : ' TL')}>
        <div className="overlap">
          {
            this.state.mode === 1 ? (
              <div className="frame">
                <div className="div">
                  <img
                    className="subtract"
                    alt="Subtract"
                    src="/images/img/subtract.svg"
                  />
    
                  <img
                    className="mask-group"
                    alt="Mask group"
                    src="/images/img/mask-group.png"
                  />
                </div>
              </div>
            ) : (
              <div className="frame-0">
              </div>
            )
          }

          <div className="group-2">

            <div className="group-3">
            <div className={`frame-4${ [2,3].includes(this.state.mode) ? ' background' : '' }`}>
                <img
                  className="ico-logo-orbi-color"
                  alt="Ico logo orbi color"
                  src={`/images/img/${this.state.mode === 1 ? 'ico-logo-orbi-color' : 'logo-orbi-blanco'}.svg`}
                  onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(1)}}
                />
                <img
                  className="ico-logo-orbi-color-mobile"
                  alt="Ico logo orbi color"
                  src={`/images/img/${this.state.mode === 1 ? 'ico-logo-orbi-color-mobile' : 'ico-logo-orbi-blanco-mobile'}.svg`}
                  onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(1)}}
                />


                <div className="frame-5">
                  <div className={"text-wrapper-3" } onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(1)}}>Inicio</div>

                  <div className={"text-wrapper-3" + (this.state.mode === 2 ? ' selected' : '') } onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(2)}}>Dueños de Cargas</div>

                  <div className={"text-wrapper-3" + (this.state.mode === 3 ? ' selected' : '') } onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(3)}}>Transportistas</div>

                  <div className={"text-wrapper-3" } onClick={() => {
                    this.pricingRef.current.scrollIntoView();
                  }}>
                    Precios
                  </div>
                  <div className={"text-wrapper-3"}>
                    <Link to={`/survey`}>
                      Solicitar invitación
                    </Link>
                  </div>

                  <div className="crea-tu-cuenta-wrapper">
                    <div className="crea-tu-cuenta-2">
                      <button 
                        className="crea-tu-cuenta-2-btn"
                        // style={{border: "0px", backgroundColor: "unset", height: "59px", width: "243px"}}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({
                            showLoginModal: true
                          });
                        }}
                      >
                        Iniciar sesión
                      </button>
                    </div>
                  </div>

                  <div
                    className="frame-5-mobile-title"
                  >{this.state.mode === 2 ? 'Dueños de carga' : this.state.mode === 3 ? 'Transportistas' : ''}</div>

                  <div className="frame-5-mobile">
                    <img
                      className="ico-mobile-menu"
                      alt="Ico logo orbi color"
                      src={`/images/img/mobile-menu-icon${this.state.mode !== 1 ? '-white' : ''}.svg`}
                      onClick={() => {this.toggleMobileMenu()}}
                    />
                    <div className="mobile-menu" style={{display: this.state.showMobileMenu ? 'block' : 'none'}}>
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: 'blue',
                          zIndex: -1
                        }}
                      />

                      <div className="mobile-menu-item" onClick={() => { this.mainPage.current.scrollIntoView(); this.setState({mode: 1, showMobileMenu: false})}}>Inicio</div>
                      <div className="mobile-menu-item" onClick={() => { this.mainPage.current.scrollIntoView(); this.setState({mode: 2, showMobileMenu: false})}}>Dueños de Cargas</div>
                      <div className="mobile-menu-item" onClick={() => { this.mainPage.current.scrollIntoView(); this.setState({mode: 3, showMobileMenu: false})}}>Transportistas</div>
                      <div className="mobile-menu-item" onClick={() => { this.pricingRef.current.scrollIntoView(); this.setState({showMobileMenu: false}) }}>Precios</div>
                      <div className="mobile-menu-item">
                        <Link to={`/survey`}>
                          Solicitar invitación
                        </Link>
                      </div>
                      <div className="mobile-menu-item" onClick={() => { this.mainPage.current.scrollIntoView(); this.setState({showLoginModal: true, showMobileMenu: false})}}>Iniciar Sesión</div>
                    </div>
                  </div>
                </div>

              </div>

              <div className={"frame-2 ".concat(this.state.mode === 1 ? 'base-page' : 'special-page')} >
                <p className="la-forma-m-s-r-pida">
                  <span className="text-wrapper">
                    {
                      this.state.mode === 1 ? (
                        'El único canal que conecta todas las cadenas de abastecimiento'
                      ) : this.state.mode === 2 ? (
                        <>La forma más rápida y simple de conectar a todos tus clientes, proveedores y transportistas en el mismo canal.</>
                      ) : <>La forma más rápida y simple de conectar con todos tus clientes en el mismo canal.</>
                    }
                  </span>
                </p>

                {
                  this.state.mode === 1 && (
                    <div className="text-wrapper-2">
                      Orbinetwork es la comunidad en la nube que conecta a <b>todas las empresas de todas las cadenas de abastecimiento.</b> ¿Aún no te han invitado? ¡No esperes más! Solicita tu invitación y conecta tu negocio a la red de Orbinetwork.
                    </div>
                  )
                }

                <div className="frame-3">
                  <div className="CTA-primary rectangle">
                    {/* <div className="rectangle" /> */}
                    <Link to={`/survey`}>
                      <button className="crea-tu-cuenta">Solicitar invitación</button>
                    </Link>
                  </div>
                </div>
              </div>

              
            </div>

            <div className="carousel-container">
              <CarouselProvider
                visibleSlides={1}
                totalSlides={2}
                step={1}
                // currentSlide={currentSlide}
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                isIntrinsicHeight={true}
                isPlaying={true}
                interval={4000}
              >
                <div className="slider-wrapper">
                  <Slider>
                    <Slide index={0} className="slide">
                      <img 
                        className="element-ordenes-linea" 
                        alt="Element ordenes linea" 
                        src="/images/img/2-ordenes-linea-transportista.png" 
                      />
                    </Slide>
                    <Slide index={1}>
                      <img 
                        className="element-ordenes-linea" 
                        alt="Element ordenes linea" 
                        src="/images/img/2-ordenes-linea-transportista.png" 
                      />
                    </Slide>
                  </Slider>
                  {/* <div className="controls">
                    <ButtonBack className="btn-arrow reverse-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonBack>
                    <DotGroup className="dot-group" />
                    <ButtonNext className="btn-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonNext>
                  </div> */}
                </div>
              </CarouselProvider>
            </div>
          </div>

          {
            this.state.mode === 1 && (
              <div className="frame-6">
                <p className="p">Empresas que confían en nosotros</p>
                <div className="carousel-container" id="brandsCarousel">
                  <CarouselProvider
                    visibleSlides={this.state.brandsSlides}
                    totalSlides={this.getBrands().length}
                    step={1}
                    // currentSlide={currentSlide}
                    naturalSlideWidth={100}
                    naturalSlideHeight={125}
                    isIntrinsicHeight={true}
                    isPlaying={this.state.scrollBrandsCarousel}
                    // lockOnWindowScroll={true}
                    interval={2000}
                  >
                    <div className="slider-wrapper">
                      <Slider>
                        {
                          this.getBrands().map((brand, index) => (
                            <Slide index={index} className="slide" key={index}>
                              <img className="group-4" alt="Group" src={brand.url} />
                            </Slide>

                          ))
                        }
                        {/* <Slide index={1}>
                          <img className="group-4" alt="Group" src="/images/img/group-330-2.svg" />
                        </Slide>
                        <Slide index={2}>
                          <img className="group-4" alt="Group" src="/images/img/group-330-3.svg" />
                        </Slide>
                        <Slide index={3}>
                          <img className="group-4" alt="Group" src="/images/img/group-330-4.svg" />
                        </Slide>
                        <Slide index={4}>
                          <img className="group-4" alt="Group" src="/images/img/group-330-5.svg" />
                        </Slide>
                        <Slide index={5}>
                          <img className="group-4" alt="Group" src="/images/img/group-330-6.svg" />
                        </Slide> */}
                      </Slider>
                      {/* <div className="controls">
                        <ButtonBack className="btn-arrow reverse-arrow">
                          <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                        </ButtonBack>
                        <DotGroup className="dot-group" />
                        <ButtonNext className="btn-arrow">
                          <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                        </ButtonNext>
                      </div> */}
                    </div>
                  </CarouselProvider>
                </div>
              </div>
            )
          }
        </div>
      </div>
      
      {
        this.state.mode === 1 && (
          <div className="tech-wrapper">
            <img
              className="img-2-s"
              alt="Icon calendar"
              src="/images/img/group-146-3.svg"
            />
            <div className="tech">
              <div className="title">
                <p className="uno-para-todos-y">
                  Un mismo canal para todos y todos para uno
                </p>
    
                <p className="text-wrapper-4">
                  Visualiza, optimiza y transforma tu operación con <b>una</b> misma <b>plataforma</b>, universal, ominicanal y omnidireccional <b>que beneficia a todas</b> las organizaciones de tu cadena de abastecimiento al compartir todos la misma herramienta.
                </p>
    
                <div className="frame-3">
                  <div className="CTA-primary rectangle">
                    <Link to={`/survey`}>
                      <button className="crea-tu-cuenta">Solicitar invitación</button>
                    </Link>
                  </div>
                </div>
    
                <div className="overlap-wrapper">
                  <img
                    className="img-2"
                    alt="Icon calendar"
                    src="/images/img/group-146-3.svg"
                  />
                </div>
              </div>
    
              <div className="row">
                <div className="frame-wrapper">
                  <div className="frame-7">
                    <div className="text-wrapper-5">Universal</div>
    
                    <p className="text-wrapper-6">
                      Un canal de comunicación único con interfaces simples que cualquiera puede usar sin ser expertos en tecnología, para que todas las áreas de la operación y toma de decisiones de tu negocio tengan la información que necesitan en tiempo real, desde <b>protección patrimonial, producción, almacén, planeación, compras, ventas, logística, distribución, torre de control, dirección</b>, etc. Además con el mismo canal pueden colaborar también todos tus clientes, proveedores y transportistas, sin importar el giro ni el tamaño de sus empresas. 
                    </p>
                  </div>
                </div>
    
                <div className="frame-wrapper">
                  <div className="frame-8">
                    <div className="text-wrapper-5">Omnicanal</div>
    
                    <p className="text-wrapper-6">
                      Integra a todas las <b>fábricas, almacenes, puntos de venta, transportistas</b> de tu <b>distribución primaria, secundaria, última milla, canal moderno, tradicional</b> o <b>digital</b> de tu empresa, de tus clientes y de tus proveedores. 
                    </p>
                  </div>
                </div>
    
                <div className="frame-wrapper">
                  <div className="frame-9">
                    <div className="text-wrapper-5">Omnidireccional</div>
    
                    <p className="text-wrapper-6">
                      Conéctate de forma <b>privada</b> con todos tus clientes, proveedores, transportistas con una misma red donde todos ellos  también puedan colaborar con su propia red de clientes proveedores y transportistas <b>sin complejidades</b> y <b>sin fragmentación</b>. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        this.state.mode === 1 && (
          <div className="frame-10">
            <div className="frame-11">
              <div className="text-wrapper-7">Integra toda tu operación en minutos</div>
    
              <p className="text-wrapper-8">
                A una comunidad privada que soporta cientos de empresas transportistas, miles de clientes y millones de pedidos y viajes.
              </p>
            </div>
    
            <div className="frame-12">
              <div className="frame-13">
                <div className="frame-14">
                  <p className="text-wrapper-9">
                    Reduce hasta un <b>50%</b> el tiempo de ciclo de viajes.
                  </p>
                </div>
              </div>
    
              <div className="frame-15">
                <div className="frame-14">
                  <p className="text-wrapper-9">
                    Incrementa hasta un <b>30%</b> la productividad de los camiones en tu operación.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {/* <div className="frame-16">
        <div className="frame-17">
          <div className="text-wrapper-10">Testimoniales</div>

          <div className="frame-18">
            <div className="group-6">
              <div className="overlap-group-2">
                <p className="text-wrapper-11">
                  Designers need to have a strong understanding of the
                  principles of design in order to create effective solutions.
                  They must also be aware of the latest trends and technologies
                  so that they can stay ahead of the curve.
                </p>

                <div className="overlap-group-3">
                  <div className="text-wrapper-12">Jane Doe</div>

                  <div className="text-wrapper-13">CEO of Inkyy.com</div>
                </div>

                <img className="group-7" alt="Group" src="/images/img/group-91.png" />

                <img className="line" alt="Line" src="/images/img/line-1.svg" />
              </div>

              <img
                className="rectangle-2"
                alt="Rectangle"
                src="/images/img/path-8.png"
              />
            </div>

            <div className="group-6">
              <div className="overlap-3">
                <p className="text-wrapper-11">
                  Designers need to have a strong understanding of the
                  principles of design in order to create effective solutions.
                  They must also be aware of the latest trends and technologies
                  so that they can stay ahead of the curve.
                </p>

                <div className="overlap-group-3">
                  <div className="text-wrapper-12">Jane Doe</div>

                  <div className="text-wrapper-13">CEO of Inkyy.com</div>
                </div>

                <img className="group-7" alt="Group" src="/images/img/group-91.png" />

                <img className="line-2" alt="Line" src="/images/img/line-1-1.svg" />

                <img
                  className="rectangle-3"
                  alt="Rectangle"
                  src="/images/img/path-8.png"
                />
              </div>
            </div>

            <div className="group-8">
              <div className="overlap-3">
                <p className="text-wrapper-11">
                  Designers need to have a strong understanding of the
                  principles of design in order to create effective solutions.
                  They must also be aware of the latest trends and technologies
                  so that they can stay ahead of the curve.
                </p>

                <div className="overlap-group-3">
                  <div className="text-wrapper-12">Jane Doe</div>

                  <div className="text-wrapper-13">CEO of Inkyy.com</div>
                </div>

                <img
                  className="group-9"
                  alt="Group"
                  src="/images/img/group-91-2.png"
                />

                <img className="line-3" alt="Line" src="/images/img/line-1-2.svg" />

                <img
                  className="rectangle-3"
                  alt="Rectangle"
                  src="/images/img/path-8.png"
                />
              </div>
            </div>
          </div>

          <div className="group-10">
            <div className="ellipse-6" />

            <div className="ellipse-7" />

            <div className="ellipse-8" />
          </div>
        </div>
      </div> */}

      {
        this.state.mode === 1 && (
          <div className="frame-19">
            <div className="frame-20">
              {/* <div className="text-wrapper-14">Nuestros datos</div> */}
    
              <div className="text-wrapper-15">Más de...</div>
            </div>
    
            <div className="frame-21">
              <div className="frame-22">
                <p className="element">
                  <span className="text-wrapper">3</span>
    
                  <span className="text-wrapper-16">M</span>
                </p>
    
                <div className="text-wrapper-17">De viajes monitoreados</div>
              </div>
    
              <div className="frame-22">
                <p className="element">
                  <span className="text-wrapper">$50</span>
    
                  <span className="text-wrapper-16">MM</span>
                </p>
    
                <p className="text-wrapper-17">
                  de pesos timbrados en cartas portes desde nuestra plataforma
                </p>
              </div>
    
              <div className="frame-22">
                <p className="element">
                  <span className="text-wrapper">4</span>
    
                  <span className="text-wrapper-16">K</span>
                </p>
    
                <p className="text-wrapper-17">
                  {" "}
                  operadores que utilizan nuestra app
                </p>
              </div>
            </div>
          </div>
        )
      }

      <div className="frame-23">

        {
          this.state.mode === 1 && <>
            <div className="frame-11">
              <div className="text-wrapper-7">Beneficios</div>
    
              <p className="text-wrapper-8">
                Siente el poder de la velocidad de la información colaborativa en tiempo real, la simplicidad en su máxima expresión y explota tu verdadero potencial:
              </p>
            </div>
            <div className="frame-24-0">
              {/* <p className="text-wrapper-8-1">Maximiza...</p> */}
              <div className="frame-24">
                <div className="frame-25">
                  <div className="frame-26">
                    <img className="vector" alt="Vector" src="/images/img/vector.svg" />
      
                    <div className="frame-27">
                      <p className="text-wrapper-18">
                        Maximiza la productividad de tus recursos y operación.
                      </p>
                    </div>
                  </div>
                </div>
      
                <div className="frame-25">
                  <div className="frame-26">
                    <img
                      className="pag-pregunta-b"
                      alt="Pag pregunta b"
                      src="/images/img/pag-11-pregunta-b-act.svg"
                    />
      
                    <div className="frame-27">
                      <p className="text-wrapper-18">
                        Maximiza el nivel de servicio y experiencia de tus clientes:
                        NPS
                      </p>
                    </div>
                  </div>
                </div>
      
                <div className="frame-25">
                  <div className="frame-26">
                    <img
                      className="pag-pregunta-a"
                      alt="Pag pregunta a"
                      src="/images/img/pag-11-pregunta-a-act.svg"
                    />
      
                    <div className="frame-27">
                      <p className="text-wrapper-18">
                        Maximiza la rentabilidad de tu negocio: EBITDA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        <div className="frame-28">
          {
            this.state.mode === 2 && (
              <div className="text-wrapper-19-1">
                Beneficios a tu operación
              </div>
            )
          }

          <div className="text-wrapper-19">
            {
              this.state.mode === 1 ? (
                '¿Cómo funciona Orbinetwork?'
              ) : this.state.mode === 2 ? (
                'Transformación digital integral'
              ) : 'Beneficios a tu operación'
            }
          </div>

          {
            this.state.mode === 1 && (
              <p className="text-wrapper-19-0">Únete solo por invitación.</p>
            )
          }

          {
            this.state.mode === 2 && (
              <div className="text-wrapper-19-2">
                Nuestra plataforma ofrece soluciones integrales para la cadena de suministro, mejorando la eficiencia y la colaboración. 
                Con Orbi Network, transforma tus operaciones logísticas en un proceso ágil y efectivo.
              </div>
            )
          }

          <div className="frame-29">
            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img
                    className="frame-32"
                    alt="Frame"
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-1.svg" ) : this.state.mode === 2 ? (
                        "/images/img/cargo-owner-1.svg"
                      ) : "/images/img/cargo-owner-2.svg"
                    }
                  />
                </div>

                <div className="frame-33">
                  <div className="text-wrapper-20">
                    {
                      this.state.mode === 1 ? (
                        '1. Regístrate'
                      ) : this.state.mode === 2 ? (
                        'Visibilidad Omnicanal en tiempo real'
                      ) : 'Transformación digital integral '
                    }
                  </div>

                  <p className="div-2">
                    <span className="text-wrapper-21">
                      {
                        this.state.mode === 1 ? (
                          <>Recibe una invitación para crear tu <b>perfil personal</b> y el <b>perfil</b> para tu compañía.</>
                        ) : this.state.mode === 2 ? (
                          'Monitorea cada fase del ciclo de un pedido, desde su creación hasta la entrega...'
                        ) : 'El único SAAS de logística que te permite conectar todas las areas de tu negocio...'
                      }
                      <br />
                    </span>

                    {
                      this.state.mode !== 1 && (
                        <span
                         className="text-wrapper-22"
                         onClick={() => {
                          if(this.state.mode === 2) {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/cargo-owner-1.svg',
                              detailTitle: 'Visibilidad Omnicanal en tiempo real',
                              detailMessage: 'Monitorea cada fase del ciclo de un pedido, desde su creación hasta la entrega, con seguimiento minuto a minuto. Mejora la transparencia y reacciona rápido ante imprevistos.'
                            })
                          } else {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/cargo-owner-2.svg',
                              detailTitle: 'Transformación digital integral',
                              detailMessage: 'El único SAAS de logística que te permite conectar todas las areas de tu negocio, sucursales o bases con todos tus clientes sin importar su industria, giros comercial o tipo de operación facilitando una operación más rápida, eficiente y con experiencia digital.'
                            })
                          }
                         }}
                        >Leer más</span>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>

            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img 
                    className="frame-32" 
                    alt="Frame" 
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-2.svg" ) : this.state.mode === 2 ? (
                        "/images/img/cargo-owner-2.svg"
                      ) : "/images/img/cargo-owner-1.svg"
                    }
                  />
                </div>

                <div className="frame-33">
                  <div className="text-wrapper-23">
                    {
                      this.state.mode === 1 ? (
                        '2. Invita'
                      ) : this.state.mode === 2 ? (
                        `Logística inteligente con IA`
                      ) : 'Visibilidad Omnicanal en tiempo real'
                    }
                  </div>

                  <p className="div-2">
                    <span className="text-wrapper-21">
                      {
                        this.state.mode === 1 ? (
                          `A todos los colaboradores de tu empresa involucrados en la operación de la cadena de abastecimiento y en la toma de decisiones del negocio.`
                        ) : this.state.mode === 2 ? (
                          `Automatiza la planificación, gestión de envíos y citas de recolección/entrega con análisis...`
                        ) : `Brinda estatus automático a todos tus clientes de cada etapa de sus viajes...`
                      }
                      <br />
                    </span>
                    {
                      this.state.mode !== 1 && (
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            if(this.state.mode === 2) {
                              this.setState({
                                showViewDetail: true,
                                detailImg: '/images/img/cargo-owner-2.svg',
                                detailTitle: 'Logística inteligente con IA',
                                detailMessage: 'Automatiza la planificación, gestión de envíos y citas de recolección/entrega con análisis predictivos basados en datos históricos y situaciones en tiempo real.'
                              })
                            } else {
                              this.setState({
                                showViewDetail: true,
                                detailImg: '/images/img/cargo-owner-1.svg',
                                detailTitle: 'Visibilidad Omnicanal en tiempo real',
                                detailMessage: 'Brinda estatus automático a todos tus clientes de cada etapa de sus viajes en tiempo real desde la confirmación de solicitud de viaje, la asignación de unidad, la recolección de las mercancias y hasta la entrega en destino final. '
                              })
                            }
                         }}
                        >Leer más</span>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>

            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img
                    className="group-11"
                    alt="Group"
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-3.svg" ) : this.state.mode === 2 ? (
                        "/images/img/cargo-owner-3.svg"
                      ) : "/images/img/cargo-owner-3.svg"
                    }
                  />
                </div>
                <div className="frame-33">
                  <div className="text-wrapper-23">
                      {
                        this.state.mode === 1 ? (
                          `3. Configura`
                        ) : this.state.mode === 2 ? (
                          `Seguridad vial y cumplimiento fiscal garantizado`
                        ) : `Logística inteligente con IA`
                      }
                  </div>
                  <p className="div-2">
                    <span className="text-wrapper-21">
                      {
                        this.state.mode === 1 ? (
                          <>Los catálogos de <b>datos maestros</b> de la cuenta de tu empresa.</>
                        ) : this.state.mode === 2 ? (
                          `Verifica que tus transportistas cumplan con las normativas viales y...`
                        ) : `Recibe asignaciones de viajes y citas de recolección y entrega de forma...`
                      }
                      <br />
                    </span>
                    {
                      this.state.mode !== 1 && (
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            if(this.state.mode === 2) {
                              this.setState({
                                showViewDetail: true,
                                detailImg: '/images/img/cargo-owner-3.svg',
                                detailTitle: 'Seguridad vial y cumplimiento fiscal garantizado',
                                detailMessage: 'Verifica que tus transportistas cumplan con las normativas viales y fiscales, incluyendo la Carta Porte, reduciendo el riesgo de accidentes y sanciones.'
                              })
                            } else {
                              this.setState({
                                showViewDetail: true,
                                detailImg: '/images/img/cargo-owner-3.svg',
                                detailTitle: 'Logística inteligente con IA',
                                detailMessage: 'Recibe asignaciones de viajes y citas de recolección y entrega de forma automática por parte de tus clientes con la disponibilidad que tus unidades reportan de forma automática a los clientes con quien se encuentran asignadas.'
                              })

                            }
                          }}
                        >Leer más</span>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>

            {
              this.state.mode === 3 && (
                <div className="frame-30">
                  <div className="frame-31">
                    <div className="frame-31-1">
                      <img
                        className="group-11"
                        alt="Group"
                        src="/images/img/cargo-owner-3.svg"
                      />
                    </div>

                    <div className="frame-33">
                      <div className="text-wrapper-23">
                        Seguridad vial y cumplimiento fiscal garantizado
                      </div>

                      <p className="div-2">
                        <span className="text-wrapper-21">
                          {
                            `Audita el cumplimiento normativo de tu flota y permite a tus clientes que...`
                          }
                          <br />
                        </span>
                        <span
                          className="text-wrapper-22"
                          onClick={() => {
                            this.setState({
                              showViewDetail: true,
                              detailImg: '/images/img/cargo-owner-3.svg',
                              detailTitle: 'Seguridad vial y cumplimiento fiscal garantizado',
                              detailMessage: 'Audita el cumplimiento normativo de tu flota y permite a tus clientes que validen tus documentos.  Garantiza el cumplimiento fiscal de la carta porte con las funciones de timbrado automatico al momento de cargar las mercancías o durante el viaje cada vez que hay un cambio de operador o vehículo.'
                            })
                          }}
                        >Leer más</span>
                      </p>
                    </div>
                  </div>
                </div>
              )
            }

            <div className="frame-30">
              <div className="frame-31">
                <div className="frame-31-1">
                  <img 
                    className="frame-35" 
                    alt="Frame" 
                    src={ this.state.mode === 1 ? (
                      "/images/img/group-129-4.svg" ) : this.state.mode === 2 ? (
                        "/images/img/group-129-5.svg"
                      ) : "/images/img/group-129-5.svg"
                    } 
                  />
                </div>

                <div className="frame-33">
                  <p className="text-wrapper-20">
                    
                    {
                      this.state.mode === 1 ? (
                        `4. Crea tu red de colaboración`
                      ) : this.state.mode === 2 ? (
                        `Medición de productividad y mejora continua`
                      ) : `Medición de productividad y mejora continua`
                    }
                  </p>

                  <p className="text-wrapper-24">
                    
                    {
                      this.state.mode === 1 ? (
                        `Envía invitaciones a todos tus clientes, proveedores y transportistas para que se únan a colaborar de forma privada en la red de Orbinetwork. `
                      ) : this.state.mode === 2 ? (
                        `Evalúa el desempeño de toda la cadena logística con reportes...`
                      ) : `Evalúa el desempeño de tus unidades, operadores y de tus clientes e...`
                    }
                    {
                      this.state.mode !== 1 && (
                        <>
                          <br />
                          <span
                            className="text-wrapper-22"
                            onClick={() => {
                              if(this.state.mode === 2) {
                                this.setState({
                                  showViewDetail: true,
                                  detailImg: '/images/img/group-129-5.svg',
                                  detailTitle: 'Medición de productividad y mejora continua',
                                  detailMessage: 'Evalúa el desempeño de toda la cadena logística con reportes e indicadores personalizables del módulo de Inteligencia de Negocios (BI), para tomar decisiones estratégicas.'
                                })
                              } else {
                                this.setState({
                                  showViewDetail: true,
                                  detailImg: '/images/img/group-129-5.svg',
                                  detailTitle: 'Medición de productividad y mejora continua',
                                  detailMessage: 'Evalúa el desempeño de tus unidades, operadores y de tus clientes e identifica oportunidades para mejorar, asi como la rentabilidad de  rutas, operaciones y clientes con los reportes y decenas de indicadores, tablas y gráficas personalizables del módulo de Inteligencia de Negocios (Bi Business Intelligence.)'
                                })
                              }
                            }}
                          >Leer más</span>
                        </>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>
            
            <div className={this.state.mode === 3 ? "frame-30" : "frame-36"}>
              {
                this.state.mode !== 3 && (
                  <div className="frame-31-1">
                    <img 
                      className="frame-32" 
                      alt="Frame" 
                      src={ this.state.mode === 1 ? (
                        "/images/img/group-129-5.svg" ) : this.state.mode === 2 ? (
                          "/images/img/cargo-owner-5.svg"
                        ) : ""
                      } 
                    /> 
                  </div>
                )
              }

              <p className="text-wrapper-25">
                ¡Todo listo! comienza a usar Orbinetwork
              </p>

              <p className="text-wrapper-26">
                {
                  this.state.mode === 1 ? (
                    `Conoce todo lo que Orbinetwork puede hacer para ti.`
                  ) : this.state.mode === 2 ? (
                    `Conoce todo lo que Orbinetwork puede hacer para ti. Regístrate ahora para recibir una invitación.`
                  ) : `Audita el cumplimiento normativo de tu flota y permite a tus clientes que...`
                }
              </p>

              <div className="frame-37">
                    <Link to={`/survey`}>
                      <div className="crea-tu-cuenta-2">Solicitar invitación</div>
                    </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="frame-38">
        <div className="frame-39">
          {/* <div className="text-wrapper-27">
            
            {
              this.state.mode === 1 ? (
                `Beneficios`
              ) : this.state.mode === 2 ? (
                `Features`
              ) : `Features`
            }
          </div> */}

          <div className="frame-40">
            <p className="text-wrapper-28">
              
              {
                this.state.mode === 1 ? (
                  `Conoce los beneficios y funciones para...`
                ) : this.state.mode === 2 ? (
                  `Funciones para Dueños de Carga`
                ) : `Funciones para Transportistas`
              }
            </p>
          </div>
        </div>
        
        {
          this.state.mode === 1 ? (
            <div className="frame-41">
              <div className="frame-42">
                <div className="frame-43">
                  <div className="group-12" />
    
                  <div className="frame-44">
                    <div className="frame-45">
                      <div className="text-wrapper-29">Dueños de Carga</div>
    
                      <p className="si-tu-actividad">
                        Si tu empresa fabrica, distribuye, comercializa y/o vende cualquier producto o materia prima, descubre todo lo que Orbinetwork tiene para los dueños de carga como tú.
                      </p>
                    </div>
    
                    <div className="frame-46">
                      <div className="CTA-primary-2 rectangle-4">
                          <button 
                            className="crea-tu-cuenta-3" 
                            onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(2)}}
                          >Conoce más</button>
                      </div>
                      <Link to={`/survey`}>
                        <div className="crea-tu-cuenta-4">Solicitar invitación</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="frame-47">
                <div className="frame-48">
                  <div className="frame-49">
                    <img
                      className="group-13"
                      alt="Group"
                      src="/images/img/group-148.png"
                    />
                    <div className="text-wrapper-30">Transportistas</div>
                  </div>
    
                  
                  <p className="si-tu-actividad-2">
                    Si tu empresa presta servicio de transporte de carga federal o privada a dueños de carga, descubre todo lo que Orbinetwork tiene para los transportistas como tú.
                  </p>
    
                  <div className="frame-46">
                    <div className="CTA-primary-3">
                      <img
                        className="rectangle-5"
                        alt="Rectangle"
                        src="/images/img/rectangle.svg"
                      />
                        <button 
                          className="crea-tu-cuenta-5" 
                          onClick={() => {this.mainPage.current.scrollIntoView();this.handleModeChange(3)}}
                        >Conoce más</button>
                    </div>
                    <Link to={`/survey`}>
                      <div className="crea-tu-cuenta-6">Solicitar invitación</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : this.state.mode === 2 ? (
            <div className="carousel-container">
              <CarouselProvider
                visibleSlides={this.state.benefitsSlides}
                totalSlides={7}
                step={1}
                // currentSlide={currentSlide}
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                isIntrinsicHeight={true}
              >
                <div className="slider-wrapper">
                  <Slider>
                    <Slide index={0} className="slide">
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-1.svg" />
                        <p className="frame-41-2-title">
                          Red de colaboración
                        </p>
                        <p className="frame-41-2-text">
                          Crea tu propia red privada de colaboración con todos tus clientes en una misma herramienta.
                        </p>
                      </div>
                    </Slide>
                    <Slide index={1}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-2.svg" />
                        <p className="frame-41-2-title">
                          Control de Órdenes y Viajes
                        </p>
                        <p className="frame-41-2-text">
                          Gestiona de principio a fin los pedidos y embarques de todas tus operaciones (T1, T2, UM)
                        </p>
                      </div>
                    </Slide>
                    <Slide index={2}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-3.svg" />
                        <p className="frame-41-2-title">
                          Monitoreo
                        </p>
                        <p className="frame-41-2-text">
                          Visualiza estatus y ubicacion en tiempo real, y centraliza la gestiona citas de recolección y entrega.
                        </p>
                      </div>
                    </Slide>
                    <Slide index={3}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-4.svg" />
                        <p className="frame-41-2-title">
                          Timbrado CFDI con Complemento Carta Porte
                        </p>
                        <p className="frame-41-2-text">
                          Automatiza el timbrado de los CFDIS con Complemento Carta Porte de todos tus viajes.
                        </p>
                      </div>
                    </Slide>
                    <Slide index={4}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-5.svg" />
                        <p className="frame-41-2-title">
                          Normatividad
                        </p>
                        <p className="frame-41-2-text">
                          Audita las normatividades de todos tus transportistas
                        </p>
                      </div>
                    </Slide>
                    <Slide index={5}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-6.svg" />
                        <p className="frame-41-2-title">
                          Disponibilidad de Flota
                        </p>
                        <p className="frame-41-2-text">
                          Consulta la unidades disponibles de tus transportistas.
                        </p>
                      </div>
                    </Slide>
                    <Slide index={6}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-7.svg" />
                        <p className="frame-41-2-title">
                          Reportes y KPIs
                        </p>
                        <p className="frame-41-2-text">
                          Mide el desempeño de toda tu cadena con nuestro Business Intelligence.
                        </p>
                      </div>
                    </Slide>
                  </Slider>
                  <div className="controls">
                    <ButtonBack className="btn-arrow reverse-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonBack>
                    <DotGroup className="dot-group" />
                    <ButtonNext className="btn-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonNext>
                  </div>
                </div>
              </CarouselProvider>
            </div>
          ) : (
            <div className="carousel-container">
              <CarouselProvider
                visibleSlides={this.state.benefitsSlides}
                totalSlides={7}
                step={1}
                // currentSlide={currentSlide}
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                isIntrinsicHeight={true}
              >
                <div className="slider-wrapper">
                  <Slider>
                    <Slide index={0} className="slide">
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-1.svg" />
                        <p className="frame-41-2-title">
                          Red de colaboración
                        </p>
                        <p className="frame-41-2-text">
                          Crea tu propia red privada de colaboración con todos tus clientes en una misma herramienta.
                        </p>
                      </div>
                    </Slide>
                    <Slide index={1}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-2.svg" />
                        <p className="frame-41-2-title">
                          Control de Viajes
                        </p>
                        <p className="frame-41-2-text">
                          Gestiona y asigna unidades a tus viajes.
                        </p>
                      </div>
                    </Slide>
                    <Slide index={2}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-3.svg" />
                        <p className="frame-41-2-title">
                          Monitoreo
                        </p>
                        <p className="frame-41-2-text">
                          Visualiza estatus, ubicacion y evidencias en tiempo real y solicita cambios de citas de recolección y entrega.
                        </p>
                      </div>
                    </Slide>
                    <Slide index={3}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-4.svg" />
                        <p className="frame-41-2-title">
                          Timbrado CFDI con Complemento Carta Porte
                        </p>
                        <p className="frame-41-2-text">
                          Automatiza el timbrado de los CFDIS con Complemento Carta Porte de todos tus viajes de todos tus clientes.
                        </p>
                      </div>
                    </Slide>
                    <Slide index={4}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-5.svg" />
                        <p className="frame-41-2-title">
                          Normatividad
                        </p>
                        <p className="frame-41-2-text">
                          Permite que tus clientes auditen las normatividades de tu flotilla.
                        </p>
                      </div>
                    </Slide>
                    <Slide index={5}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-6.svg" />
                        <p className="frame-41-2-title">
                          Disponibilidad de Flota
                        </p>
                        <p className="frame-41-2-text">
                          Reporta tus unidades disponibles de forma automática a tus clientes.
                        </p>
                      </div>
                    </Slide>
                    <Slide index={6}>
                      <div className="frame-41-2">
                        <img className="frame-41-2-img" alt="icon" src="/images/img/frame-41-1-7.svg" />
                        <p className="frame-41-2-title">
                          Reportes y KPIs
                        </p>
                        <p className="frame-41-2-text">
                          Mide el desempeño de todas tus operaciones y clientes con nuestro Business Intelligence.
                        </p>
                      </div>
                    </Slide>
                  </Slider>
                  <div className="controls">
                    <ButtonBack className="btn-arrow reverse-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonBack>
                    <DotGroup className="dot-group" />
                    <ButtonNext className="btn-arrow">
                      <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                    </ButtonNext>
                  </div>
                </div>
              </CarouselProvider>
            </div>
          )
        }
        

      </div>

      <div className="frame-50"  ref={this.pricingRef}>
        <div className="frame-51">
          <div className="text-wrapper-19">Nuestros precios</div>

          <p className="text-wrapper-31">
            Elige el plan que mejor se adapte a ti
          </p>
        </div>

        <div className="frame-52">
          {
            this.state.mode === 1 && 
            <div className="rectangle-6" />
          }

          {
            this.state.mode !== 3 &&
            <div 
              className={"frame-53 " + (this.state.pricingMode === 1 || this.state.mode === 2 ? 'active' : '') + (this.state.mode !== 1 ? ' unique' : '')} 
              onClick={() => { 
                this.setState({pricingMode: 1});
              }}
            >
              <div className="text-wrapper-32" >
                Dueños de carga
              </div>
            </div>
          }

          {
            this.state.mode !== 2 &&
            <div 
              className={"frame-54 " + (this.state.pricingMode === 2 || this.state.mode === 3 ? 'active' : '') + (this.state.mode !== 1 ? ' unique' : '')} 
              onClick={() => {
                this.setState({pricingMode: 2});
              }}
            >
              <div className="text-wrapper-33">
                Transportistas
                </div>
            </div>
          }
        </div>

        <div className="carousel-container">
          <CarouselProvider
            visibleSlides={1}
            totalSlides={(this.state.mode === 2 || (this.state.mode === 1 && this.state.pricingMode === 1)) ? 2 : 1}
            step={1}
            // currentSlide={currentSlide}
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            isIntrinsicHeight={true}
          >
            <div className="slider-wrapper">
              <Slider>
                <Slide index={0} className="slide">
                  <div className="group-14-container">
                    <p className="text-wrapper-141">Basic**</p>
                    <p className="text-wrapper-142">GRATIS</p>
                    <div className="group-14-item">
                      <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                      Usuarios
                    </div>
                    <div className="group-14-item">
                      <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                      Productos
                    </div>
                    <div className="group-14-item">
                      <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                      Instalaciones
                    </div>
                    <div className="group-14-item">
                      <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                      Red (Comunidad)
                    </div>
                    <div className="group-14-item">
                      <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                      Rutas
                    </div>
                    <div className="group-14-item">
                      <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                      RutaFlotilla (Normatividad)
                    </div>
                    <p className="text-wrapper-143">Controles</p>
                    <div className="group-14-item">
                      <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                      Órdenes y citas
                    </div>
                    <div className="group-14-item">
                      <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                      Embarques
                    </div>
                    <div className="group-14-item">
                      <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
                      Contratos
                    </div>
                  </div>
                </Slide>
                {
                  (this.state.mode === 2 || (this.state.mode === 1 && this.state.pricingMode === 1)) && (
                    <Slide index={1}>
                      <div className="group-14-container">
                        <p className="text-wrapper-144">Premium</p>
                        <p className="text-wrapper-145">$1,199 USD/mes</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Usuarios
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Productos
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Instalaciones
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Red (Comunidad)
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Rutas
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          RutaFlotilla (Normatividad)
                        </div>
                        <p className="text-wrapper-143">Controles</p>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Órdenes y citas
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Embarques
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Tráfico y GPS
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Instalaciones
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Contratos
                        </div>
                        <div className="group-14-item">
                          <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
                          Reportes: Inteligencia de negocios
                        </div>
                      </div>
                    </Slide>
                  )
                }
              </Slider>
              <div className="controls">
                <ButtonBack className="btn-arrow reverse-arrow">
                  <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                </ButtonBack>
                <DotGroup className="dot-group" />
                <ButtonNext className="btn-arrow">
                  <img src="/images/img/carousel-arrow.svg" alt="arrow" />
                </ButtonNext>
              </div>
            </div>
          </CarouselProvider>
        </div>

        <div className="group-14-2">
          <p className="text-wrapper-146">*Versión Lite incluida gratis. Opcionalmente puede pagar la versión</p>

          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109.svg"/>
            Incluido
          </div>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/frame-109-1.svg"/>
            Incluido para las transacciones/viajes pagados por evento con Orbibucks
          </div>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/group-286.svg"/>
            Pago por evento con Orbibucks tarifa variable por tipo de embarque
          </div>
        </div>

        <div className="group-14-3">
          <p className="text-wrapper-147">**Costo en Orbibucks por tipo de embarques</p>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/group-286-1.svg"/>
            <div className="group-14-item-description">
              <div className="text-wrapper-148">*FTL (T1)</div>
              <div className="text-wrapper-149">1.00 Orbibuck por remolque por viaje</div>
            </div>
          </div>
          <div className="group-14-item">
            <img className="group-14-checkmark" alt="Checkmark" src="/images/img/group-286-1.svg"/>
            <div className="group-14-item-description">
              <div className="text-wrapper-148">LTL (T2 y UM)</div>
              <div className="text-wrapper-149">0.40 Orbibucks por pedido/entrega</div>
            </div>
          </div>
        </div>

        <div className="frame-69-1">
          <p className="text-wrapper-691">Precios Orbibucks</p>
          <div className="frame-69-container">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">100 Orbibucks</div>
              <div className="text-wrapper-693">$190 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.90 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">

            </div>
          </div>
          <div className="frame-69-container extra-info">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">200 Orbibucks</div>
              <div className="text-wrapper-693">$340 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.70 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">
              Ahorra
              <p className="text-wrapper-695">10%</p>
            </div>
          </div>
          <div className="frame-69-container extra-info">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">500 Orbibucks</div>
              <div className="text-wrapper-693">$750 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.50 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">
              Ahorra
              <p className="text-wrapper-695">20%</p>
            </div>
          </div>
          <div className="frame-69-container extra-info">
            <img className="frame-69-checkmark" alt="Checkmark" src="/images/img/group-286-2.svg"/>
            <div className="frame-69-item-description">
              <div className="text-wrapper-692">1000+ Orbibucks</div>
              <div className="text-wrapper-693">$1300 USD + IVA</div>
              <div className="text-wrapper-694">P. Unitario $1.30 USD + IVA</div>
            </div>
            <div className="frame-69-extra-info">
              Ahorra
              <p className="text-wrapper-695">30%</p>
            </div>
          </div>
        </div>

      </div>

      <div className="frame-75">
        <div className="group-41">
          <div className="overlap-10">
            <div className="rectangle-13" />

            <div className="frame-77">
              <div className="frame-78">
                <div className="frame-79">
                  <p className="text-wrapper-60">
                    Decide transformarte y únete a la comunidad Orbinetwork hoy
                    mismo.
                  </p>

                  <p className="adem-s-recibe">
                    Además <b>gana cupones de hasta 50% de descuento</b> por invitar a tus clientes, proveedores y transportistas a que se únan y disfruten juntos los beneficios de Orbinetwork.
                  </p>
                </div>

                <div className="frame-80">
                  <div className="CTA-primary-wrapper">
                    <div className="CTA-primary-4">
                      <div className="rectangle-20" />

                      <Link to={`/survey`}>
                        <div className="crea-tu-cuenta-7">Solicitar invitación</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="group-43">
                <img
                  className="cloud-copy"
                  alt="Cloud copy"
                  src="/images/img/group-135.svg"
                />
              </div>
            </div>
            <div className="group-76">
              <div className="frame-76">
                <div className="text-wrapper-59">Respaldo</div>

                <p className="text-wrapper-4">
                  Organizaciones y certificaciones que respaldan nuestro trabajo
                </p>
              </div>

              <div className="group-42">
                <div className="group-42-1">
                  <div className="rectangle-14" />

                  <div className="rectangle-14" />

                  <div className="rectangle-14" />
                </div>
                <div className="group-42-1">
                  <div className="rectangle-14" />

                  <div className="rectangle-14" />

                  <div className="rectangle-14" />
                </div>

              </div>
            </div>

            
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-3">
          <div className="frame-81">
            <div className="text-wrapper-61">Dueños de Cargas</div>
          </div>

          <img className="icon" alt="Icon" src="/images/img/icon-1.svg" />

          <div className="frame-82">
            <div className="text-wrapper-61">Transportistas</div>
          </div>
        </div>
        <img className="footer-2" alt="Footer" src="/images/img/footer.svg" />
      </div>

      
      <LoginDetailModal
        open={this.state.showViewDetail}
        img={this.state.detailImg}
        title={this.state.detailTitle}
        message={this.state.detailMessage}
        closeAction={() => this.setState( { showViewDetail: false, detailImg: '', detailTitle: '', detailMessage: '' })}
      />

      <LoginDialog
        open={this.state.showLoginModal}
        title="Iniciar sesión"
        isMobile={this.state.isMobileDevice}
        closeAction={() => this.closeModals()}
        closure={() => this.setState({ showRegisterModal: true })}
        showError={(errorMessage) => {
          this.setState({ errorMessage });
        }}
        forgotAction={this.forgotModal}
      />

      <RegisterDialog
        open={this.state.showRegisterModal}
        title="Crea tu cuenta"
        isMobile={this.state.isMobileDevice}
        closeAction={() => this.closeModals()}
        closure={() => {
          if (this.state.emailRegister !== '') {
            this.setState({ showRegisterInvitationMessageModal: true, emailRegister: "" });
            return;
          }

          if (this.state.emailInvitation === "") {
            this.setState({ showRegisterMessageModal: true });
          } else {
            this.setState({ showRegisterInvitationMessageModal: true });
          }
        }}
        showError={(errorMessage) => {
          this.setState({ errorMessage });
        }}
        emailInvitation={this.state.emailInvitation}
        hasInvitation={this.state.hasInvitation}
        changeToLogin={() => this.changeToLogin()}
        emailRegister={this.state.emailRegister}
      />

      <ResetNewPasswordDialog
        open={this.state.showResetNewPasswordModal}
        token={this.state.token}
        title="Crea contraseña nueva"
        closeAction={() => this.closeModals()}
        closure={() => this.setState({ showResetNewPasswordMessage: true })}
        showError={(errorMessage) => {
          this.setState({ errorMessage });
        }}
      />

      <ResetPasswordMessageDialog
        open={this.state.showResetNewPasswordMessage}
        message="Tu contraseña fue modificada con éxito"
        acceptAction={() => {
          this.closeModals();
          this.setState({ showLoginModal: true });
        }}
        closeAction={() => {
          this.closeModals();
          this.setState({ showLoginModal: true });
        }}
      />

      <RecoverPasswordDialog
        open={this.state.showRecoverPasswordModal}
        title="Recupera tu contraseña"
        closeAction={() => this.closeModals()}
        closure={() => this.setState({ showRecoverMessageModal: true })}
      />

      <GenericDialog
        open={this.state.showSessionError}
        title="¡Error!"
        message="La sesión se ha expirado"
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showRecoverMessageModal}
        title="¡Enviado!"
        message="Te hemos enviado un correo, para que puedas cambiar tu contraseña"
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showSuccessValidation}
        title="Éxito"
        message="Tu email ha sido verificado."
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showSuccessInvitation}
        title="Éxito"
        message="La invitación ha sido aceptada."
        acceptAction={() => this.closeModals()}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showRegisterMessageModal}
        title="Listo!"
        message="Te hemos enviado un correo, para que confirmes tu cuenta"
        acceptAction={() => {
          this.closeModals();
          window.location.href = "/register/account/";
        }}
        closeAction={() => this.closeModals()}
      />

      <GenericDialog
        open={this.state.showRegisterInvitationMessageModal}
        title="Listo!"
        message="Su cuenta ha sido creada con exito"
        acceptAction={() => {
          this.closeModals();
          window.location.href = "/register/account/";
        }}
        closeAction={() => this.closeModals()}
      />

      <ErrorDialog
        addClass="invitation-modal"
        open={this.state.showInvitationModal}
        message="Ya tienes una cuenta"
        acceptText="Iniciar Sesión"
        acceptAction={() => {
          this.setState({ showInvitationModal: false, showLoginModal: true });
        }}
        closeAction={() => {
          this.setState({ showInvitationModal: false });
        }}
      />
      
      <LoginErrorDialog
        open={this.state.errorMessage !== ""}
        message={this.state.errorMessage}
        acceptText="Aceptar"
        acceptAction={() => {
          this.closeModals();
          this.setState({ emailInvitation: "" });
        }}
        openCreateAccount={ () => {
          this.setState({
            errorMessage: '',
            showRegisterModal: true,
          });
        }}
        openForgetPassword={() => {
          this.setState({
            errorMessage: '',
            showRecoverPasswordModal: true,
          });
        }}
        closeAction={() => {
          this.closeModals();
          this.setState({ emailInvitation: "" });
        }}
      />

    </div>
  );
  }
}

export default Login;