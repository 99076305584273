import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { cancelRequest, createEntity, getEntityItems, readEntities, readEntity } from "../../redux/api";
import { listNetworkApprovedInvitations } from "../../redux/reducers/company-reducer";
import Caret from "../../shared/component/caret";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import Selector from "../../shared/component/selector";
import ShipmentBoardRow from "../../shared/component/shipment-board-row";
import TableD from "../../shared/component/table-d";
import Button from "../../shared/controls/button";
import OptionSelectorSwitch from "../../shared/controls/option-selector-switch";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import { View, ViewTitle } from "../../shared/styled/view";
import ConsolidateOrderModal from "./consolidate-order-modal";
import ConsolidateTripModal from "./consolidate-trip-modal";
import CreateOrderModal from "./create-order-modal";
import OrderCatalogRow from "./order-catalog-row";
import Loader from "../../shared/icons/tat/loader";
import { cancelMultipleRequests, partialEntity } from "../../redux/api/actions";
import { RequisitionView } from "./tables/requisition-table-view";
import GenericDialog from "../../shared/dialog/generic-modal";
import { RequisitionDetailView } from "./tables/requisition-detail-table-view";
import DivideRequisitionDeliveryModal from "../../shared/dialog/divide-requisition-delivery-modal";
import AddProductRequisitionDeliveryModal from "../../shared/dialog/add-product-requisition-delivery-modal";
import moment from 'moment';
import { sendMessageToMultipleUsersInSocket, showSimpleFlashNotification } from "../../shared/utils";
import ModifyProductDeliveryModal from "../../shared/dialog/modify-product-delivery-modal";
import ConvertDeliveryToOrderModal from "../../shared/dialog/convert-delivery-to-order-modal";

const TRANSACTIONS = [
  { description: "Compra", value: 1 },
  { description: "Venta", value: 2 },
  { description: "Externas", value: 3 },
]

const TRANSPORT = [
  { description: "Camión", value: 1 },
  { description: "Tren", value: 2 },
  { description: "Barco", value: 3 },
  { description: "Avión", value: 4 },
]

const ITEMS = [
  { description: "Pedidos", value: 1 },
  { description: "Tarimas", value: 3 },
  { description: "Órdenes", value: 2 },
]

class OrdersControlCatalogView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collapseAll: true,
      limit: 10,
      offset: 1,
      getOrdersCalled: false,

      showCreateOrderModal: false,
      showConsolidateModal: false,
      showConsolidateTripModal: false,
      productsToAdd: [],
      selectedProvider: 0,
      selectedFacility: 0,
      selectedTags: [],
      selectedCode: "",
      selectedDate: null,
      selectedLevel: 1,
      selectedClass: 0,
      selectedDate: null,

      selectionModeOn: false,
      selectedOrdersId: [],
      
      transactionType: props?.history?.location?.state?.transactionType || 1,
      transportType: 1,
      itemType: props?.history?.location?.state?.itemType || 1,
      providerProducts: [],
      moreProducts: true,
      providerFacilities: [],
      isProductsModeSelection: false,
      seed: Math.random(),
      requisitionDetail: null,
      requisitionsSelected: [],
      test_modal: null,
      error_message: null,
      requisitions: [],
      divideRequisitionDelivery: null,
      addProductRequisitionDelivery: null,
      productsSelected: [],
      confirmRequisitionsModal: false,
      cancelRequisitionsModal: false,
      isAllRequisitionsSelected: false,
      isAllProductsSelected: false,
      confirmRequisitionData: null,
      removeProductsModal: false,
      removeUniqueProductData: null,
      modifyProductData: null,
      deleteDelivery: null,
      convertDeliveryToOrderRequisition: null,
      convertDeliveryToOrderDeliveries: []
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.cancelUpdate, true);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.cancelUpdate);

    this.props.loadCompanyTags({
      company_id: this.props.companyId
    })
    this.props.loadCompanyTransportClass({
      company_id: this.props.companyId
    })
    this.props.loadCompanyUnits({
      company_id: this.props.companyId,
    });
    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
    });
    this.props.loadFacilityInfo({
      company_id: this.props.companyId,
      id: this.props.match.params.id
    });
    // this.loadOrders();
    this.loadItems();
  }

  cancelUpdate = (e) => {
    if(e.key === "Escape" && this.props.isLoading){
      this.props.cancelMultipleRequests();
    }
  }
  
  handleTabChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, selectedOrdersId: [], offset: 1}, () => {this.loadItems()});
  };

  loadItems = () => {    
    if (this.props.getOrdersCalled) {
      this.props.cancelMultipleRequests();
    }

    this.setState({ getOrdersCalled: true });
    if(this.state.transactionType === 3) return;
    if(this.state.itemType === 1){
      // this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.CATALOG");
      this.props.loadFacilityRequisitions({
        company_id: this.props.companyId,
        // facility_id: this.props.match.params.id,
        from_facility: this.state.transactionType === 2 ? this.props.match.params.id : '',
        to_facility: this.state.transactionType === 1 ? this.props.match.params.id : '',
        limit: this.state.limit,
        offset: this.state.offset,
        isMultipleCancels: true
      }, {
        onSuccess: response => {
          this.setState({seed: Math.random()})
        }
      });
    } else if(this.state.itemType === 2) {
      // this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.ORDERS.CATALOG");
      this.props.loadFacilityOrders({
        company_id: this.props.companyId,
        // facility_id: this.props.match.params.id,
        from_facility: this.state.transactionType === 2 ? this.props.match.params.id : '',
        to_facility: this.state.transactionType === 1 ? this.props.match.params.id : '',
        limit: this.state.limit,
        offset: this.state.offset,
        isMultipleCancels: true
      });
    } else if(this.state.itemType === 3) {
      // this.props.cancelRequest("COMPANIES.REQUISITIONSCONTROL.PALLETS.CATALOG");
      this.props.loadFacilityPallets({
        company_id: this.props.companyId,
        // facility_id: this.props.match.params.id,
        from_facility: this.state.transactionType === 2 ? this.props.match.params.id : '',
        to_facility: this.state.transactionType === 1 ? this.props.match.params.id : '',
        limit: this.state.limit,
        offset: this.state.offset,
        isMultipleCancels: true
      });
    }
  }

  loadOrders = () => {
    // this.setState({ getOrdersCalled: true });
    // this.props.cancelRequest("COMPANIES.ORDERS.CONTROL.CATALOG");

    // this.props.loadFacilityOrders({
    //   company_id: this.props.companyId,
    //   facility_id: this.props.match.params.id,
    //   limit: this.state.limit,
    //   offset: this.state.offset
    // });
  }

  loadProviderFacilities = () => {
    if (this.state.selectedProvider === '-1') {
      this.props.loadOwnFacilities({
        company_id: this.props.companyId,  
      }, {
        onSuccess: (response) => {
          this.setState({
            providerFacilities: (response || []).map(f => ({
              value: String(f.id),
              label: f.name.concat(' - ', f.alias, ' - ', f.code)
            }))
          });
        }
      })
      return
    }

    this.props.loadProviderFacilities({
      company_id: this.props.companyId,
      network_id: this.state.selectedProvider
    }, {
      onSuccess: (response) => {
        this.setState({
          providerFacilities: (response || []).map(f => ({
            value: String(f.id),
            label: f.name.concat(' - ', f.alias, ' - ', f.code)
          }))
        });
      }
    });
  }

  loadProviderProducts = (search_value, offset) => {
    if (this.state.selectedProvider === '-1') {
      this.props.loadProducts({
        company_id: this.props.companyId,
        view: 'short_detail',
        tags: '',
        skus: '',
        products: '',
        search: search_value || '',
        limit: '',
        offset: offset || '',
        isMultipleCancels: true
      }, {
        onSuccess: (response) => {
          this.setState({
            moreProducts: `${offset}` === '1' ? true : response.length > 0,
            providerProducts: `${offset}` === '1' ? response : this.state.providerProducts.concat(response)
          })
        }
      });
      return
    }

    this.props.loadProviderProducts({
      company_id: this.props.companyId,
      network_id: this.state.selectedProvider,
      search: search_value || '',
      offset: offset || '',
      isMultipleCancels: true
    }, {
      onSuccess: (response) => {
        this.setState({
          moreProducts: `${offset}` === '1' ? true : response.length > 0,
          providerProducts: `${offset}` === '1' ? response : this.state.providerProducts.concat(response)
        })
      }
    });
  }

  handleGridChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch(name) {
      case 'convert_delivery_to_order':
        this.setState({
          convertDeliveryToOrderRequisition: this.state.requisitionDetail ? this.state.requisitionDetail : value.requisition,
          convertDeliveryToOrderDeliveries: value.deliveries
        })
        break;
      case 'delete-delivery':
        this.setState({
          deleteDelivery: value
        });
        break;
      case 'modify_product_data':
        this.setState({
          modifyProductData: value
        });
        break;
      case 'remove_unique_product_data':
        this.setState({
          removeUniqueProductData: value
        });
        break;
      case 'confirm_requisition':
        this.setState({
          confirmRequisitionData: value
        });
        break;
      case 'divide_delivery':
        this.setState({
          requisitionDetail: null
        });
        this.loadItems();
        break;
      case 'new_products_added':
        this.setState({
          requisitionDetail: null
        });
        this.loadItems();
        break;
      case 'products_selected':

        let products_selected = this.state.productsSelected || [];
        let products_to_remove = value.filter( item => item.selected === false).map( item => item.id);
        if (products_to_remove.length > 0) {
          products_selected = products_selected.filter( item => !products_to_remove.includes(item.id) );
        }

        let products_to_add = value.filter( item => item.selected).map( item => ({id: item.id, delivery_id: item.delivery_id}));
        if (products_to_add.length > 0) {
          products_selected = products_selected.concat(products_to_add);
        }

        products_selected = products_selected.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id
          ))
        );
        
        this.setState({
          productsSelected: products_selected
        });
        break;
      case 'add-new-product':
        this.setState({
          addProductRequisitionDelivery: value
        });
        break;
      case 'save-deliveries':

        break;
      case 'divide-requisition-delivery':
        this.setState({
          divideRequisitionDelivery: value
        });
        break;
      case 'test':
        this.setState({
          test_modal: value
        });
        break;
      case 'requisition_detail':
        if (this.state.test_modal !== null || this.state.confirmRequisitionData || this.state.removeUniqueProductData || this.state.modifyProductData || this.state.convertDeliveryToOrderRequisition) break;

        this.setState({
          requisitionDetail: value,
          requisitionsSelected: [],
          isAllRequisitionsSelected: false,
          isAllProductsSelected: false
        });
        break;
      case 'requisitions_selected':
        this.setState({
          requisitionsSelected: value
        });
        break;
      default:
        console.log('no action');
    }

  }

  handleModalChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch(name){
      case "validateSignature":
        let found_callback_ = value.found_callback || null;
        let no_found_callback_ = value.no_found_callback || null;

        this.props.validateSignatureTrip({
          company_id: this.props.companyId,
          view: 'validate',
          signature: this.state.selectedCode
        }, {
          onSuccess: (response) => {

            if ('is_found' in response && response.is_found && found_callback_) {
              found_callback_()
            } else {
              if (no_found_callback_) no_found_callback_();
            }

          }
        });

      break;
      case "validateCode":
        let found_callback = value.found_callback || null;
        let no_found_callback = value.no_found_callback || null;

        this.props.validateCodeOrder({
          company_id: this.props.companyId,
          view: 'validate_requisition',
          code: this.state.selectedCode
        }, {
          onSuccess: (response) => {

            if ('is_found' in response && response.is_found && found_callback) {
              found_callback()
            } else {
              if (no_found_callback) no_found_callback();
            }

          }
        });
      break;
      case "searchProduct":
        let search_value = value.search_value || '';
        let offset = value.offset || '';

        this.props.cancelMultipleRequests();
        this.loadProviderProducts(search_value, offset);
        break;
      case "selectedProvider": 
        this.setState({
          selectedProvider: value, 
          selectedFacility: 0
        }, () => {
          this.loadProviderFacilities();
          this.loadProviderProducts('','');
        });
        break;
      // case "addProduct":
      //   this.setState({productsToAdd: this.state.productsToAdd.concat([{id: value.id, qty: value.qty}])})
      //   break;
      case "updateProduct":
        let products = this.state.productsToAdd.filter(p => p.id !== value.id);
        // let index = this.state.productsToAdd.map(p => p.id).indexOf(value.id);
        // let selectedProduct = products.splice(index,1)[0];
        if(value.qty){
          products.push({
            id: value.id,
            qty: value.qty
          })
        }
        this.setState({productsToAdd: products});
        break;
      case "removeProduct":
        let products1 = this.state.productsToAdd.filter(p => p.id !== value);
        // let index = this.state.productsToAdd.map(p => p.id).indexOf(value.id);
        // let selectedProduct = products.splice(index,1)[0];
        this.setState({productsToAdd: products1});
        break;
      
      case "consolidateOrder":
        let order = this.getItemsData().find(r => r.id === this.state.selectedOrdersId[0]) || {};
        let date = this.state.selectedDate ? (new Date(this.state.selectedDate)).toISOString() : null;
        
        let packages = [];
        let pallets = [];
        if(this.state.itemType === 1) packages = this.props.requisitionsData.filter(r => this.state.selectedOrdersId.includes(r.id)).map(r => r.package_requisition.map(p => ({id: p.id}))).flat();
        if(this.state.itemType === 3 ) pallets = this.state.selectedOrdersId.map(sO => ({id: sO}))
        //debugger;
        this.props.createOrder({
          company_id: this.props.companyId,
          code: this.state.selectedCode,
          from_company: order.from_facility.company || 0,
          to_company: order.from_facility.company || 0,
          from_facility: order.from_facility.id || 0,
          to_facility: order.to_facility.id || 0,
          // requisition_order: this.state.selectedOrdersId.map(sO => ({id: sO})),
          packages_order: packages,
          pallets_order: pallets,
          ordertag_order: this.state.selectedTags.map(sO => ({tag: sO})),
          appointment_unload_date: date,
          priority: this.state.selectedLevel,
        }, {
          onSuccess: () => {
            value && value();
            this.loadItems();
          }
        })
        break;
      case "consolidateTrip":
        // let order = this.props.requisitionsData.find(r => r.id === this.state.selectedOrdersId[0]) || {};
        // let date = this.state.selectedDate ? (new Date(this.state.selectedDate)).toISOString() : null;
        this.props.createTrip({
          company_id: this.props.companyId,
          signature: this.state.selectedCode,
          // from_company: order.from_facility.company,
          // to_company: order.from_facility.company,
          // from_facility: order.from_facility.id,
          // to_facility: order.to_facility.id,
          order_trip: this.state.selectedOrdersId.map(sO => ({id: sO})),
          triptag_trip: this.state.selectedTags.map(sO => ({tag: sO})),
          transport_class: Number(this.state.selectedClass)
          // appointment_unload_date: date,
          // priority: this.state.selectedLevel,
        }, {
          onSuccess: () => {
            value && value();
            this.loadItems();
          }
        })
        break;
      case "createOrder":
        let reqProducts = [];
        this.state.productsToAdd.forEach(p => {
          let qty = Number(p.qty);
          reqProducts.push({id: p.id, qty})
        });

        let delivery_date = null;

        if (this.state.selectedDate !== null) {
          delivery_date = moment(this.state.selectedDate, "MM/DD/YYYY").format('YYYY-MM-DD');
        }

        this.props.createRequisitionV2({
          company_id: this.props.companyId,
          products: reqProducts,
          from_facility: Number(this.state.selectedFacility),
          to_facility: Number(this.props.match.params.id),
          code: this.state.selectedCode,
          network_id: this.state.selectedProvider !== '-1' ? Number(this.state.selectedProvider) : null,
          delivery_date
        }, {
          onSuccess: (result) => {
            value();
            this.loadItems();

            showSimpleFlashNotification('Se creó con éxito el pedido', null);

            try {
              sendMessageToMultipleUsersInSocket(
                this.props.userId,
                result['users'],
                result['data']
              );
            } catch(e) {
              console.log('requisition creation error:', e)
            }

          },
          onError: (e) => {
            this.resetModal();
            this.setState({
              showCreateOrderModal: false,
              error_message: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
            })
          }
        });
        // this.props.create Order({
        //   company_id: this.props.companyId,
        //   code: this.state.selectedCode,
        //   status: 1,
        //   from_company: (this.props.companyProviders.find(p => p.value === this.state.selectedProvider) || {id: 0}).id,
        //   to_company: this.props.companyId,
        //   from_facility: Number(this.state.selectedFacility),
        //   to_facility: Number(this.props.match.params.id),
        //   // ordertag_order: [/* {tag: 1} */],
        //   ordertag_order: this.state.selectedTags.map(t => ({
        //     tag: t
        //   })),
        //   orderproduct_order: this.state.productsToAdd.map(p => ({
        //     product: p.id,
        //     quantity: Number(p.qty)
        //   }))
        // }, {
        //   onSuccess: () => {
        //     value();
        //     this.loadOrders();
        //   }
        // })
        break;
      default:
        // debugger;
        this.setState({[name]: value});
    }
  }

  resetModal = () => {
    this.setState({
      productsToAdd: [],
      selectedProvider: 0,
      selectedFacility: 0,
      selectedTags: [],
      selectedOrdersId: [],
      selectedCode: "",
      selectedDate: null,
      selectedLevel: 1,
      selectedClass: 0,
      providerProducts: [],
      providerFacilities: []
    })
  }

  handleSelectTrip = (e) => {
    let selectedOrdersId = [...this.state.selectedOrdersId];
    if (e.target.value) {
      selectedOrdersId.push(e.target.name);
    } else {
      selectedOrdersId.splice(
        selectedOrdersId.findIndex((f) => f === e.target.name),
        1
      );
    }
    this.setState({ selectedOrdersId });
  };

  toggleSelectMode = () => {
    this.setState({selectionModeOn: !this.state.selectionModeOn, selectedOrdersId: []});
  }

  selectAllOrders = () => {
    let data = this.getItemsData();
    if(this.state.selectedOrdersId.length !== data.length){
      this.setState({selectedOrdersId: data.map(o => o.id)});
    } else {
      this.setState({selectedOrdersId: []});
    }
  }

  getActions = (order) => {
    switch(this.state.itemType){
      case 1:
        let pedidoActions = [];
        if(!order.order){
          pedidoActions.push({
            description: "Convertir en orden",
            action: () => {
              this.setState({
                showConsolidateModal: true,
                selectedOrdersId: [order.id]
              })
            },
          });
        }
        if(this.state.transactionType === 2){
          pedidoActions.push({
            description: "Confirmar",
            action: () => {
              /* this.setState({
                showDeleteAlertModal: true
              }) */
            },
          })
        }
        return pedidoActions;
      case 2: 
        let orderActions = [
          {
            description: "Convertir en embarque",
            action: () => {
              this.setState({
                showConsolidateTripModal: true,
                selectedOrdersId: [order.id]
              })
            },
          }
        ];
        if(this.state.transactionType === 2){
          orderActions.push({
            description: "Confirmar",
            action: () => {
              /* this.setState({
                showDeleteAlertModal: true
              }) */
            },
          })
        }
        return orderActions;
      default: return []
    }
  }

  getItemsData = () => {
    switch(this.state.itemType){
      case 1:
        return this.props.requisitionsData;
      case 2:
        return this.props.ordersData;
      case 3:
        return this.props.palletsData;
      default:
        return [];
    }
  }

  evaluateConsolidationOrders = () => {
    if(this.state.itemType === 2) return false;
    if(this.state.selectedOrdersId.length < 2){
      let selectedItemsData = this.getItemsData().filter(i => this.state.selectedOrdersId.includes(i.id));
      return selectedItemsData.some(i => i.order);
    }
    let selectedItemsData = this.getItemsData().filter(i => this.state.selectedOrdersId.includes(i.id));
    // if(this.state.itemType === 1) 
    return selectedItemsData.some(i => {
      return i.from_facility.id !== selectedItemsData[0].from_facility.id || i.to_facility.id !== selectedItemsData[0].to_facility.id || i.order
    })
    // else return selectedItemsData.some(i => {
    //   return i.from_facility !== selectedItemsData[0].from_facility || i.to_facility !== selectedItemsData[0].to_facility || i.order
    // })
  }

  getTableConfig = () => {
    switch(this.state.itemType){
      case 1:
        return RequisitionTableConfig;
      case 2:
        return TableConfig;
      case 3:
        return PalletTableConfig;
      default:
        return TableConfig
    }
    // return (this.state.itemType === 1 ? RequisitionTableConfig : TableConfig)
  }

  getCount = () => {
    switch(this.state.itemType){
      case 1:
        return this.props.requisitionsCount;
      case 2:
        return this.props.ordersCount;
      case 3:
        return this.props.palletsCount;
      default:
        return 0
    }
  }

  render() {
    return (
      <View className="orders-control-view">

        {
          (this.props.isLoading) && 
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000080",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1001,
              backdropFilter: "blur(3px)"
            }}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Loader width="100px" height="100px"/>
              <div style={{color: "#fff", fontSize: "36px"}}>
                Cargando
              </div>
            </div>
            <div style={{color: "#fff", fontSize: "30px"}}>
              Si desea cancelar la consulta, presione ESC
            </div>
          </div>
        }

        <ViewTitle>
          <span>Control de órdenes {this.props.facilityData["name"] || ''}</span>
        </ViewTitle>
        <div className="switches-container">
          <OptionSelectorSwitch
            value={this.state.itemType}
            name="itemType"
            onChange={this.handleTabChange}
            items={ITEMS}
          />
          <OptionSelectorSwitch
            value={this.state.transactionType}
            name="transactionType"
            onChange={this.handleTabChange}
            items={TRANSACTIONS}
          />
          <OptionSelectorSwitch
            value={this.state.transportType}
            name="transportType"
            onChange={this.handleTabChange}
            items={TRANSPORT}
          />
        </div>
        <div className="content">
          <div className="view-actions">
            <div></div>
            {
              (this.state.itemType !== 1 || this.state.transactionType !== 1) ? '' :
              <Button
                onClick={() => {this.setState({showCreateOrderModal: true})}}
                type={`primary`}
                text={"Crear pedido de compra"}
                // name="primary"
                disabled={this.state.itemType !== 1 || this.state.transactionType !== 1}
              />
            }
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {
              this.state.requisitionDetail ?
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <div
                  style={{display: 'flex', alignItems: 'flex-start'}}
                >
                  <img
                    alt=""
                    src="/images/close.svg"
                    style={{ width: 20, marginLeft: 20, cursor: 'pointer'}}
                    onClick={() => this.setState({requisitionDetail: null, productsSelected: [], isAllProductsSelected: false})}
                  />
                  <div
                    style={{
                      color: 'white',
                      fontSize: 22,
                      marginLeft: 15
                    }}
                  >{this.state.requisitionDetail?.code}</div>
                </div>
              </div> : <div style={{height: 27}}/>
            }
          </div>

          {
            this.state.itemType === 1 ?
            this.state.requisitionDetail ?
            <RequisitionDetailView
              key={this.state.seed}
              allSelected={this.state.isAllProductsSelected}
              isModeSelection={this.state.selectionModeOn}
              handleChange={this.handleGridChange}
              rowData={this.state.requisitionDetail.deliveries}
              requisition={this.state.requisitionDetail}
            />
            :
            <RequisitionView
              key={this.state.seed}
              allSelected={this.state.isAllRequisitionsSelected}
              isModeSelection={this.state.selectionModeOn}
              handleChange={this.handleGridChange}
              rowData={this.props.requisitionsData}
              transactionType={this.state.transactionType}
            /> : ''
          }

          {
            this.state.itemType !== 1 &&
            <TableD
              tableConfig={
                {
                  ...this.getTableConfig(),
                  columns: [
                    {
                      ...this.getTableConfig().columns[0],
                      text: <CaretContainer
                        onClick={() => this.setState({ collapseAll: !this.state.collapseAll })}
                      >
                        <Caret up={this.state.collapseAll} fill="#AFC8FF" />
                      </CaretContainer>
                    }
                  ].concat(this.getTableConfig().columns.slice(1))
                }
              }>
              {
                (this.getItemsData()).length > 0 ? (
                  <Selector
                    selectedItemClass="selected"
                    selectionModeClass="selection"
                    selectedItems={this.state.selectedOrdersId}
                    onSelect={this.handleSelectTrip}
                    selectionModeOn={this.state.selectionModeOn} 
                    toggleSelectionMode={this.toggleSelectMode}
                  >
                    {
                      (this.getItemsData()).map((r, index) => (
                        <ShipmentBoardRow
                          key={ r.id}
                          id={r.id}
                          selected={this.state.selectedOrdersId.includes(r.id)}
                          // selected={false}
                        >
                          <OrderCatalogRow
                            {...r}
                            itemType={this.state.itemType}
                            actions={this.getActions(r)}
                            tableConfig={
                              {
                                ...this.getTableConfig(),
                                columns: [
                                  {
                                    ...this.getTableConfig().columns[0],
                                    text: <CaretContainer
                                      onClick={() => this.setState({ collapseAll: !this.state.collapseAll })}
                                    >
                                      <Caret up={this.state.collapseAll} fill="#AFC8FF" />
                                    </CaretContainer>
                                  }
                                ].concat(this.getTableConfig().columns.slice(1))
                              }
                            }
                            collapsed={this.state.collapseAll}
                            history={this.props.history}
                            key={index}
                          />
                        </ShipmentBoardRow>
                      )
                    )
                  }
                </Selector>
                ) : (
                    <div
                      style={{
                        backgroundColor: "#212A3C",
                        height: "300px",
                        textAlign: "center",
                        opacity: "0.57",
                        color: "#FFFFFF",
                        fontSize: "24px",
                        fontWeight: "bold",
                        letterSpacing: "0",
                        lineHeight: "37px",
                        paddingTop: "30px",
                      }}
                    >
                      <span>
                        {"No hay "}{this.state.itemType === 1 ? "pedidos" : "órdenes"}{" en este momento"}
                      </span>
                      <br />
                      <img alt="" src="/images/empty-travels.png"></img>
                    </div>
                  )
              }
            </TableD>
          }
          
          <PaginatorControlled
            itemCount={this.getCount()}
            onChange={(e) => {
              this.setState({ [e.target.name]: e.target.value }, () => {
                if (this.state.getOrdersCalled === true) {
                  this.loadItems();
                }
              });
            }}
            limit={this.state.limit}
            offset={this.state.offset}
          />
          
        </div>
        <CatalogSelectionFooter
          key={`catalog-footer`}
          show={this.state.productsSelected.length > 0 || this.state.requisitionsSelected.length > 0 }
          selectedElements={this.state.productsSelected.length || this.state.requisitionsSelected.length }
          selectAll={() => {
            if (this.state.requisitionDetail === null) {
              this.setState({
                isAllRequisitionsSelected: true,
                seed: Math.random()
              });
            } else {
              this.setState({
                isAllProductsSelected: true,
                seed: Math.random()
              });
            }
          }}
          actions={
            (
              this.state.requisitionDetail === null ?
              [{
                description: "Cancelar pedido(s)",
                f: () => {
                  this.setState({cancelRequisitionsModal: true});
                }
              },{
                description: "Confirmar pedido(s)",
                f: () => {
                  this.setState({confirmRequisitionsModal: true});
                }
              }] : [{
                description: "Eliminar",
                f: () => {
                  this.setState({removeProductsModal: true});
                }
              }]
            ).concat(
              [
                // {
                //   description: "Consolidar",
                //   disabled: this.evaluateConsolidationOrders(),
                //   f: () => {
                //     console.log(':::', this.state.selectedOrdersId);
                //     if(this.state.itemType === 1 || this.state.itemType === 3) this.setState({showConsolidateModal: true})
                //     else this.setState({showConsolidateTripModal: true})
                //   },
                // },
                {
                  description: "Cancelar",
                  // disabled: true,
                  f: () => {
                    this.setState({seed: Math.random(), requisitionsSelected: [], isAllRequisitionsSelected: false, isAllProductsSelected: false, productsSelected: []});
                  },
                },
              ]
            )
          }
        />
        {
          this.state.showCreateOrderModal &&
          <CreateOrderModal
            show={this.state.showCreateOrderModal}
            onClose={() => {this.resetModal(); this.setState({showCreateOrderModal: false})}}
            handleChange={this.handleModalChange}
            currentFacilityName={this.props.facilityData["name"] || ''}

            selectedProducts={this.state.productsToAdd}
            selectedProvider={this.state.selectedProvider}
            selectedFacility={this.state.selectedFacility}
            selectedTags={this.state.selectedTags}
            selectedCode={this.state.selectedCode}

            productCatalog={this.state.providerProducts}
            providerCatalog={this.props.companyProviders}
            facilitiesCatalog={this.state.providerFacilities}
            tagsCatalog={[]}
            unitsCatalog={this.props.companyUnits}

            isLoadingProducts={this.props.isLoadingProducts}
            moreProducts={this.state.moreProducts}
            isValidationLoading={this.props.isValidationLoading}
            isCreationLoading={this.props.isCreationLoading}
            selectedDate={this.state.selectedDate}
          />
        }
        
        {
          this.state.showConsolidateModal &&
          <ConsolidateOrderModal
            show={this.state.showConsolidateModal}
            onClose={() => {this.resetModal(); this.setState({showConsolidateModal: false})}}
            handleChange={this.handleModalChange}
            
            selectedTags={this.state.selectedTags}
            selectedCode={this.state.selectedCode}
            selectedDate={this.state.selectedDate}
            selectedLevel={this.state.selectedLevel}

            selectedOrders={this.getItemsData().filter(i => this.state.selectedOrdersId.includes(i.id))}

            tagsCatalog={this.props.orderTags}
          />
        }

        {
          this.state.showConsolidateTripModal &&
          <ConsolidateTripModal
            show={this.state.showConsolidateTripModal}
            onClose={() => {this.resetModal(); this.setState({showConsolidateTripModal: false})}}
            handleChange={this.handleModalChange}
            
            selectedTags={this.state.selectedTags}
            selectedCode={this.state.selectedCode}
            // selectedDate={this.state.selectedDate}
            // selectedLevel={this.state.selectedLevel}
            selectedClass={this.state.selectedClass}

            selectedOrders={this.getItemsData().filter(i => this.state.selectedOrdersId.includes(i.id))}

            tagsCatalog={this.props.tripTags}
            classCatalog={this.props.companyTransportClasses}
            isValidationLoading={this.props.isValidationLoading}
          />
        }

        {
          this.state.test_modal &&
          <GenericDialog
            open={this.state.test_modal !== null}
            title={this.state.test_modal}
            message={'Mensaje de prueba'}
            acceptAction={() => this.setState({test_modal: null})}
            closeAction={() => this.setState({test_modal: null})}
          />
        }

        {
          this.state.error_message &&
          <GenericDialog
            open={this.state.error_message !== null}
            title={'Error'}
            message={this.state.error_message}
            acceptAction={() => this.setState({error_message: null})}
            closeAction={() => this.setState({error_message: null})}
          />
        }

        {
          this.state.divideRequisitionDelivery &&
          <DivideRequisitionDeliveryModal
            deliveryData={this.state.divideRequisitionDelivery}
            closeAction={() => this.setState({divideRequisitionDelivery: null})}
            requisition_id={this.state.requisitionDetail?.id}
            handleChange={this.handleGridChange}
          />
        }

        {
          this.state.addProductRequisitionDelivery &&
          <AddProductRequisitionDeliveryModal
            deliveryData={this.state.addProductRequisitionDelivery}
            closeAction={() => this.setState({addProductRequisitionDelivery: null})}
            network_id={this.state.requisitionDetail?.network_id}
            requisition_id={this.state.requisitionDetail?.id}
            handleChange={this.handleGridChange}
          />
        }

        {
          this.state.confirmRequisitionData !== null &&
          <GenericDialog
            open={this.state.confirmRequisitionData !== null}
            title={'CONFIRMAR PEDIDO'}
            message={`¿Estás seguro de confirmar el pedido ${this.state.confirmRequisitionData.code}?`}
            isLoading={this.props.isRequisitionActionsLoading}
            acceptAction={() => {
              this.props.callActionRequisition({
                company_id: this.props.companyId,
                action: 'confirm_requisitions',
                requisitions: [this.state.confirmRequisitionData.id]
              }, {
                onSuccess: () => {
                  showSimpleFlashNotification(`Se realizó el cambio con éxito al pedido ${this.state.confirmRequisitionData.code}`, null);
                  this.loadItems();
                  this.setState({
                    confirmRequisitionData: null,
                    requisitionsSelected: [],
                    isAllRequisitionsSelected: false
                  });
                },
                onError: (e) => {
                  this.setState({
                    confirmRequisitionData: null,
                    requisitionsSelected: [],
                    isAllRequisitionsSelected: false,
                    error_message: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                  })
                }
              })
            }}
            closeAction={() => {
              this.setState({confirmRequisitionData: null})}
            }
          />
        }

        {
          this.state.cancelRequisitionsModal &&
          <GenericDialog
            open={this.state.cancelRequisitionsModal}
            title={'CANCELAR PEDIDOS'}
            message={'¿Estás seguro de cancelar estos pedidos?'}
            isLoading={this.props.isRequisitionActionsLoading}
            acceptAction={() => {
              this.props.callActionRequisition({
                company_id: this.props.companyId,
                action: 'cancel_requisitions',
                requisitions: this.state.requisitionsSelected
              }, {
                onSuccess: () => {
                  showSimpleFlashNotification('Se realizaron los cambios con éxito', null);
                  this.loadItems();
                  this.setState({
                    cancelRequisitionsModal: false,
                    requisitionsSelected: [],
                    isAllRequisitionsSelected: false
                  });
                },
                onError: (e) => {
                  this.setState({
                    cancelRequisitionsModal: false,
                    requisitionsSelected: [],
                    isAllRequisitionsSelected: false,
                    error_message: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                  })
                }
              })
            }}
            closeAction={() => {
              this.setState({cancelRequisitionsModal: false})}
            }
          />
        }

        {
          this.state.confirmRequisitionsModal && 
          <GenericDialog
            open={this.state.confirmRequisitionsModal}
            title={'CONFIRMAR PEDIDOS'}
            message={'¿Estás seguro de confirmar estos pedidos?'}
            isLoading={this.props.isRequisitionActionsLoading}
            acceptAction={() => {
              this.props.callActionRequisition({
                company_id: this.props.companyId,
                action: 'confirm_requisitions',
                requisitions: this.state.requisitionsSelected
              }, {
                onSuccess: () => {
                  showSimpleFlashNotification('Se realizaron los cambios con éxito', null);
                  this.loadItems();
                  this.setState({
                    confirmRequisitionsModal: false,
                    requisitionsSelected: [],
                    isAllRequisitionsSelected: false
                  });
                },
                onError: (e) => {
                  this.setState({
                    confirmRequisitionsModal: false,
                    requisitionsSelected: [],
                    isAllRequisitionsSelected: false,
                    error_message: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'
                  })
                }
              })
            }}
            closeAction={() => {
              this.setState({confirmRequisitionsModal: false})}
            }
          />
        }

        {
          this.state.deleteDelivery &&
          <GenericDialog
            open={this.state.deleteDelivery !== null}
            title={'ELIMINAR ENTREGA'}
            message={'¿Estás seguro de eliminar esta entrega?'}
            isLoading={this.props.isProductLoading}
            acceptAction={() => {
              this.props.modifyProduct({
                company_id: this.props.companyId,
                delivery_id: this.state.deleteDelivery.id,
                requisition_id: this.state.requisitionDetail.id,
                action: 'delete_delivery'
              }, {
                onSuccess: () => {
                  
                  this.loadItems();
                  this.setState({
                    deleteDelivery: null,
                    requisitionDetail: null
                  });

                  showSimpleFlashNotification('Se eliminó con éxito la entrega', null);
                },
                onError: (e) => {
                  this.setState({
                    deleteDelivery: null,
                    errorMessage: e.response?.data?.error || 'Ocurrío un error, intente de nuevo'
                  })
                }
              });

            }}
            closeAction={() => {
              this.setState({deleteDelivery: false})}
            }
          />
        }

        {
          this.state.modifyProductData && 
          <ModifyProductDeliveryModal
            closeAction={() => this.setState({modifyProductData: null})}
            data={this.state.modifyProductData}
            onClickAccept={(data) => {

              data.company_id = this.props.companyId;
              data.requisition_id = this.state.requisitionDetail.id;
              data.action = 'modify_product_quantity';

              this.props.modifyProduct(data, {
                onSuccess: () => {
                  
                  this.loadItems();
                  this.setState({
                    modifyProductData: null,
                    requisitionDetail: null
                  });

                  showSimpleFlashNotification('Se modificó con éxito el producto', null);
                },
                onError: (e) => {
                  this.setState({
                    modifyProductData: null,
                    errorMessage: e.response?.data?.error || 'Ocurrío un error, intente de nuevo'
                  })
                }
              });

            }}
            isUpdatingLoading={this.props.isProductLoading}
          />
        }

        {
          this.state.removeUniqueProductData &&
          <GenericDialog
            open={this.state.removeUniqueProductData !== null}
            title={'ELIMINAR PRODUCTO'}
            message={'¿Estás seguro de eliminar este producto?'}
            isLoading={this.props.isRequisitionActionsLoading}
            acceptAction={() => {
              this.props.callActionRequisition({
                company_id: this.props.companyId,
                action: 'remove_products',
                deliveries: [this.state.removeUniqueProductData]
              }, {
                onSuccess: () => {
                  showSimpleFlashNotification(`Se eliminaron los productos correctamente`, null);
                  this.loadItems();
                  this.setState({
                    removeUniqueProductData: null,
                    productsSelected: [],
                    isAllProductsSelected: false,
                    requisitionDetail: null
                  });
                },
                onError: (e) => {
                  this.setState({
                    removeUniqueProductData: null,
                    productsSelected: [],
                    isAllProductsSelected: false,
                    error_message: e.response?.data?.error || 'Ocurrió un error, intente de nuevo',
                  })
                }
              });
            }}
            closeAction={() => {
              this.setState({removeUniqueProductData: false})}
            }
          />
        }

        {
          this.state.removeProductsModal &&
          <GenericDialog
            open={this.state.removeProductsModal}
            title={'ELIMINAR PRODUCTOS'}
            message={'¿Estás seguro de eliminar estos productos?'}
            isLoading={this.props.isRequisitionActionsLoading}
            acceptAction={() => {
              let all_products_by_delivery = [];
              (this.state.requisitionDetail?.deliveries || []).map( delivery => {

                let total_products = this.state.productsSelected.filter( item => item.delivery_id === delivery.id).map( item => item.id);

                if (total_products.length > 0 && total_products.length < delivery.products.length) {
                  all_products_by_delivery.push({
                    id: delivery.id,
                    products: total_products
                  });
                } else if (total_products.length > 0 && total_products.length === delivery.products.length) {
                  all_products_by_delivery.push({
                    id: delivery.id,
                    products: total_products.slice(0, -1)
                  });
                }

              });

              this.props.callActionRequisition({
                company_id: this.props.companyId,
                action: 'remove_products',
                deliveries: all_products_by_delivery
              }, {
                onSuccess: () => {
                  showSimpleFlashNotification(`Se eliminaron los productos correctamente`, null);
                  this.loadItems();
                  this.setState({
                    removeProductsModal: false,
                    productsSelected: [],
                    isAllProductsSelected: false,
                    requisitionDetail: null
                  });
                },
                onError: (e) => {
                  this.setState({
                    removeProductsModal: false,
                    productsSelected: [],
                    isAllProductsSelected: false,
                    error_message: e.response?.data?.error || 'Ocurrió un error, intente de nuevo',
                  })
                }
              });

            }}
            closeAction={() => {
              this.setState({removeProductsModal: false})}
            }
          />
        }

        {
          this.state.convertDeliveryToOrderRequisition &&
          <ConvertDeliveryToOrderModal
            requitition={this.state.convertDeliveryToOrderRequisition}
            deliveries={this.state.convertDeliveryToOrderDeliveries}
            closeAction={() => this.setState({convertDeliveryToOrderRequisition: null, convertDeliveryToOrderDeliveries: []})}
            tagsCatalog={this.props.orderTags}
          />
        }

      </View>
    )
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const companyUnits = getEntityItems(state, "COMPANIES.CATALOGS.UNITS");
  //Tipo 7=Ordenes, 9=Viajes
  const companyTags = getEntityItems(state, "COMPANIES.TAGS"); 
  const orderTags = companyTags.filter(t => t.type === 7);
  const tripTags = companyTags.filter(t => t.type === 9);
  const facilityData = getEntityItems(state, "COMPANIES.FACILITIES"); 
  const palletsData = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.PALLETS.CATALOG")["results"] || [];
  const palletsCount = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.PALLETS.CATALOG")["count"] || 0;
  const requisitionsData = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.CATALOG2")["results"] || []; 
  const requisitionsCount = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.CATALOG2")["count"] || 0;
  const ordersData = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.ORDERS.CATALOG")["results"] || [];
  const ordersCount = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.ORDERS.CATALOG")["count"] || 0;
  const ownProducts = getEntityItems(state, "COMPANIES.PRODUCTS") || [];
  // const orders Data = PSEUDO;
  // const companyProviders = getEntityItems(state, "COMPANIES.NETWORK");
  let companyProviders = getEntityItems(state, "COMPANIES.ORDERS.CONTROL.PROVIDERS").reduce(
      (result, network) => {
        // let id = companyProviders.find(
        //   (provider) => provider.id === network.id
        // );
        if (network.from_companies.id === companyId) {
          if (network.to_companies.type === 2) {
            result.push({
              value: String(network.id),

              label: network.to_companies_alias.concat(
                " - ",
                network.to_companies_code,
                " - ",
                network.to_companies.orbi_id
              ),
              id: network.to_companies.id
            });
          }
        } else {
          if (network.from_companies.type === 2)
            result.push({
              // value: network.id,
              value: String(network.id),
              // label: network.from_companies.corporation_name,
              label: network.from_companies.orbi_id.concat(
                " - ",
                network.from_companies_alias,
                " - ",
                network.from_companies_code
              ),
              id: network.from_companies.id
            });
        }
        return result;
      },
      []
    );
  
  companyProviders.unshift(
    {
      value: '-1', // -1 se interpreta como propia
      label: `${state.globalCatalog.session.company.orbi_id}`,
      id: state.globalCatalog.session.company.id
    }
  );
    
  const companyTransportClasses = getEntityItems(state, "COMPANIES.TRANSPORTCLASS").map(f => ({
    value: String(f.id),
    label: f.code
  }));; 
  const providerFacilities = getEntityItems(state, "COMPANIES.ORDERS.CONTROL.PROVIDERS.FACILITIES").map(f => ({
    value: String(f.id),
    label: f.name.concat(' - ', f.alias, ' - ', f.code)
  }));
  const providerProducts = getEntityItems(state, "COMPANIES.ORDERS.CONTROL.PROVIDERS.PRODUCTSPARAMS");

  const isOrdersLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.ORDERS']?.status?.isFetching || false;
  const isTripsLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.TRIPS']?.status?.isFetching || false;
  const isRequisitionLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.REQUISITIONS']?.status?.isFetching || false;
  const isRequisitionCatalogLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.CATALOG2']?.status?.isFetching || false;
  const isRequisitionActionsLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.ACTIONS']?.status?.isFetching || false;
  const isOrdersCatalogLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.ORDERS.CATALOG']?.status?.isFetching || false;
  const isPalletsLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.PALLETS.CATALOG']?.status?.isFetching || false;
  const isOwnProductsLoading = state?.api['COMPANIES.PRODUCTS']?.status?.isFetching || false;
  const isProductsLoading = state?.api['COMPANIES.ORDERS.CONTROL.PROVIDERS.PRODUCTSPARAMS']?.status?.isFetching || false;
  const isSignatureValidationLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.TRIPS.VALIDATESIGNATURE']?.status?.isFetching || false;
  const isCodeValidationLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.ORDERS.VALIDATECODE']?.status?.isFetching || false;
  const isCreationLoading = state?.api['COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.CREATIONV2']?.status?.isFetching || false;
  const isProductLoading = state?.api['COMPANIES.ORDERS.CONTROL.PROVIDERS.NEWPRODUCTS']?.status?.isFetching || false;

  return {
    companyId,
    companyUnits,
    orderTags,
    tripTags,
    facilityData,
    requisitionsData,
    requisitionsCount,
    palletsData,
    palletsCount,
    ordersData,
    ordersCount,
    companyProviders,
    companyTransportClasses,
    providerFacilities,
    providerProducts,
    ownProducts,
    isLoading: isRequisitionCatalogLoading || isOrdersCatalogLoading || isOrdersLoading || isTripsLoading || isRequisitionLoading || isPalletsLoading,
    isLoadingProducts: isOwnProductsLoading || isProductsLoading,
    isValidationLoading: isSignatureValidationLoading || isCodeValidationLoading,
    isCreationLoading,
    userId: state.globalCatalog.session.user.id,
    isRequisitionActionsLoading,
    isProductLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  // dispatch(listNetworkApprovedInvitations("", ""));
  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    loadProducts: (params, opt) => dispatch(readEntities("COMPANIES.PRODUCTS", params, opt)),
    loadOwnFacilities: (params, opt) => dispatch(readEntities("COMPANIES.FACILITIES.ALL", params, opt)),
    createRequisition: (params, opts) =>
      dispatch(createEntity("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS", params, opts)),
    loadFacilityRequisitions: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.CATALOG2", params, opt)),
    createRequisitionV2: (params, opts) =>
      dispatch(createEntity("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.CREATIONV2", params, opts)),
    callActionRequisition: (params, opts) =>
      dispatch(partialEntity("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.ACTIONS", params, opts)),
    loadFacilityOrders: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.ORDERS.CATALOG", params, opt)),
    loadFacilityPallets: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.PALLETS.CATALOG", params, opt)),

    createOrder: (params, opts) =>
      dispatch(createEntity("COMPANIES.REQUISITIONSCONTROL.ORDERS", params, opts)),
    createTrip: (params, opts) =>
      dispatch(createEntity("COMPANIES.REQUISITIONSCONTROL.TRIPS", params, opts)),

    loadCompanyTransportClass: (params, opts) =>
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS", params, opts)),
    loadCompanyTags: (params, opts) =>
      dispatch(readEntities("COMPANIES.TAGS", params, opts)),
    loadCompanyUnits: (params, opts) =>
      dispatch(readEntities("COMPANIES.CATALOGS.UNITS", params, opts)),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.ORDERS.CONTROL.PROVIDERS", params, opts)),
    loadProviderFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.ORDERS.CONTROL.PROVIDERS.FACILITIES", params, opts)),
    loadProviderProducts: (params, opts) =>
      dispatch(readEntities("COMPANIES.ORDERS.CONTROL.PROVIDERS.PRODUCTSPARAMS", params, opts)),
    loadFacilityInfo: (params, opt) =>
      dispatch(readEntity("COMPANIES.FACILITIES", params, opt)),
    validateSignatureTrip: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.TRIPS.VALIDATESIGNATURE", params, opt)),
    validateCodeOrder: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.ORDERS.VALIDATECODE", params, opt)),
    modifyProduct: (params, opts) => dispatch(partialEntity("COMPANIES.ORDERS.CONTROL.PROVIDERS.NEWPRODUCTS", params, opts)),
    // loadFacilityOrders: (params, opt) =>
    //   dispatch(readEntities("COMPANIES.ORDERS.CONTROL.CATALOG", params, opt)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersControlCatalogView);

const CaretContainer = styled.div`
  align-self: center;
  cursor: pointer;
`;

const headerStyle = {
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  backgroundColor: "#455471",
  borderBottom: "solid #A4B4CE 2px",
};
const rowStyle = {
  backgroundColor: "#455471",
  height: "81px",
  borderBottom: "solid 1px #A4B4CE",
  minHeight: "81px"
};

const contentStyle = {
  maxHeight: "500px",
  overflowY: "auto",
}

const cellStyle = {
  height: "50px",
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "center",
};
const headerItemStyle = {
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "center",
};

const TableConfig = {
  rowStyle,
  headerStyle,
  contentStyle,
  columns: [
    {
      text: "",
      width: "20px",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Origen",
      width: "20%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Destino/Cita",
      width: "20%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Código",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Criticidad",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Tipo",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Etiquetas",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Acciones",
      width: "5%",
      headerItemStyle,
      cellStyle: { ...cellStyle, justifyContent: "flex-end" },
    },
  ],
};

const PalletTableConfig = {
  rowStyle,
  headerStyle,
  contentStyle,
  columns: [
    {
      text: "",
      width: "20px",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Tarima",
      width: "30%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Unidades",
      width: "14%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Peso Neto",
      width: "17%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Peso Bruto",
      width: "17%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Volumen",
      width: "17%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Acciones",
      width: "5%",
      headerItemStyle,
      cellStyle: { ...cellStyle, justifyContent: "flex-end" },
    },
  ],
};

const RequisitionTableConfig = {
  rowStyle,
  headerStyle,
  contentStyle,
  columns: [
    {
      text: "",
      width: "20px",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Origen",
      width: "20%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Destino/Cita",
      width: "20%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Pedido",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Orden",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Tipo",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Etiquetas",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Acciones",
      width: "5%",
      headerItemStyle,
      cellStyle: { ...cellStyle, justifyContent: "flex-end" },
    },
  ],
};

// const PSEUDO = [
//   {
//       "id": 108372,
//       "code": "0014177395",
//       "appointment_loading_date": null,
//       "appointment_unload_date": null,
//       "net_weight": "1.2",
//       "gross_weight": "1.2",
//       "volume": "0.0",
//       "priority": 2,
//       "fecha_programacion": "2021-05-22",
//       "trip": 62435,
//       "from_company": 61,
//       "to_company": 61,
//       "from_facility": {
//           "id": 7465,
//           "name": "CMM",
//           "code": "1000001000",
//           "alias": "PC01",
//           "avatar": 2
//       },
//       "to_facility": {
//           "id": 7225,
//           "name": "ALTIPLANO",
//           "code": "2210503000",
//           "alias": "FK00",
//           "avatar": 2
//       },
//       "order_subtype": 1,
//       "status": {
//           "id": 9,
//           "description": "Cancelado"
//       },
//       "route": 386,
//       "ordertag_order": [
//           {
//               "tag": {
//                   "id": 337,
//                   "title": "CERVEZA",
//                   "color": "3D77F7",
//                   "type": 7
//               }
//           }
//       ],
//       "orderproduct_order": [
//           {
//               "product": {
//                   "id": 8,
//                   "sku": "000000000002000167",
//                   "description": "TARIMA PLAST 1.10 X 1.20 M NUCON AZUL",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.03,
//                   "gross_weight": 0.03,
//                   "volume": 0.198,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 4
//           },
//           {
//               "product": {
//                   "id": 44,
//                   "sku": "000000000002000058",
//                   "description": "ENVASE BARRIL 1/4",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.011,
//                   "gross_weight": 0.011,
//                   "volume": 39.062,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 128
//           },
//           {
//               "product": {
//                   "id": 248,
//                   "sku": "000000000003000042",
//                   "description": "NEGRA MODELO BARRIL CHOPE 29 L CT R",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.029,
//                   "gross_weight": 0.04,
//                   "volume": 0.29,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 0
//           },
//           {
//               "product": {
//                   "id": 8,
//                   "sku": "000000000002000167",
//                   "description": "TARIMA PLAST 1.10 X 1.20 M NUCON AZUL",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.03,
//                   "gross_weight": 0.03,
//                   "volume": 0.198,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 15
//           },
//           {
//               "product": {
//                   "id": 14,
//                   "sku": "000000000002000017",
//                   "description": "ENVASE CORONA CLARA 24/355 ML GRAB",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.006,
//                   "gross_weight": 0.006,
//                   "volume": 13.02,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 1260
//           },
//           {
//               "product": {
//                   "id": 161,
//                   "sku": "000000000003000933",
//                   "description": "CORONA EXTRA CLARA 24/355 ML PROMO TRAD",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.009,
//                   "gross_weight": 0.015,
//                   "volume": 0.085,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 0
//           },
//           {
//               "product": {
//                   "id": 5,
//                   "sku": "000000000002004297",
//                   "description": "ENVASE C-V-L-M-P-PL MEGA12/1.2L LISO",
//                   "net_weight_unit": 12,
//                   "net_weight": 0.009,
//                   "gross_weight": 0.009,
//                   "volume": 0.015,
//                   "gross_weight_unit": 12,
//                   "volume_unit": 12,
//                   "product_unit": 1,
//                   "url": "http://qa.v2.orbinetwork.com/api/v2/companies/61/orders_control/facilities/7465/images/product/5/5c6595e8-d671-47c9-bf75-e020d2fbc450.jpeg"
//               },
//               "quantity": 1386
//           },
//           {
//               "product": {
//                   "id": 8,
//                   "sku": "000000000002000167",
//                   "description": "TARIMA PLAST 1.10 X 1.20 M NUCON AZUL",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.03,
//                   "gross_weight": 0.03,
//                   "volume": 0.198,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 21
//           },
//           {
//               "product": {
//                   "id": 137,
//                   "sku": "000000000003008329",
//                   "description": "CE MEGA PAPEL 12 PK DIV 12/1.2 L RET",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.015,
//                   "gross_weight": 0.022,
//                   "volume": 0.144,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 0
//           }
//       ]
//   },
//   {
//       "id": 105498,
//       "code": "0014166651",
//       "appointment_loading_date": null,
//       "appointment_unload_date": null,
//       "net_weight": "1.1",
//       "gross_weight": "1.1",
//       "volume": "0.0",
//       "priority": 2,
//       "fecha_programacion": "2021-05-16",
//       "trip": 60865,
//       "from_company": 61,
//       "to_company": 61,
//       "from_facility": {
//           "id": 7465,
//           "name": "CMM",
//           "code": "1000001000",
//           "alias": "PC01",
//           "avatar": 2
//       },
//       "to_facility": {
//           "id": 7471,
//           "name": "TEHUACAN",
//           "code": "2210503004",
//           "alias": "FK03",
//           "avatar": 2
//       },
//       "order_subtype": 1,
//       "status": {
//           "id": 9,
//           "description": "Cancelado"
//       },
//       "route": 395,
//       "ordertag_order": [
//           {
//               "tag": {
//                   "id": 337,
//                   "title": "CERVEZA",
//                   "color": "3D77F7",
//                   "type": 7
//               }
//           }
//       ],
//       "orderproduct_order": [
//           {
//               "product": {
//                   "id": 8,
//                   "sku": "000000000002000167",
//                   "description": "TARIMA PLAST 1.10 X 1.20 M NUCON AZUL",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.03,
//                   "gross_weight": 0.03,
//                   "volume": 0.198,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 36
//           },
//           {
//               "product": {
//                   "id": 26,
//                   "sku": "000000000002000021",
//                   "description": "ENVASE CORONA FAMILIAR 12/940 ML GRAB",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.007,
//                   "gross_weight": 0.007,
//                   "volume": 0.0,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 2772
//           },
//           {
//               "product": {
//                   "id": 71,
//                   "sku": "000000000003001268",
//                   "description": "CORONA DIV 12/940ML CT R GRABADA",
//                   "net_weight_unit": 6,
//                   "net_weight": 0.011,
//                   "gross_weight": 0.018,
//                   "volume": 0.113,
//                   "gross_weight_unit": 6,
//                   "volume_unit": null,
//                   "product_unit": null,
//                   "url": null
//               },
//               "quantity": 0
//           }
//       ]
//   }
// ]