import React, { useState, useMemo, useCallback, createRef } from "react";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from "../../../shared/controls/button";
import { RequisitionText, RequisitionCenter } from "./style";
import { AG_GRID_LOCALE_ES } from "../../../shared/language";
import ReactTooltip from "react-tooltip";

const getCellView = (key, params, props) => {
  let view = null;

  switch(key) {
    case 'volume':
      let total_volume = (params.data.volume || 0) * (params.data.qty_per_sku || 0);
      view = <div>{`${total_volume} ${params.data.volume_description}`}</div>
      break;
    case 'gross_weight_description':
      let total_gross = (params.data.gross_weight || 0) * (params.data.qty_per_sku || 0);
      view = <div>{`${total_gross} ${params.data.gross_weight_description}`}</div>
      break;
    case 'net_weight_description':
      let total_weight = (params.data.net_weight || 0) * (params.data.qty_per_sku || 0);
      view = <div>{`${total_weight} ${params.data.net_weight_description}`}</div>
      break;
    case 'suffix':
      view = <div>{`${params.requisition_code}-${params.suffix}`}</div>
      break;
    case 'qty':
      view = <div style={{display: 'flex', alignItems: 'center'}}>
        <div>{params.data.qty_per_sku}</div>

        <img
          alt=""
          src="/images/edit.png"
          style={{ cursor: "pointer", marginLeft: 10, width: 21, height: 21 }}
          onClick={() => {
            props.handleChange({
              target: {
                name: "modify_product_data",
                value: params.data
              }
            });
          }}
        />
        
      </div>;
    break;
    case 'tags':
      view = <RequisitionCenter>
        {
          (params.tags || []).map( tag => {
            return(
              <div
                key={`tag-${tag.id}`}
                style={{
                  backgroundColor: tag.color,
                  display: 'flex',
                  height: 25,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 4,
                  paddingRight: 4,
                  color: 'white',
                  fontSize: 11,
                  borderRadius: 8,
                  marginRight: 8
                }}
              >
                {tag.title}
              </div>
            );
          })
        }
      </RequisitionCenter>
      break;
    default:
      view = <div></div>;
  }

  return view
};

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }
};

export const RequisitionDetailView = (props) => {

  const gridRef = createRef();
  const [rowData, setRowData] = useState(props.rowData);
  
  const [colDefs, setColDefs] = useState([
    { headerName: 'Número de pedido', field: 'requisition_code',  minWidth: 250, filter: 'agTextColumnFilter', suppressMenu: true, sortable: true, cellRenderer: "agGroupCellRenderer" },
    { headerName: 'Número de entrega', field: 'suffix', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 200, sortable: true,
      cellRenderer: params => {
        return getCellView('suffix', params.data || {}, props)
      }
    },
    { headerName: 'Número de orden', field: 'order_code', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 200, sortable: true },
    { headerName: 'ID de orden', field: 'order_id', suppressMenu: true, filter: 'agTextColumnFilter', minWidth: 200, sortable: true },
    { headerName: 'Fecha deseada de recolección', field: 'collection_date',  minWidth: 250, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, sortable: true },
    { headerName: 'Fecha deseada de entrega', field: 'delivery_date',  minWidth: 250, filter: 'agDateColumnFilter', filterParams: filterParams, suppressMenu: true, sortable: true },
    { headerName: 'Etiquetas', field: 'tags_description', minWidth: 200, filter: 'agTextColumnFilter', suppressMenu: true,
      cellRenderer: params => {
        return getCellView('tags', params.data || {}, props)
      }
    },
    { headerName: 'Criticidad', field: 'criticality', minWidth: 200, filter: true, suppressMenu: true, sortable: true },
    { headerName: 'Estatus de orden', field: 'order_status_description', minWidth: 200, filter: true, suppressMenu: true, sortable: true },
    { headerName: 'CTA', field: 'cta', minWidth: 230, suppressMenu: true, 
      cellRenderer: params => {
        const isRow = params.node.data;
        if (!isRow) { return <div></div> }

        return(
          <div style={{width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {
              [1,2].includes(props.requisition?.status?.id || 0) &&
              <Button 
                text='Convertir entrega en orden' 
                type='primary small'
                onClick={() => {
                  props.handleChange({
                    target: {
                      name: "convert_delivery_to_order",
                      value: {deliveries: [params.data], requisition: props.requisition}
                    }
                  });  
                }}
              />
            }
          </div>
        );
      }
    },
    { headerName: 'Acciones', field: 'actions', minWidth: 200, suppressMenu: true, tooltipField: 'actions' }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      floatingFilter: true,
      tooltipComponent: params => {
        return(
          <div
            style={{
              backgroundColor: 'black',
              color: 'white',
              padding: 10,
              borderRadius: 10
            }}
          >
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "add-new-product",
                    value: params.data
                  }
                })
              }}
            >{'Añadir producto nuevo'}</div>

            {
              params.data.total_deliveries > 1 ?
              <div
                style={{cursor: 'pointer', marginTop: 8}}
                onClick={() => {
                  props.handleChange({
                    target: {
                      name: "delete-delivery",
                      value: params.data
                    }
                  })
                }}
              >{'Eliminar entrega'}</div> :
              <>
                <div
                  data-tip
                  data-for={`delete-delivery-${params.data.id}`}
                  style={{marginTop: 8}}
                  onMouseEnter={(e) => {
                    ReactTooltip.hide();
                    ReactTooltip.show(e.currentTarget);
                  }}
                >{'Eliminar entrega'}</div>

                <ReactTooltip
                  id={`delete-delivery-${params.data.id}`}
                  className='eta-tooltip-content'
                  event="focusin"
                  eventOff="focusout"
                  place="right"
                > 
                  <>
                    <div>{`El pedido debe tener más de una entrega`}</div>
                  </>
                </ReactTooltip>
              </>
            }

            <div
              style={{
                cursor: 'pointer',
                marginTop: 8
              }}
              onClick={() => {
                props.handleChange({
                  target: {
                    name: "divide-requisition-delivery",
                    value: params.data
                  }
                })
              }}
            >{'Dividir entrega'}</div>
          </div>
        );
      },
    };
  }, []);

  const onGridReady = useCallback((params) => {

    if (props.allSelected) {
      params.api.forEachNode( node => {
        node.setExpanded(true);
      });
    }

    return;

    if (props.configuration['filter'] !== undefined && typeof props.configuration['filter'] === "object") {
      gridRef.current.api.setFilterModel(
        props.configuration['filter']
      );
    }

    if (props.configuration['columns'] !== undefined && typeof props.configuration['columns'] === "object") {

      let temp_columns = props.configuration['columns'];

      if (temp_columns.length === colDefs.length) {
        gridRef.current.api.setColumnDefs(
          temp_columns
        );
        return;
      }

      try {

        let currentColumns = colDefs.map( item => item.field);
        let currentColumnsSaved = props.configuration['columns'].map( item => item.field);
        let difference = currentColumns.filter(x => !currentColumnsSaved.includes(x));

        let newColumnsToAdd = [];

        colDefs.map( (item, index) => {
          if (difference.includes(item.field)) {
            newColumnsToAdd.push({
              index,
              column: item
            })
          }
        });

        newColumnsToAdd.map( item => {
          temp_columns.splice(item.index, 0, item.column);
        });

        gridRef.current.api.setColumnDefs(
          temp_columns
        );

      } catch (error) {
        console.error('Error setting columns definitions', error);

        gridRef.current.api.setColumnDefs(
          temp_columns
        );
      }
    }

  }, []);

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        rowHeight: 50,
        columnDefs: [
          { headerName: 'Nombre del producto', field: "product_name", suppressMenu: true, checkboxSelection: true, headerCheckboxSelection: true },
          { headerName: 'SKU', field: "sku", suppressMenu: true },
          { headerName: 'Qty por SKU', field: "qty_per_sku", suppressMenu: true,
            cellRenderer: params => {
              return getCellView('qty', params || {}, props);
            }
          },
          { headerName: 'Peso neto', field: "net_weight_description", suppressMenu: true,
            cellRenderer: params => {
              return getCellView('net_weight_description', params || {}, props);
            }
          },
          { headerName: 'Peso Bruto por SKU', field: "gross_weight_description", suppressMenu: true,
            cellRenderer: params => {
              return getCellView('gross_weight_description', params || {}, props);
            }
          },
          { headerName: 'Volumen por SKU', field: "volume", suppressMenu: true,
            cellRenderer: params => {
              return getCellView('volume', params || {}, props);
            }
          },
          { headerName: 'CTA', field: 'cta', suppressMenu: true, 
            cellRenderer: params => {
              const isRow = params.node.data;
              if (!isRow) { return <div></div> }

              return(
                <div style={{width: '100%', height: '50px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>

                  {
                    params.node.parent.allChildrenCount > 1 &&
                    <img
                      alt=""
                      src="/images/bin.png"
                      style={{ cursor: "pointer", marginLeft: 10}}
                      onClick={() => {
                        props.handleChange({
                          target: {
                            name: "remove_unique_product_data",
                            value: {
                              id: params.data.delivery_id,
                              products: [params.data.product_delivery_id]
                            }
                          }
                        });
                      }}
                    />
                  }

                </div>
              );
            }
          }
        ],
        rowSelection: 'multiple',
        defaultColDef: {
          flex: 1
        },
        suppressRowClickSelection: true,
        onRowSelected: params => {
          let selected = params.api.getSelectedRows().map( item => item.product_delivery_id);
          let all_ids = [];

          (params?.node?.parent?.allLeafChildren || []).map( item => {
            let product_id = {id: item.data.product_delivery_id, selected: selected.includes(item.data.product_delivery_id), delivery_id: item.data.delivery_id};
            all_ids.push(product_id);
          });

          props.handleChange({
            target: {
              name: "products_selected",
              value: all_ids
            }
          });
        },
        getRowId: params => {
          return String(params.data.product_delivery_id);
        },
        onGridReady: params => {
          if (props.allSelected) {
            params.api.forEachNode( node => {
              node.setSelected(true);
            });
          }
        }
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.products);
      },
    };
  }, []);

  const getRowHeight = useCallback((params) => {
    const isDetailRow = params.node.detail;
    // for all rows that are not detail rows, return nothing
    if (!isDetailRow) { return undefined; }

    // otherwise return height based on number of rows in detail grid
    const detailPanelHeight = ((params.data?.products || []).length * 52) + 50;
    return detailPanelHeight < 50 ? 150 : detailPanelHeight;
  }, []);

  return (
    <div>

      <div
        className="ag-theme-alpine"
        style={{ height: 650, marginTop: 20, marginBottom: 50 }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          enableCellTextSelection={true}
          rowHeight={50}
          detailCellRendererParams={detailCellRendererParams}
          masterDetail={true}
          getRowHeight={getRowHeight}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          localeText={AG_GRID_LOCALE_ES}
          defaultColDef={defaultColDef}

          tooltipShowDelay={500}
          tooltipInteraction={true}
          keepDetailRows={true}
          
          // onRowGroupOpened={params => {

          //   //params.node.setSelected(true);//.api.selectAll()

          //   params.api.forEachDetailGridInfo((node, index) => {
          //     // node.detailNode.detailGridInfo.api.forEachNode((node_2, index_2) => {
          //     //   console.log(index_2, node_2);
          //     // });
          //     node.api.flashCells();
          //     console.log(index, node);
          //   });

          //   // console.log('open', params);
          // }}

          onGridReady={onGridReady}
          getRowId = { params => {
            return String(params.data.id);
          }}
        />
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'end',
        marginBottom: 20
      }}>
      </div>
    </div>
  );
}